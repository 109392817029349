import React, { useEffect,useState } from "react"

import { Row, Col, Card, CardBody, CardTitle, CardSubtitle,Spinner ,Pagination,PaginationItem,PaginationLink} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import  "./testcsc.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { _getIngressi } from "../../helpers/server_helper";
import { useTable , usePagination} from 'react-table'
import { giornoString } from "../../helpers/formatting";
import makeData , {makeDataTest ,makeDataIngressi, makeDataPerXl } from './makeData';



function TableX({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI

  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })


  function bgd(x){
    switch (x) {
      case 'interessanti':
        return 'rgb(246, 185, 59)';
      case 'selezionati':
        return 'rgb(0, 107, 118)';
      default:
        return 'rgb(190, 211, 243)'
    }
  }
  
 
  
  
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr style={{backgroundColor:'#ddd',padding:20,border:'1px solid',}} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th style={{backgroundColor:'#ddd',textAlign:'center', padding:20,border:'1px solid'}}{...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr  style={{border:'1px solid'}} {...row.getRowProps()}>
                 {row.cells.map(cell => {
                if(cell.column.Header == "Record" ){
                  return <td  {...cell.getCellProps()}>{pagina > 0 ? ((pagina*showPage)+row.index+1) : row.index+1  }</td>
                }else{
                  if(cell.column.Header != 'POP UP' ){
                    return <td  style={{border:'1px solid'}}{...cell.getCellProps()}>
                      <div style={{padding:20}}>
                        {cell.value ? <p style={{width:100,border:'1px solid',padding:5,backgroundColor:'#c5e4ff',fontWeight:'900'}}>Ingressi: <span style={{fontWeight:'900',color:'#000'}}>{cell.value ? cell.value.ingressi : 0}</span></p> : null}
                        {cell.value ? <p style={{width:100,border:'1px solid',padding:5,backgroundColor:'#34c38f',fontWeight:'900'}}>trial: <span style={{fontWeight:'900',color:'#000'}}>{cell.value ? cell.value.trial : 0}</span></p> : null}
                        {cell.value ? <p style={{width:100,border:'1px solid',padding:5,backgroundColor:'#ffbfdc',fontWeight:'900'}}>sticks: <span style={{fontWeight:'900',color:'#000'}}>{cell.value ? cell.value.sticks : 0}</span></p> : null}
                      </div> </td>
                  }else{
                    return <td  style={{padding:30}}{...cell.getCellProps()}>{cell.render('Cell')}</td>
                  }
                  
                }
                
              })}
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

const ResponsiveTables = () => {

  const [items,setItems] = useState([])
  const [header,setH] = useState([])
  const [metaInfo,setMeta] = useState({})
  const [loading,setLoadind] = useState(false)
  const [colonne,setcolonne] = useState([])
  const [mese,setmese] = useState(new Date().getMonth())
  



  const getDaysInMonth = (month, year) => {
    var date = new Date(year, month, 1);
    
   
    
    
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    
    
    return days;
  }
  const faitabellamese = (month) =>{
    let dateM =  month;
    let dateY = new Date().getFullYear();
    
  
    
    let dateToUse = getDaysInMonth(dateM,dateY);
  
    let coll = [{
      Header: 'POP UP',
      accessor: 'popup',
    }];
    dateToUse.forEach(element => {
      coll.push(
        {
          Header: giornoString(element.getDay())+' '+element.getDate(),
          accessor: 'day'+element.getDate()+'/'+(element.getMonth()+1)+'/'+element.getUTCFullYear(),
        }
      )
    });
  
    
    setcolonne(coll)
    setmese(month)

    _getIngressi((red)=>{
      console.log('vediamo ');
      console.log(red);
      let passa = makeDataIngressi(red)
      console.log(passa);
      setItems(passa)
    })

   

  }


  

  useEffect(() => {
    faitabellamese(mese)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Ingressi / Trial / Sticks" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Ingressi / Trial / Sticks </CardTitle>
                  <CardSubtitle className="mb-3">
                    Qui puoi vedere il report di Ingressi / Trial / Sticks
                  </CardSubtitle>


                  {loading ? (
                    <div style={{textAlign:'center',padding:30}}>
                      <Spinner color="secondary" />
                  </div>
                  ) : (
                  <div className="table-rep-plugin">
                    <div
                      style={{overflowX: 'scroll',maxHeight: '60vh'}}
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <TableX columns={colonne} data={items} />
                      </div>
                  </div> )}
                </CardBody>
              </Card>
            </Col>
            
            <Col lg="12">
              
              
            </Col>
            
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResponsiveTables
