import React from "react"
import PropTypes from "prop-types"
import { map, get } from "lodash"
import { Card, CardBody, Col, Media, Row ,Table} from "reactstrap"
import { dateToString } from "../../../helpers/formatting";
import img1 from "../../../assets/images/companies/img-1.png"
import TextEditable from "./textEditable";


const ProjectDetail = ({ project ,gestore }) => {
  return (
    <Card>
      <CardBody>
        <Media>
          <img src={project.cliente.logo} alt="" className="avatar-sm mr-4" />

          <Media className="overflow-hidden" body>
            {gestore ? <TextEditable value={project.titolo} tipo={'titolo'} uid={project._id} iptype='titolo' /> : <h3>{project.titolo}</h3>}
            
            <p className="text-muted">{project.cliente.alias}</p>
          </Media>
        </Media>

        <h5 className="font-size-15 mt-4">Dettagli :</h5>
        
        <p className="text-muted">
        {gestore ? <TextEditable value={project.descrizione} tipo={'descrizione'} uid={project._id} iptype='desc' /> : <p>{project.descrizione}</p>}
          
          
        </p>


        <div className="">
          <Table className=" mb-0">
            <tbody>
              
              <tr>
                <th scope="row">Mansione :</th>
                {gestore ? <TextEditable value={project.mansione} tipo={'mansione'} uid={project._id} /> :   <span>{project.mansione}</span> }
              </tr>
              <tr>
                <th scope="row">Indirizzo :</th>
                {gestore ? <TextEditable value={project.textLocation} tipo={'textLocation'} uid={project._id} /> :   <span>{project.textLocation}</span> }
              </tr>
              <tr>
                <th scope="row">Testo data :</th>
                {gestore ? <TextEditable value={project.timedesc} tipo={'timedesc'} uid={project._id} /> :   <span>{project.timedesc}</span> }
              </tr>
              <tr>
                <th scope="row">Abbigliamento :</th>
                {gestore ? <TextEditable value={project.abbigliamento} tipo={'abbigliamento'} uid={project._id} /> :   <span>{project.abbigliamento}</span> }
                
              </tr>
              <tr>
                <th scope="row">Compenso :</th>
                {gestore ? <TextEditable value={project.descCompenso} tipo={'descCompenso'} uid={project._id} /> :   <span>{project.descCompenso}</span> }
               
              </tr>
              <tr>
                <th scope="row">Modalità di pagamento :</th>
                {gestore ? <TextEditable value={project.modalitaPaga} tipo={'modalitaPaga'} uid={project._id} /> :   <span>{project.modalitaPaga}</span> }
                
              </tr>
              <tr>
                <th scope="row">Requisiti :</th>
                {gestore ? <TextEditable value={project.descRequisiti} tipo={'descRequisiti'} uid={project._id} /> :   <span>{project.descRequisiti}</span> }
                
              </tr>
              <tr>
                <th style={{color:'#556de5',fontWeight:900}} scope="row">Citta :</th>
                {gestore ? <TextEditable value={project.citta} tipo={'citta'} uid={project._id} /> :   <span>{project.kom}</span> }
                
              </tr>
              <tr>
                <th style={{color:'#556de5',fontWeight:900}} scope="row">kom :</th>
                {gestore ? <TextEditable value={project.kom} tipo={'kom'} uid={project._id} /> :   <span>{project.kom}</span> }
                
              </tr>
              <tr>
                <th scope="row">Note :</th>
                {gestore ? <TextEditable value={project.note} tipo={'note'} uid={project._id} /> :   <span>{project.note}</span> }
                
              </tr>
              

            </tbody>
          </Table>

          
              
        </div>

        {/*<div className="text-muted mt-4">
            <p>
              <i className="mdi mdi-chevron-right text-primary mr-1" />
                Data descrizione
            </p>
            <p>
              <i className="mdi mdi-chevron-right text-primary mr-1" />
              Mansione
            </p>
            <p>
              <i className="mdi mdi-chevron-right text-primary mr-1" />
              Abbigliamento
             </p>
            <p>
              <i className="mdi mdi-chevron-right text-primary mr-1" />
              Compenso
            </p>
            <p>
              <i className="mdi mdi-chevron-right text-primary mr-1" />
              modalità di pagamento
            </p>
            <p>
              <i className="mdi mdi-chevron-right text-primary mr-1" />
              Requisiti
            </p>
            <p>
              <i className="mdi mdi-chevron-right text-primary mr-1" />
              note
            </p>
        </div>*/}
        

        <Row className="task-dates">
          <Col sm="6" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar mr-1 text-primary" /> Start Date
              </h5>
              <p className="text-muted mb-0">{dateToString(project.dataStart)}</p>
            </div>
          </Col>

          <Col sm="6" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check mr-1 text-primary" /> Due
                Date
              </h5>
              <p className="text-muted mb-0">{dateToString(project.dataEnd)}</p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

ProjectDetail.propTypes = {
  project: PropTypes.object,
}

export default ProjectDetail
