import { split } from "lodash";
import { element } from "prop-types";
import { COMUNI } from "./comuni";
const MESI = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' };


export function makeDataTest(array) {
  
  let newArr = [];
  

  array.forEach(element => {
    let obj = {
      orari:element.turnoString,
      idMongo:element._id
    }
    if(element.daysNumber){
      Object.keys(element.daysNumber).forEach(key => {
        console.log('sei fuori di tsta');
        console.log(key, element.daysNumber[key]);
        obj[key] = {max:element.daysNumber[key]}
      });
      let arrayUserUse = [];
      if(element.daysPeapleYo){
        Object.keys(element.daysPeapleYo).forEach(key => {
          console.log('quardo dentro a qui :');
          console.log(key);
          console.log(element.daysPeapleYo[key]);
          obj[key].persona = element.daysPeapleYo[key]

          
          
  
  
          /*arrayUser.forEach(user => {
             nomeTX = nomeTX + ' '+user.nome+' ,'
          });*/
          //ok   obj[key].nome = element.daysPeapleYo[key]
  
        });

        //obj[key].nome = arrayUserUse
  
      }
    }
    
    console.log('mostro codaf ');
    console.log(obj);
    
    
    newArr.push(obj)
  });


  console.log('arrai che devo modificare ');
  console.log(newArr);
  
  

  return newArr
}

export function makeDatanuovo(array) {
  
  let newArr = [];
  

  array.forEach(element => {
    let obj = {
      orari:element.turnoString,
      idMongo:element._id
    }
    if(element.giorni){
      Object.keys(element.giorni).forEach(key => {
        console.log(key, element.giorni[key]);
        let cambioMinuscolo = key.toLowerCase()
        obj[cambioMinuscolo] = {max: element.giorni[key].disp}
      });
      let arrayUserUse = [];
      if(element.daysPeapleYo){
        Object.keys(element.daysPeapleYo).forEach(key => {
          console.log('quardo dentro a qui :');
          console.log(key);
          console.log(element.daysPeapleYo[key]);
          obj[key].persona = element.daysPeapleYo[key]

          
          
          
          
          
          
  
          /*arrayUser.forEach(user => {
             nomeTX = nomeTX + ' '+user.nome+' ,'
          });*/
          //ok   obj[key].nome = element.daysPeapleYo[key]
  
        });

        //obj[key].nome = arrayUserUse
  
      }
    }
    
    console.log('mostro codaf ');
    console.log(obj);
    
    
    newArr.push(obj)
  });


  console.log('arrai che devo modificare ');
  console.log(newArr);
  
  

  return newArr
}


export function legendCategorie(array,id) {
  
  
  let text = ''
  array.forEach(element => {
    if(element.value == id){
      text =element.label 
    }
  });

  return  text


  

  
}


export async function formatAdresse(via) {
  const axios = require('axios');
  try {
     let res = await axios({
          url: 'https://maps.googleapis.com/maps/api/geocode/json?&address='+via+'&key=AIzaSyDSnyIFG854kXKB_U0TG8sfWINHIym_ET0',
          method: 'get',
          timeout: 8000,
          headers: {
              'Content-Type': 'application/json',
          }
      })
      if(res.status == 200){
          // test for status you want, etc
          console.log(res.status)
      }    
      // Don't forget to return something   
      return res.data.results[0].address_components[4].short_name
  }
  catch (err) {
      console.error(err);
  }
}

export const formatAdresseOld = (via) =>{   
  const axios = require('axios');
  axios.get('https://maps.googleapis.com/maps/api/geocode/json?&address='+via+'&key=AIzaSyDSnyIFG854kXKB_U0TG8sfWINHIym_ET0')
      .then(function (response) {
        // handle success
        
        
        return response.data.results[0].address_components[4].short_name
       
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });

}



export const comuneCf = (codiceFiscale) => { 
 
  let reusltato = codiceFiscale.charAt(12).toUpperCase() === 'Z' ? "Estero" : COMUNI[codiceFiscale.substring(11,15).toUpperCase()]; 
  console.log('forse erroe');
  console.log(reusltato);
  if(reusltato){
    return  reusltato
  }else{
   // return  'romania'
   return  ''
  }
  
}


export const testNome = (codiceFiscale , nome) => { 

  

  let chatNome = codiceFiscale.substring(3,6).toUpperCase()
  let array = chatNome.split('')
  let isOk = false
  array.forEach(element => {
    console.log('test elem');
    console.log(element);
    if(nome.toUpperCase().includes(element)){
      console.log('true');
      isOk= true
    }else{
      console.log('falsep');
      isOk= false
      return false;
    }
    
  });
  return  isOk
}

export const dataCf = (codiceFiscale) => {
	let [ anno, giorno ] = [ codiceFiscale.substring(6,8), codiceFiscale.substring(9,11) ];
	if (giorno>40) {
		giorno -= 40;
		giorno =  giorno;
	}
	return (anno < 20 ? "20" : "19" ) + anno + "-" + MESI[codiceFiscale.charAt(8).toUpperCase()] + "-" + giorno;
}

export const dataCfNew = (codiceFiscale) => {
	let [ anno, giorno ] = [ codiceFiscale.substring(6,8), codiceFiscale.substring(9,11) ];
	if (giorno>40) {
		giorno -= 40;
		giorno =  giorno;
	}
	return (anno < 20 ? "20" : "19" ) + anno + "-" + MESI[codiceFiscale.charAt(8)] + "-" + giorno;
}



export const calculate_age = (dob) => {

  var diff_ms = Number(new Date().getFullYear()) - Number(dob);
  console.log(diff_ms);


  return diff_ms
}

export const dateCalculate = (date,y) =>{
  var oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + y);
  return oneYearFromNow
}


export const numberFormater = (numero,piu) =>{
  numero = ''+numero
  let prefisso1 = numero.substring(0,1)
  let prefisso2 = numero.substring(0,2)
  if(prefisso1 != '+' && prefisso2 != '00'){
    if(piu){
      return  '+39'+numero
    }else{
      return  '39'+numero
    }
   
  }else{
    return  numero
  }

}

export const capellis = [
  {
    label: "Biondo",
    value: "Biondo"
  },
  {
    label: "Nero",
    value: "Nero"
  },
  {
    label: "Rosso",
    value: "Rosso"
  },
  {
    label: "Castano chiaro",
    value: "Castano chiaro"
  },
  {
    label: "Castano scuro",
    value: "Castano scuro"
  },
]


export const linguex =  [
  { value : 'ab', label : 'Abkhazian' },
  { value : 'aa', label : 'Afar' },
  { value : 'af', label : 'Afrikaans' },
  { value : 'ak', label : 'Akan' },
  { value : 'sq', label : 'Albanian' },
  { value : 'am', label : 'Amharic' },
  { value : 'ar', label : 'Arabic' },
  { value : 'an', label : 'Aragonese' },
  { value : 'hy', label : 'Armenian' },
  { value : 'as', label : 'Assamese' },
  { value : 'av', label : 'Avaric' },
  { value : 'ae', label : 'Avestan' },
  { value : 'ay', label : 'Aymara' },
  { value : 'az', label : 'Azerbaijani' },
  { value : 'bm', label : 'Bambara' },
  { value : 'ba', label : 'Bashkir' },
  { value : 'eu', label : 'Basque' },
  { value : 'be', label : 'Belarusian' },
  { value : 'bn', label : 'Bengali' },
  { value : 'bh', label : 'Bihari languages' },
  { value : 'bi', label : 'Bislama' },
  { value : 'bs', label : 'Bosnian' },
  { value : 'br', label : 'Breton' },
  { value : 'bg', label : 'Bulgarian' },
  { value : 'my', label : 'Burmese' },
  { value : 'ca', label : 'Catalan, Valencian' },
  { value : 'km', label : 'Central Khmer' },
  { value : 'ch', label : 'Chamorro' },
  { value : 'ce', label : 'Chechen' },
  { value : 'ny', label : 'Chichewa, Chewa, Nyanja' },
  { value : 'zh', label : 'Chinese' },
  { value : 'cu', label : 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
  { value : 'cv', label : 'Chuvash' },
  { value : 'kw', label : 'Cornish' },
  { value : 'co', label : 'Corsican' },
  { value : 'cr', label : 'Cree' },
  { value : 'hr', label : 'Croatian' },
  { value : 'cs', label : 'Czech' },
  { value : 'da', label : 'Danish' },
  { value : 'dv', label : 'Divehi, Dhivehi, Maldivian' },
  { value : 'nl', label : 'Dutch, Flemish' },
  { value : 'dz', label : 'Dzongkha' },
  { value : 'en', label : 'English' },
  { value : 'eo', label : 'Esperanto' },
  { value : 'et', label : 'Estonian' },
  { value : 'ee', label : 'Ewe' },
  { value : 'fo', label : 'Faroese' },
  { value : 'fj', label : 'Fijian' },
  { value : 'fi', label : 'Finnish' },
  { value : 'fr', label : 'French' },
  { value : 'ff', label : 'Fulah' },
  { value : 'gd', label : 'Gaelic, Scottish Gaelic' },
  { value : 'gl', label : 'Galician' },
  { value : 'lg', label : 'Ganda' },
  { value : 'ka', label : 'Georgian' },
  { value : 'de', label : 'German' },
  { value : 'ki', label : 'Gikuyu, Kikuyu' },
  { value : 'el', label : 'Greek (Modern)' },
  { value : 'kl', label : 'Greenlandic, Kalaallisut' },
  { value : 'gn', label : 'Guarani' },
  { value : 'gu', label : 'Gujarati' },
  { value : 'ht', label : 'Haitian, Haitian Creole' },
  { value : 'ha', label : 'Hausa' },
  { value : 'he', label : 'Hebrew' },
  { value : 'hz', label : 'Herero' },
  { value : 'hi', label : 'Hindi' },
  { value : 'ho', label : 'Hiri Motu' },
  { value : 'hu', label : 'Hungarian' },
  { value : 'is', label : 'Icelandic' },
  { value : 'io', label : 'Ido' },
  { value : 'ig', label : 'Igbo' },
  { value : 'id', label : 'Indonesian' },
  { value : 'ia', label : 'Interlingua (International Auxiliary Language Association)' },
  { value : 'ie', label : 'Interlingue' },
  { value : 'iu', label : 'Inuktitut' },
  { value : 'ik', label : 'Inupiaq' },
  { value : 'ga', label : 'Irish' },
  { value : 'it', label : 'Italian' },
  { value : 'ja', label : 'Japanese' },
  { value : 'jv', label : 'Javanese' },
  { value : 'kn', label : 'Kannada' },
  { value : 'kr', label : 'Kanuri' },
  { value : 'ks', label : 'Kashmiri' },
  { value : 'kk', label : 'Kazakh' },
  { value : 'rw', label : 'Kinyarwanda' },
  { value : 'kv', label : 'Komi' },
  { value : 'kg', label : 'Kongo' },
  { value : 'ko', label : 'Korean' },
  { value : 'kj', label : 'Kwanyama, Kuanyama' },
  { value : 'ku', label : 'Kurdish' },
  { value : 'ky', label : 'Kyrgyz' },
  { value : 'lo', label : 'Lao' },
  { value : 'la', label : 'Latin' },
  { value : 'lv', label : 'Latvian' },
  { value : 'lb', label : 'Letzeburgesch, Luxembourgish' },
  { value : 'li', label : 'Limburgish, Limburgan, Limburger' },
  { value : 'ln', label : 'Lingala' },
  { value : 'lt', label : 'Lithuanian' },
  { value : 'lu', label : 'Luba-Katanga' },
  { value : 'mk', label : 'Macedonian' },
  { value : 'mg', label : 'Malagasy' },
  { value : 'ms', label : 'Malay' },
  { value : 'ml', label : 'Malayalam' },
  { value : 'mt', label : 'Maltese' },
  { value : 'gv', label : 'Manx' },
  { value : 'mi', label : 'Maori' },
  { value : 'mr', label : 'Marathi' },
  { value : 'mh', label : 'Marshallese' },
  { value : 'ro', label : 'Moldovan, Moldavian, Romanian' },
  { value : 'mn', label : 'Mongolian' },
  { value : 'na', label : 'Nauru' },
  { value : 'nv', label : 'Navajo, Navaho' },
  { value : 'nd', label : 'Northern Ndebele' },
  { value : 'ng', label : 'Ndonga' },
  { value : 'ne', label : 'Nepali' },
  { value : 'se', label : 'Northern Sami' },
  { value : 'no', label : 'Norwegian' },
  { value : 'nb', label : 'Norwegian Bokmål' },
  { value : 'nn', label : 'Norwegian Nynorsk' },
  { value : 'ii', label : 'Nuosu, Sichuan Yi' },
  { value : 'oc', label : 'Occitan (post 1500)' },
  { value : 'oj', label : 'Ojibwa' },
  { value : 'or', label : 'Oriya' },
  { value : 'om', label : 'Oromo' },
  { value : 'os', label : 'Ossetian, Ossetic' },
  { value : 'pi', label : 'Pali' },
  { value : 'pa', label : 'Panjabi, Punjabi' },
  { value : 'ps', label : 'Pashto, Pushto' },
  { value : 'fa', label : 'Persian' },
  { value : 'pl', label : 'Polish' },
  { value : 'pt', label : 'Portuguese' },
  { value : 'qu', label : 'Quechua' },
  { value : 'rm', label : 'Romansh' },
  { value : 'rn', label : 'Rundi' },
  { value : 'ru', label : 'Russian' },
  { value : 'sm', label : 'Samoan' },
  { value : 'sg', label : 'Sango' },
  { value : 'sa', label : 'Sanskrit' },
  { value : 'sc', label : 'Sardinian' },
  { value : 'sr', label : 'Serbian' },
  { value : 'sn', label : 'Shona' },
  { value : 'sd', label : 'Sindhi' },
  { value : 'si', label : 'Sinhala, Sinhalese' },
  { value : 'sk', label : 'Slovak' },
  { value : 'sl', label : 'Slovenian' },
  { value : 'so', label : 'Somali' },
  { value : 'st', label : 'Sotho, Southern' },
  { value : 'nr', label : 'South Ndebele' },
  { value : 'es', label : 'Spanish, Castilian' },
  { value : 'su', label : 'Sundanese' },
  { value : 'sw', label : 'Swahili' },
  { value : 'ss', label : 'Swati' },
  { value : 'sv', label : 'Swedish' },
  { value : 'tl', label : 'Tagalog' },
  { value : 'ty', label : 'Tahitian' },
  { value : 'tg', label : 'Tajik' },
  { value : 'ta', label : 'Tamil' },
  { value : 'tt', label : 'Tatar' },
  { value : 'te', label : 'Telugu' },
  { value : 'th', label : 'Thai' },
  { value : 'bo', label : 'Tibetan' },
  { value : 'ti', label : 'Tigrinya' },
  { value : 'to', label : 'Tonga (Tonga Islands)' },
  { value : 'ts', label : 'Tsonga' },
  { value : 'tn', label : 'Tswana' },
  { value : 'tr', label : 'Turkish' },
  { value : 'tk', label : 'Turkmen' },
  { value : 'tw', label : 'Twi' },
  { value : 'ug', label : 'Uighur, Uyghur' },
  { value : 'uk', label : 'Ukrainian' },
  { value : 'ur', label : 'Urdu' },
  { value : 'uz', label : 'Uzbek' },
  { value : 've', label : 'Venda' },
  { value : 'vi', label : 'Vietlabelse' },
  { value : 'vo', label : 'Volap_k' },
  { value : 'wa', label : 'Walloon' },
  { value : 'cy', label : 'Welsh' },
  { value : 'fy', label : 'Western Frisian' },
  { value : 'wo', label : 'Wolof' },
  { value : 'xh', label : 'Xhosa' },
  { value : 'yi', label : 'Yiddish' },
  { value : 'yo', label : 'Yoruba' },
  { value : 'za', label : 'Zhuang, Chuang' },
  { value : 'zu', label : 'Zulu' }
];

export const occhis = [
  {
    label: "Azzurri",
    value: "Azzurri"
  },
  {
    label: "Castani",
    value: "Castani"
  },
  {
    label: "Ceruleo",
    value: "Ceruleo"
  },
  {
    label: "Verdi",
    value: "Verdi"
  },
]

export const provincie = [
  {
    label: "Agrigento",
    value: "AG",
    "regione": "Sicilia"
  },
  {
    label: "Alessandria",
    value: "AL",
    "regione": "Piemonte"
  },
  {
    label: "Ancona",
    value: "AN",
    "regione": "Marche"
  },
  {
    label: "Arezzo",
    value: "AR",
    "regione": "Toscana"
  },
  {
    label: "Ascoli Piceno",
    value: "AP",
    "regione": "Marche"
  },
  {
    label: "Asti",
    value: "AT",
    "regione": "Piemonte"
  },
  {
    label: "Avellino",
    value: "AV",
    "regione": "Campania"
  },
  {
    label: "Bari",
    value: "BA",
    "regione": "Puglia"
  },
  {
    label: "Barletta-Andria-Trani",
    value: "BT",
    "regione": "Puglia"
  },
  {
    label: "Belluno",
    value: "BL",
    "regione": "Veneto"
  },
  {
    label: "Benevento",
    value: "BN",
    "regione": "Campania"
  },
  {
    label: "Bergamo",
    value: "BG",
    "regione": "Lombardia"
  },
  {
    label: "Biella",
    value: "BI",
    "regione": "Piemonte"
  },
  {
    label: "Bologna",
    value: "BO",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Bolzano/Bozen",
    value: "BZ",
    "regione": "Trentino-Alto Adige/Südtirol"
  },
  {
    label: "Brescia",
    value: "BS",
    "regione": "Lombardia"
  },
  {
    label: "Brindisi",
    value: "BR",
    "regione": "Puglia"
  },
  {
    label: "Cagliari",
    value: "CA",
    "regione": "Sardegna"
  },
  {
    label: "Caltanissetta",
    value: "CL",
    "regione": "Sicilia"
  },
  {
    label: "Campobasso",
    value: "CB",
    "regione": "Molise"
  },
  {
    label: "Carbonia-Iglesias",
    value: "CI",
    "regione": "Sardegna"
  },
  {
    label: "Caserta",
    value: "CE",
    "regione": "Campania"
  },
  {
    label: "Catania",
    value: "CT",
    "regione": "Sicilia"
  },
  {
    label: "Catanzaro",
    value: "CZ",
    "regione": "Calabria"
  },
  {
    label: "Chieti",
    value: "CH",
    "regione": "Abruzzo"
  },
  {
    label: "Como",
    value: "CO",
    "regione": "Lombardia"
  },
  {
    label: "Cosenza",
    value: "CS",
    "regione": "Calabria"
  },
  {
    label: "Cremona",
    value: "CR",
    "regione": "Lombardia"
  },
  {
    label: "Crotone",
    value: "KR",
    "regione": "Calabria"
  },
  {
    label: "Cuneo",
    value: "CN",
    "regione": "Piemonte"
  },
  {
    label: "Enna",
    value: "EN",
    "regione": "Sicilia"
  },
  {
    label: "Fermo",
    value: "FM",
    "regione": "Marche"
  },
  {
    label: "Ferrara",
    value: "FE",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Firenze",
    value: "FI",
    "regione": "Toscana"
  },
  {
    label: "Foggia",
    value: "FG",
    "regione": "Puglia"
  },
  {
    label: "Forlì-Cesena",
    value: "FC",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Frosinone",
    value: "FR",
    "regione": "Lazio"
  },
  {
    label: "Genova",
    value: "GE",
    "regione": "Liguria"
  },
  {
    label: "Gorizia",
    value: "GO",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    label: "Grosseto",
    value: "GR",
    "regione": "Toscana"
  },
  {
    label: "Imperia",
    value: "IM",
    "regione": "Liguria"
  },
  {
    label: "Isernia",
    value: "IS",
    "regione": "Molise"
  },
  {
    label: "L'Aquila",
    value: "AQ",
    "regione": "Abruzzo"
  },
  {
    label: "La Spezia",
    value: "SP",
    "regione": "Liguria"
  },
  {
    label: "Latina",
    value: "LT",
    "regione": "Lazio"
  },
  {
    label: "Lecce",
    value: "LE",
    "regione": "Puglia"
  },
  {
    label: "Lecco",
    value: "LC",
    "regione": "Lombardia"
  },
  {
    label: "Livorno",
    value: "LI",
    "regione": "Toscana"
  },
  {
    label: "Lodi",
    value: "LO",
    "regione": "Lombardia"
  },
  {
    label: "Lucca",
    value: "LU",
    "regione": "Toscana"
  },
  {
    label: "Macerata",
    value: "MC",
    "regione": "Marche"
  },
  {
    label: "Mantova",
    value: "MN",
    "regione": "Lombardia"
  },
  {
    label: "Massa-Carrara",
    value: "MS",
    "regione": "Toscana"
  },
  {
    label: "Matera",
    value: "MT",
    "regione": "Basilicata"
  },
  {
    label: "Medio Campidano",
    value: "VS",
    "regione": "Sardegna"
  },
  {
    label: "Messina",
    value: "ME",
    "regione": "Sicilia"
  },
  {
    label: "Milano",
    value: "MI",
    "regione": "Lombardia"
  },
  {
    label: "Modena",
    value: "MO",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Monza e della Brianza",
    value: "MB",
    "regione": "Lombardia"
  },
  {
    label: "Napoli",
    value: "NA",
    "regione": "Campania"
  },
  {
    label: "Novara",
    value: "NO",
    "regione": "Piemonte"
  },
  {
    label: "Nuoro",
    value: "NU",
    "regione": "Sardegna"
  },
  {
    label: "Ogliastra",
    value: "OG",
    "regione": "Sardegna"
  },
  {
    label: "Olbia-Tempio",
    value: "OT",
    "regione": "Sardegna"
  },
  {
    label: "Oristano",
    value: "OR",
    "regione": "Sardegna"
  },
  {
    label: "Padova",
    value: "PD",
    "regione": "Veneto"
  },
  {
    label: "Palermo",
    value: "PA",
    "regione": "Sicilia"
  },
  {
    label: "Parma",
    value: "PR",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Pavia",
    value: "PV",
    "regione": "Lombardia"
  },
  {
    label: "Perugia",
    value: "PG",
    "regione": "Umbria"
  },
  {
    label: "Pesaro e Urbino",
    value: "PU",
    "regione": "Marche"
  },
  {
    label: "Pescara",
    value: "PE",
    "regione": "Abruzzo"
  },
  {
    label: "Piacenza",
    value: "PC",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Pisa",
    value: "PI",
    "regione": "Toscana"
  },
  {
    label: "Pistoia",
    value: "PT",
    "regione": "Toscana"
  },
  {
    label: "Pordenone",
    value: "PN",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    label: "Potenza",
    value: "PZ",
    "regione": "Basilicata"
  },
  {
    label: "Prato",
    value: "PO",
    "regione": "Toscana"
  },
  {
    label: "Ragusa",
    value: "RG",
    "regione": "Sicilia"
  },
  {
    label: "Ravenna",
    value: "RA",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Reggio di Calabria",
    value: "RC",
    "regione": "Calabria"
  },
  {
    label: "Reggio nell'Emilia",
    value: "RE",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Rieti",
    value: "RI",
    "regione": "Lazio"
  },
  {
    label: "Rimini",
    value: "RN",
    "regione": "Emilia-Romagna"
  },
  {
    label: "Roma",
    value: "RM",
    "regione": "Lazio"
  },
  {
    label: "Rovigo",
    value: "RO",
    "regione": "Veneto"
  },
  {
    label: "Salerno",
    value: "SA",
    "regione": "Campania"
  },
  {
    label: "Sassari",
    value: "SS",
    "regione": "Sardegna"
  },
  {
    label: "Savona",
    value: "SV",
    "regione": "Liguria"
  },
  {
    label: "Siena",
    value: "SI",
    "regione": "Toscana"
  },
  {
    label: "Siracusa",
    value: "SR",
    "regione": "Sicilia"
  },
  {
    label: "Sondrio",
    value: "SO",
    "regione": "Lombardia"
  },
  {
    label: "Taranto",
    value: "TA",
    "regione": "Puglia"
  },
  {
    label: "Teramo",
    value: "TE",
    "regione": "Abruzzo"
  },
  {
    label: "Terni",
    value: "TR",
    "regione": "Umbria"
  },
  {
    label: "Torino",
    value: "TO",
    "regione": "Piemonte"
  },
  {
    label: "Trapani",
    value: "TP",
    "regione": "Sicilia"
  },
  {
    label: "Trento",
    value: "TN",
    "regione": "Trentino-Alto Adige/Südtirol"
  },
  {
    label: "Treviso",
    value: "TV",
    "regione": "Veneto"
  },
  {
    label: "Trieste",
    value: "TS",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    label: "Udine",
    value: "UD",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    label: "Valle d'Aosta/Vallée d'Aoste",
    value: "AO",
    "regione": "Valle d'Aosta/Vallée d'Aoste"
  },
  {
    label: "Varese",
    value: "VA",
    "regione": "Lombardia"
  },
  {
    label: "Venezia",
    value: "VE",
    "regione": "Veneto"
  },
  {
    label: "Verbano-Cusio-Ossola",
    value: "VB",
    "regione": "Piemonte"
  },
  {
    label: "Vercelli",
    value: "VC",
    "regione": "Piemonte"
  },
  {
    label: "Verona",
    value: "VR",
    "regione": "Veneto"
  },
  {
    label: "Vibo Valentia",
    value: "VV",
    "regione": "Calabria"
  },
  {
    label: "Vicenza",
    value: "VI",
    "regione": "Veneto"
  },
  {
    label: "Viterbo",
    value: "VT",
    "regione": "Lazio"
  }
]

export function giornoString(giorno) {
  let gioeno =  ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato']

  return gioeno[giorno];
}

function dateToStringYo(x){
    let d = new Date(x).getDate();
    let m = new Date(x).getMonth()+1;
    if(m < 10){
      m = '0'+m;
    }
    let y = new Date(x).getFullYear();
    return d+'/'+m+'/'+y;
  }


  function dateToStringYonew(x){
    console.log('mostrooooo iimm');
    console.log(x);
    let d = new Date(x).getDate();
    let m = new Date(x).getMonth()+1;
    if(m < 10){
      m = '0'+m;
    }
    let y = new Date(x).getFullYear();
    return new Date(x)
  }

  function timeToStringYo(x){
    if(x){
      let h = new Date(x).getHours();
    let m = new Date(x).getMinutes();
    if(m < 10){
      m = '0'+m;
    }
    if(h < 10){
      h = '0'+h;
    }
    
    return h+':'+m
    }else{
      return null
    }

  }



  export const levelDetect = (x) =>{
    let levelL = 'A1';
    switch (x) {
      case 1:
        levelL = 'A1';
        break;
      case 2:
        levelL = 'A2';
        break;
      case 3:
        levelL = 'B1';
        break;
      case 4:
        levelL = 'B2';
        break;
      case 5:
        levelL = 'C1';
        break;
      case 6:
        levelL = 'C2';
        break;
      case 7:
        levelL = 'Madrelingua';
        break;
      default:
        levelL = 'A1';
        break;
    }
    return levelL
  }
  
export const dateToString = dateToStringYo
export const timeToString = timeToStringYo
export const dateToStringnew = dateToStringYonew

