import React, {useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row,TabContent,TabPane,Table } from "reactstrap"
import {_getAllCategoriaInt , _createCategorInt,_getClineti,_createCliente,_getCliente,UpdateCliente} from "../../helpers/server_helper"
import { Widget } from "@uploadcare/react-widget";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ProjectsCreate = props => {
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [selectedFiles, setselectedFiles] = useState([])
  const [listaInterni, setListaInterni] = useState([])
  const [categoria, setCategoria] = useState({})
  const [special, setSpecial] = useState(false)
  const [titolo, setTitolo] = useState('')
  const [sex, setSex] = useState('')
  const [cliente,setCliente] = useState(null)

  const [selected,setSelected] = useState(null)
  const [show,setShow] = useState(false)


  const [load, setload] = useState(false)

  const [alias, setTalias] = useState('')
  const [ragione_sociale, setragione_sociale] = useState('')
  const [indirizzo, setindirizzo] = useState('')
  const [pIva, setpIva] = useState('')
  
  const {
    match: { params },
 } = props



  useEffect(() => {
    
    if (params && params.id) {
      _getCliente(params.id,(results)=>{
        console.log('clinete risult');
        console.log(results);
        setCliente(results)
      })
    }
    
  }, [])

  const salva = () =>{
    let objUpdate = {...cliente}
    delete objUpdate.__v;
    delete objUpdate.updatedAt;
    delete objUpdate.createdAt;
    objUpdate.uid= cliente._id


    
    UpdateCliente(objUpdate,(res)=>{
      console.log('salvato');
      console.log(res);
      alert('dato salvato')
    })
  }

  const cambio = (type,value) =>{
    setload(true)
    let newT = {...cliente}
    newT[type] =  value;
    setCliente(newT)
    setload(false)
  }

  const startDateChange = date => {
    setstartDate(date)
  }

  const endDateChange = date => {
    setendDate(date)
  }

  const addValue = (value,type) =>{
    let obj =  categoria;
    obj[type] = value.target.value;
    
    setCategoria(obj)

  }


 


  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="edit" breadcrumbItem="Cliente" />

          <Row>
            <Col lg="12">
              {cliente ? (
                <Card>
                  <p>{load ? '..' : null}</p>
                <CardBody>
                  <CardTitle className="mb-4">{cliente.alias}</CardTitle>
                  <Form>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Nome 
                      </Label>
                      <Col lg="10">
                        <Input
                          id="projectname"
                          name="projectname"
                          type="text"
                          className="form-control"
                          value={cliente.alias ? cliente.alias : '' }
                          onChange={(e)=>cambio('alias',e.target.value)}
                          placeholder="Enter nome "
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Ragine Sociale
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={cliente.ragione_sociale ? cliente.ragione_sociale : ''}
                          onChange={(e)=>cambio('ragione_sociale',e.target.value)}
                          rows="3"
                          placeholder="Enter Ragine Sociale"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        indirizzo
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={cliente.indirizzo ? cliente.indirizzo : ''}
                          onChange={(e)=>cambio('indirizzo',e.target.value)}
                          rows="3"
                          placeholder="Enter indirizzo"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        PIva
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={cliente.pIva ? cliente.pIva : ''}
                          onChange={(e)=>cambio('pIva',e.target.value)}
                          rows="3"
                          placeholder="Enter PIva"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Logo
                      </Label>
                      <Col lg="10">
                        <div>
                          <img style={{width:100}} src={cliente.logo} />
                        </div>
                        {show ? (
                          <Widget imagesOnly 
                          crop="1:1" 
                          tabs="file"
                          onFileSelect={(file) => {
                          console.log('File changed: ', file)
                          
                          if (file) {
                            file.progress(info => console.log('File progress: ', info.progress))
                            file.done(info => {console.log('File uploaded: ', info);cambio('logo',info.cdnUrl);setShow(false)})
                          }
                        }}
                        publicKey="11b33ec1ef5ed2bddb9f"  />
                        ) : null}
                        {!show ? (
                        <button onClick={()=>setShow(true)} class="btn btn-primary waves-effect waves-light btn btn-primary">cambia immagine</button>

                      ) : null}
                      </Col>
                    </FormGroup>
                    

                    

                    
                  </Form>
                  
                  <Row className="justify-content-end">
                    <Col lg="10">
                      {/*<Button disabled={false} onClick={()=>salva(true)} type="submit" color="primary">
                        Salva
                      </Button>*/}
                      <Button style={{backgroundColor:'black'}} disabled={false} onClick={()=>salva()} type="submit" >
                        Salva
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              ): (
                <Card>
                <CardBody>
                  <CardTitle className="mb-4">Cliente</CardTitle>
                  
                  
                  
                </CardBody>
              </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreate
