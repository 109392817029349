import axios from "axios"
let urll = 'https://mcm-api-web.herokuapp.com/api/v1'

//urll = 'https://api-mcm-no-git.herokuapp.com/api/v1'

//urll = 'http://localhost:3030/api/v1'

//urll ='https://api-mcm-rzeve.ondigitalocean.app/api/v1'


class API {

  constructor(){
    console.log('start api');
    //this.url = 'http://localhost:3001'
    this.url = urll
    this.endpoints = {}

  }
  /**
   * Create and store a single entity's endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {

    this.endpoints[entity.name] = this.createBasicCRUDEndpoints(entity)
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this))
  }
  /**
   * Create the basic endpoints handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createBasicCRUDEndpoints( { name } ) {



    var endpoints = {}

    const resourceURL = `${this.url}/${name}`

    //endpoints.getAllQuery = ({ id }, config={}) => axios.get(`${resourceURL}?Azziende={all}${id}`, config)

    //endpoints.getAllQueryDip = ({ id }, config={}) => axios.get(`${resourceURL}?dipendente={all}${id}`, config)

    //endpoints.getByRif = ({ id }, config={}) => axios.get(`${resourceURL}?rif=${id}`, config)

    //endpoints.getAllOk = ( {config={}} ) => axios.get(`${resourceURL}`, Object.assign({ headers: config }))
    //
    //endpoints.getAllOk = ( config={} ) => console.log(config.config);

    endpoints.getAllOk = (config={}) => axios.get(resourceURL, { headers: config})

    endpoints.getAllParamx = (params={},config={}) => {
      console.log('config');
      console.log(config);
      console.log('_____');
      console.log('parame');
      console.log(params);
      console.log('_____');
      return(
        axios.get(resourceURL, { params:params, headers: config})
        //axios.get(resourceURL, { params: params,headers: config})
      )
    }
    
    endpoints.getAllParam = (params={},config={}) => axios.get(resourceURL, { params: params,headers: config})




    //endpoints.getAll = ({ query={}}, config={} ) => axios.get(resourceURL, Object.assign({ params: query , config }))


    endpoints.getPost = (data, config={}) =>  axios.post(resourceURL, data, { headers: config})

    endpoints.getOne = ({ id }, config={}) =>  axios.get(`${resourceURL}/${id}`, { headers: config})

    endpoints.create = (toCreate, config={}) =>  axios.post(resourceURL, toCreate, { headers: config})

    endpoints.updateConID = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate, config)
    
    endpoints.update = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.uid}`, toUpdate, { headers: config})

    endpoints.updateNew = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.idDate}`, toUpdate, config)
    

    endpoints.updateIII = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.uid}`, toUpdate, config)


    endpoints.patch  = ({id}, toPatch, config={}) => axios.patch(`${resourceURL}/${id}`, toPatch, config)

    endpoints.delete = ({ id }, config={}) => axios.delete(`${resourceURL}/${id}`, config)

    //console.log(endpoints);
    return endpoints

  }

}

export default API
