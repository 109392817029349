import React, { useEffect,useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  TabContent,
  TabPane,
  CardTitle,
  CardHeader,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import {_getAllLavoriApi , _getAllLavoriStoriaco , _getLavoroCopyyo} from "../../helpers/server_helper"
import { dateToString } from "../../helpers/formatting";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import images from "assets/images"
import companies from "assets/images/companies"

import { getProjects } from "store/actions"





const ProjectsList = props => {
  

  const [lavori,setLavori] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }




  const lavoriStorico = ()=>{
    
    _getAllLavoriStoriaco((result)=>{
       
      console.log(result);
      setLavori(result)
    })
  }

  const lavorixx = ()=>{
    
    _getAllLavoriApi((result)=>{
       
      console.log(result);
      setLavori(result)
    })
  }

  const ViewvweList = () =>{
    switch (key) {
      case value:
        
        break;
    
      default:
        break;
    }
  }


  
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      _getAllLavoriApi((result)=>{
       
        console.log(result);
        setLavori(result)
      })
      

      

    }
  }, [])

  const creaCopia = (id) =>{
    //alert('copia')
    _getLavoroCopyyo(id,(result)=>{
       
      console.log('risultatopooo copia');
      console.log(result);
      alert('Lavoro copiato con successo, ricorda di controllare , cambiare e aggiungere i dati mancanti! * il cliente per ora non può essere modificato' )
      lavorixx()
  })
}
  
  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          {/* Render Breadcrumbs */}
          
          
          <CardHeader className="bg-transparent border-bottom">
            <div className="d-flex flex-wrap">
              <div className="mr-2">
                <h5 className="card-title mt-1 mb-0">Progetti</h5>
              </div>
              
              
             

              <ul
                className="nav nav-tabs nav-tabs-custom card-header-tabs ml-auto"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      console.log('polll');
                      toggle("1");
                      lavorixx()
                    }}
                  >
                    Tutti
                  </NavLink>
                </NavItem>
                
                {/*<NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    I Miei
                  </NavLink>
                </NavItem>*/}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                      lavorixx()
                    }}
                  >
                    Special
                  </NavLink>
                  </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggle("4");
                      lavoriStorico()
                    }}
                  >
                    Storico
                  </NavLink>
                </NavItem>
                
              </ul>
            </div>
            
          </CardHeader>
          
          <Link to="/projects-create">
                <p><i class="bx bx-plus"></i>  Nuovo progetto</p>
              </Link>

          <TabContent activeTab={activeTab} className="py-4">
            <TabPane tabId="1">
            <Row>
              <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap table-centered table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Projects</th>
                            <th scope="col">Due Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Team</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(lavori, (project, index) => (
                            !project.special && !project.chiuso  ? (
                              <tr  key={index}>
                              <td style={{backgroundColor: project.decided ? '#9aa6cc' : 'white'}}>
                                <img
                                  src={project.cliente ? project.cliente.logo :null}
                                  alt=""
                                  className="avatar-sm"
                                />
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14">
                                  <Link
                                    to={`/projects-overview/${project._id}`}
                                    className="text-dark"
                                  >
                                    {project.titolo}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  {project.descrizione}
                                </p>
                                {project.incognito ? <Badge color={'danger'}>Incognito</Badge> : null}
                              </td>
                              {project.special ? (
                                <td>
                                  <p>{'dal '+dateToString(project.from)}</p>
                                  <p>{'al '+dateToString(project.to)}</p>
                                </td>
                                
                              ) :  <td>
                                  <p>{'dal '+dateToString(project.dataStart)}</p>
                                  <p>{'al '+dateToString(project.dataEnd)}</p>
                                </td> }
                              <td>
                                {project.online ?  <Badge color={'success'}>
                                  online
                                </Badge> :  <Badge color={'warning'}>
                                  offline
                                </Badge>}
                                
                              </td>
                              <td>
                                <div className="team">
                                  {map(project.referenti, (member, index) =>
                                    !member.img_profilo || member.img_profilo !== "Null" ? (
                                      <Link
                                        key={index}
                                        to="#"
                                        className="team-member d-inline-block"
                                        id="member1"
                                      >
                                        <img
                                          src={member.img_profilo}
                                          className="rounded-circle avatar-xs m-1"
                                          alt=""
                                        />
                                        
                                      </Link>
                                    ) : (
                                      <Link
                                        to="#"
                                        className="team-member d-inline-block"
                                        id={"member" + member._id}
                                        key={"_team_" + index}
                                      >
                                        <div className="avatar-xs m-1">
                                          <span
                                            className={
                                              "avatar-title rounded-circle bg-soft-" +
                                              member.color +
                                              " text-" +
                                              member.color +
                                              " font-size-16"
                                            }
                                          >
                                            {member.name.charAt(0)}
                                          </span>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={"member" + member.id}
                                          >
                                            {member.nome}
                                          </UncontrolledTooltip>
                                        </div>
                                      </Link>
                                    )
                                  )}
                                </div>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#"
                                    className="card-drop"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <DropdownItem onClick={()=>creaCopia(project._id)}>Duplica</DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                            ) : (
                              null
                            )
                            
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>

            {/* special */}
            <TabPane tabId="3">
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap table-centered table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Projects</th>
                            <th scope="col">Due Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Team</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(lavori, (project, index) => (
                            project.special ? (
                              <tr key={index}>
                              <td>
                                <img
                                  src={project.cliente ? project.cliente.logo :null}
                                  alt=""
                                  className="avatar-sm"
                                />
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14">
                                  <Link
                                    to={`/projects-overview/${project._id}`}
                                    className="text-dark"
                                  >
                                    {project.titolo}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  {project.descrizione}
                                </p>
                              </td>
                              {project.special ? (
                                <td>
                                  <p>{'dal '+dateToString(project.from)}</p>
                                  <p>{'al '+dateToString(project.to)}</p>
                                </td>
                                
                              ) :  <td>
                                  <p>{'dal '+dateToString(project.dataStart)}</p>
                                  <p>{'al '+dateToString(project.dataEnd)}</p>
                                </td> }
                              <td>
                                {project.online ?  <Badge color={'success'}>
                                  online
                                </Badge> :  <Badge color={'warning'}>
                                  offline
                                </Badge>}
                                
                              </td>
                              <td>
                                <div className="team">
                                  {map(project.referenti, (member, index) =>
                                    !member.img_profilo || member.img_profilo !== "Null" ? (
                                      <Link
                                        key={index}
                                        to="#"
                                        className="team-member d-inline-block"
                                        id="member1"
                                      >
                                        <img
                                          src={member.img_profilo}
                                          className="rounded-circle avatar-xs m-1"
                                          alt=""
                                        />
                                        
                                      </Link>
                                    ) : (
                                      <Link
                                        to="#"
                                        className="team-member d-inline-block"
                                        id={"member" + member._id}
                                        key={"_team_" + index}
                                      >
                                        <div className="avatar-xs m-1">
                                          <span
                                            className={
                                              "avatar-title rounded-circle bg-soft-" +
                                              member.color +
                                              " text-" +
                                              member.color +
                                              " font-size-16"
                                            }
                                          >
                                            {member.name.charAt(0)}
                                          </span>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={"member" + member.id}
                                          >
                                            {member.nome}
                                          </UncontrolledTooltip>
                                        </div>
                                      </Link>
                                    )
                                  )}
                                </div>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#"
                                    className="card-drop"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <DropdownItem href="#">Action</DropdownItem>
                                    <DropdownItem href="#">
                                      Another action
                                    </DropdownItem>
                                    <DropdownItem href="#">
                                      Something else here
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                            ) : (
                              null
                            )
                            
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap table-centered table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Projects</th>
                            <th scope="col">Due Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Team</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(lavori, (project, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  src={project.cliente ? project.cliente.logo :null}
                                  alt=""
                                  className="avatar-sm"
                                />
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14">
                                  <Link
                                    to={`/projects-overview/${project._id}`}
                                    className="text-dark"
                                  >
                                    {project.titolo}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  {project.descrizione}
                                </p>
                              </td>
                              {project.special ? (
                                <td>
                                  <p>{'dal '+dateToString(project.from)}</p>
                                  <p>{'al '+dateToString(project.to)}</p>
                                </td>
                                
                              ) :  <td>
                                  <p>{'dal '+dateToString(project.dataStart)}</p>
                                  <p>{'al '+dateToString(project.dataEnd)}</p>
                                </td> }
                              <td>
                                {project.online ?  <Badge color={'success'}>
                                  online
                                </Badge> :  <Badge color={'warning'}>
                                  offline
                                </Badge>}
                                
                              </td>
                              <td>
                                <div className="team">
                                  {map(project.referenti, (member, index) =>
                                    !member.img_profilo || member.img_profilo !== "Null" ? (
                                      <Link
                                        key={index}
                                        to="#"
                                        className="team-member d-inline-block"
                                        id="member1"
                                      >
                                        <img
                                          src={member.img_profilo}
                                          className="rounded-circle avatar-xs m-1"
                                          alt=""
                                        />
                                        
                                      </Link>
                                    ) : (
                                      <Link
                                        to="#"
                                        className="team-member d-inline-block"
                                        id={"member" + member._id}
                                        key={"_team_" + index}
                                      >
                                        <div className="avatar-xs m-1">
                                          <span
                                            className={
                                              "avatar-title rounded-circle bg-soft-" +
                                              member.color +
                                              " text-" +
                                              member.color +
                                              " font-size-16"
                                            }
                                          >
                                            {member.name.charAt(0)}
                                          </span>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={"member" + member.id}
                                          >
                                            {member.nome}
                                          </UncontrolledTooltip>
                                        </div>
                                      </Link>
                                    )
                                  )}
                                </div>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#"
                                    className="card-drop"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <DropdownItem href="#">Action</DropdownItem>
                                    <DropdownItem href="#">
                                      Another action
                                    </DropdownItem>
                                    <DropdownItem href="#">
                                      Something else here
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                            
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          

          {/*<Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row>*/}
        </Container>
      </div>
    </React.Fragment>
  )
}



export default ProjectsList

