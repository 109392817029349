import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Media } from "reactstrap"
import {UpdateInfoLavoro} from "../../../helpers/server_helper"

const InputEdit = ({value,tipo,uid,iptype}) =>{

  
  const [testo,setTesto] = useState()
  const [edit,setEdit] = useState(false)

  const salva = () =>{
    let progect = {
      id:uid,
    }
    progect[tipo] = testo


    console.log(progect);
    UpdateInfoLavoro(progect,(res)=>{
      
      
      alert('dato salvato')
      setEdit(false)
    })
  }

  useEffect(() => {
    setTesto(value)
  }, [value])

  switch (iptype) {
    case 'desc':
      return(
        edit ? (
          <div style={{display:''}}>
            <textarea className="form-control" type='text' onChange={(e)=>setTesto(e.target.value)}  value={testo} />
            <button onClick={()=>salva()} type="button" class="btn btn-success waves-effect"><i style={{cursor:'pointer'}} className="fas fa-save"></i></button>
            <button onClick={()=>setEdit(false)} type="button" class="btn btn-danger waves-effect"><i style={{cursor:'pointer'}} className="fas fa-window-close"></i></button>

          </div>
        ) : (
          <div style={{padding:20}}>
             <h3 onClick={()=>setEdit(true)} className="text-truncate font-size-16"><i style={{cursor:'pointer',fontSize:12,color:'#ddd'}} className="far fa-edit"></i> {testo ? testo : 'edit'}</h3>
          </div>
          
          
        )
    )
    case 'titolo':
      return(
        edit ? (
          <div style={{display:'flex'}}>
            <input className="form-control" type='text' onChange={(e)=>setTesto(e.target.value)}  value={testo} />
            <button onClick={()=>salva()} type="button" class="btn btn-success waves-effect"><i style={{cursor:'pointer'}} className="fas fa-save"></i></button>
            <button onClick={()=>setEdit(false)} type="button" class="btn btn-danger waves-effect"><i style={{cursor:'pointer'}} className="fas fa-window-close"></i></button>

          </div>
        ) : (
          <h3 onClick={()=>setEdit(true)} className="text-truncate font-size-20"><i style={{cursor:'pointer',color:'#ddd',fontSize:15}} className="far fa-edit"></i> {testo ? testo : 'edit'}</h3>
          
          
        )
    )
    case 'num':
      return(
        <td>
          {edit ? (
            <div>
              <input type='number' onChange={(e)=>setTesto(e.target.value)}  value={testo} />
              <button onClick={()=>salva()}>salvas</button>
              <button onClick={()=>setEdit(false)}>close</button>
            </div>
          ) : (
            <span style={{cursor:'pointer',color:'blue'}} onClick={()=>setEdit(true)}>{testo ? testo : 'edit'}</span>
          )}
        </td>
      )
  
    default:
      return(
        <td>
          {edit ? (
            <div style={{display:'flex'}}>
            <textarea className="form-control" type='text' onChange={(e)=>setTesto(e.target.value)}  value={testo} />
            <button onClick={()=>salva()} type="button" class="btn btn-success waves-effect"><i style={{cursor:'pointer'}} className="fas fa-save"></i></button>
            <button onClick={()=>setEdit(false)} type="button" class="btn btn-danger waves-effect"><i style={{cursor:'pointer'}} className="fas fa-window-close"></i></button>

          </div>
          ) : (
            <span style={{cursor:'pointer',whiteSpace: 'pre-wrap',marginLeft: -15}} onClick={()=>setEdit(true)}><i style={{cursor:'pointer',fontSize:10,color:'#ddd'}} className="far fa-edit"></i> {testo ? testo : 'edit'} </span>
          )}
        </td>
      )
  }

  
}


export default InputEdit