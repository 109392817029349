

const range = len => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}




export function giornoString(giorno) {
  let gioeno =  ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato']

  return gioeno[giorno];
}



export function makeDataTest(array) {
  
  
  
  
  
  let newArr = [];
  
  return array
}

function nomeG(nome){
  switch (nome) {
    case '5f306d8dafda4977669aaa1f':
      return 'Arese'
      break;
    case '5f4a8f6e1799ce001724009b':
    return 'Roma Est'
    break;
    case '5f46efc690d3000017412109':
    return 'Carosello'
    break;
    case '5f4ad6ff1799ce00172400d9':
    return 'Porte di Mestre'
    break;
    case '5f4ad33b1799ce00172400ca':
    return 'Centro Nova'
    break;
    case '5f4acd871799ce00172400c8':
    return 'Grande Mela'
    break;
    case '5f4ac91f1799ce00172400b8':
    return 'i Gigli'
    break;
    case '5f4ac6671799ce00172400b7':
    return 'Parco Leonardo'
    break;
    case '5f4ac3cd1799ce00172400b6':
    return 'Euroma2'
    break;
    case '5f4a3f691799ce0017240069':
    return 'Globo'
    break;
    default:
      return nome
      break;
  }
}

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a.popup.toUpperCase();
  const bandB = b.popup.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

export function makeDataIngressi(array) {
  
  
  console.log('array passato');
  console.log(array);

  
  let newArr = []
  
  array.forEach(element => {
    let obj = {
      popup: element.popUp.titolo
    }
    element.ingressi.forEach(ingresso => {
      obj['day'+ingresso.giorno]= {
        ingressi:ingresso.numero
      }
    });

    element.trial.forEach(trial => {
      obj['day'+trial.giorno].trial = trial.numero
    });
    element.sticks.forEach(sticks => {
      obj['day'+sticks.giorno].sticks= sticks.numero
    });

    
    

    newArr.sort(compare);
    newArr.push(obj)



  });
  
  
  
  return newArr
}


function nomeColor(nome){
  switch (nome) {
    case 'Rio_de_Janeiro':
      return 'Rio'
      break;
    case 'Barcellona':
    return 'Barcelona'
    break;
    case 'Londra':
    return 'London'
    break;
    case 'New_York':
    return 'New York'
    break;
  
    default:
      return nome
      break;
  }
}

function convertDays(date){
  let dateArr = date.split("/");
  return  dateArr[1]+'/'+dateArr[0]+'/'+dateArr[2]
}

function ageFix(age){
  var separators = ['-'];
  let ageee = age.replace(new RegExp('\\' + separators.join('|\\'), 'g'), ' $& ');
  if(ageee == '46'){
    return '46 +'
  }else{
    return ageee
  }
}

export function makeDataPerXl(array,feed) {
  
  var separators = ['-'];
  let newArr = [];
  array.forEach((element,i) => {
    console.log('mostro elemento');
    console.log(element);
    
    if(feed){
        newArr.push({
          record:i,
          giornoString: convertDays(element.giornoString),
          referentio:element.operatore ? element.operatore.cognome+' '+element.operatore.nome : 'no select',
          idLavoro: element.popUp.titolo == 'Centro Nova' ? 'CentroNova' : element.popUp.titolo ,
          trial:  element.trial,
          gusto:element.gusto,
          age :element.age ? ageFix(element.age) : null,
          gender:element.gender,
          cfumi:element.cfumi  == 'TABACCO' ? 'TABACCO IN BUSTA' : element.cfumi,
          brand:element.brand,
          dcac:element.dcac == 'POPUP' ? 'POP UP' : element.dcac ,
          gadget:element.gadget ? 'si' : 'no',
          acquisto:element.acquisto ? 'si' : 'no',
          feedback:element.feedback ? element.feedback.replace(/,/g, " ") : null

        })
    }else{
      console.log(element);
      newArr.push({
        record:i,
        referentio:element.operatore ? element.operatore.cognome+' '+element.operatore.nome : 'no select',
        giornoString: convertDays(element.giornoString),
        idLavoro:element.popUp.titolo == 'Centro Nova' ? 'CentroNova' : element.popUp.titolo  ,
        time:  element.time ? element.time.replace(new RegExp('\\' + separators.join('|\\'), 'g'), ' $& ') : null,
        gender:element.gender,
        age:element.age,
        Myglo:element.Myglo,
        device: element.device,
        colour:  element.device == 'Hyper'   && element.colour ? nomeColor(element.colour) : null,
        colour_x: ( element.device == 'Hyper X2' || element.device == 'Hyper+' || element.device == 'Hyper+ UNIQ') && element.colour ? nomeColor(element.colour) : null,
        panel:element.panel ? element.panel : 'no',
        price:element.price,
        registrato:element.registrato ? 'si' : 'no'
      })
    }
    
  });
  
  
  
  
  
  return newArr
}