import PropTypes from 'prop-types'
import React from "react"
import { Card, CardBody, Col, Media } from "reactstrap"
import InputEdit from "./inputNewEdit";

const MiniCards = props => {
  const { title, text, iban, iconClass } = props
  return (
    <React.Fragment>
      <Col md="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <p className="text-muted font-weight-medium">Banca</p>
                
                <h4 style={{textTransform:'capitalize'}} className="mb-0">{text}</h4>
                <p style={{textTransform:'capitalize'}} className="mb-0">{iban}</p>
              </Media>
              
            </Media>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

MiniCards.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}

export default MiniCards
