import React, { useState ,useEffect } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import {_getStaffToDoApi} from "../../../helpers/server_helper"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [staff, setStaff] =  useState([])



  useEffect(() => {
    _getStaffToDoApi((risultato)=>{
      setStaff(risultato)
    })
    const interval = setInterval(() => {
      console.log('caricooo');
      _getStaffToDoApi((risultato)=>{
        setStaff(risultato)
      })
    }, 300000);
    return () => clearInterval(interval);

   
  }, [])

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={staff.length > 0 ? "bx bx-bell bx-tada" : "bx bx-bell"}/>
          {staff.length > 0 ? (
            <span className="badge badge-danger badge-pill">{staff.length}</span>
          ) : null}
          
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  {" "}
                 
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {staff.map((user,index)=>{
              return(
                <a key={index} href={"/user-profile/"+user._id} className="text-reset notification-item">
                  {user.ckSync ? (
                      <p style={{margin:0,background:'orange',fontSize:10,paddingLeft:10}}>approvare cambi</p>
                    ) : null}
                  <div className="media">
                    <img
                      src={user.img_profilo}
                      className="mr-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">{user.nome} {user.cognome}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {user.residenza}
                        </p>
                        
                      </div>
                    </div>
                  </div>
                  {user.ckSync ? (
                      <hr style={{margin:0,background:'orange'}}></hr>
                    ) : null}
                </a>
              )
            })}
            
          </SimpleBar>
          <div className="p-2 border-top">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/contacts-list"
            >
              {" "}
              
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}