import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
import Select from "react-select"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { _getAllClinetiValue,UpdateGiornoArr } from "../../helpers/server_helper";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Mappa from "../Map/mappa";


const ProjectsCreate = (props) => {
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [selectedFiles, setselectedFiles] = useState([])
  const [clientiValue, setClientiValue] = useState([])
  const [dateLavoro, setDateLavoro] = useState([])
  const [cliente, setCliente] = useState(null)
  const [lavoro,setLavoro] = useState({})



  useEffect(() => {
    _getAllClinetiValue((clienti)=>{
      setClientiValue(clienti)
    })
  }, [])


  const _workObjLocation = (x) => {

    let objLavoro = {...lavoro}
    console.log(x);
    objLavoro.locationOj = x
    objLavoro.textLocation = x.place_name;
    console.log('indirixxo');
    console.log(x.place_name);
    var context = x.context;
    var place_type = x.place_type[0];
    var provincia = null;
    var citta = null;
    var nazione = null;
    var self = this;
    context.forEach(function(item,i){
      //console.log(item.id);
      var region = false
      region = item.id.includes("region");
      if(region){
        console.log('provincia: '+(item.short_code ? item.short_code : item.text));
        provincia = item.short_code ? item.short_code : item.text
        
        objLavoro.provincia = provincia
      }
      var country = false
      country = item.id.includes("country");
      if(country){
        console.log('country: '+item.short_code);
        nazione = item.short_code;

        objLavoro.nazione = nazione
      }
      var place = false;
      place = item.id.includes("place");
      if(place){
        console.log('citta: '+item.text)
        citta = item.text;

        objLavoro.citta = citta
        
      }
    });



    switch (place_type) {
      case 'poi':
        console.log(place_type+' punto int');
        break;
      case 'place':
        console.log(place_type+' place');
        break;
      case 'address':
        console.log(place_type+' indirizzo');
        break;
      case 'country':
        console.log(place_type+' country');
        break;
      default:

    }
    setLavoro(objLavoro)
   
  }


  const logicadataSalvataggio = (selectedDates) =>{
    console.log('mostro data');
    console.log(selectedDates);
    setDateLavoro(selectedDates);

  }

  const addDatoLavoro = (e) =>{
    let lavoroObj = {...lavoro}
    lavoroObj[e.target.name] = e.target.value
    setLavoro(lavoroObj)
  }

  const salvataggio = () =>{
    console.log('qui salvo');
    console.log(dateLavoro);


    let obj = {
      array:dateLavoro,
      pollo:'pppp'
    }

   /* UpdateGiornoArr(obj,(uuu)=>{
      console.log(uuu);
    })*/

    /*dateLavoro.forEach(element => {
      let date = new Date(element)
      let idtime =  ''+date.getFullYear()+''+date.getMonth()+''+date.getDate()
      console.log(idtime);
    });
    //idtime =  idtime+''+selectedDate.getFullYear()+''+selectedDate.getMonth()+''+selectedDate.getDate()

    let objToSave = lavoro
    objToSave.cliente = cliente.value
    console.log(objToSave);*/


    let objToSave = lavoro
    console.log('clinete:');
    //console.log(cliente && cliente.value ? cliente.value : null);
    objToSave.cliente = cliente && cliente.value ? cliente.value : null
    objToSave.array= dateLavoro,
    objToSave.color= lavoro.color ? lavoro.color :'#db6665'
    objToSave.referenti = [JSON.parse(localStorage.getItem("authUser")).mongoDbUid]

    console.log('mostro');
    console.log(objToSave);
   UpdateGiornoArr(objToSave,(uuu)=>{
      console.log(uuu);
      if(uuu.status == 'ok'){
        props.history.push('/projects-overview/'+uuu.resut._id)
      }else{
        alert('si è verificato un errore')
      }
     
    })
  
  }

  const startDateChange = date => {
    setstartDate(date)
  }

  const endDateChange = date => {
    setendDate(date)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Crea progetto" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Crea nuovo progetto</CardTitle>
                  <Form>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Nome progetto
                      </Label>
                      <Col lg="10">
                        <Input
                          id="projectname"
                          name="titolo"
                          type="text"
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          className="form-control"
                          placeholder="Enter Project Name..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Cliente
                      </Label>
                      <Col lg="10">
                        <Select
                          value={cliente}
                          isMulti={false}
                          onChange={(e)=>setCliente(e)}
                          options={clientiValue}
                          classNamePrefix="select2x-selection"
                        />
                      </Col>
                    </FormGroup>



                    
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Chi serve
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          name='descrizione'
                          rows="3"
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          placeholder="Enter descrizione progetto..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Giorni progetto
                      </Label>
                      <Col lg="10">
                        <div className="input-group">
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              inline: false,
                              altInput: true,
                              altFormat: "d-m-Y",
                              mode: "multiple",
                              dateFormat: "m/d/Y",
                              minDate:new Date(),
                              onChange: function(selectedDates, dateStr, instance) {
                                console.log('dateStr');
                                console.log(instance);
                                console.log(selectedDates);
                                console.log('__________');
                                console.log(dateStr);
                                let arrDate = dateStr.split(",");
                                console.log(arrDate);
                                let arrSort = arrDate.sort(function(a,b){
                                  
                                  return new Date(a) - new Date(b);
                                });
                                console.log(arrSort);
                                
                                
                                logicadataSalvataggio(arrDate)
                            },
                            }}
                          />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Luogo lavoro
                      </Label>
                      <Col lg="10">
                        <Mappa callback={(x)=>_workObjLocation(x)} />
                      </Col>
                    </FormGroup> 
                   
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Testo Location
                      </Label>
                      <Col lg="10">
                        <Input
                          id="projectnametextLocation"
                          name="textLocation"
                          type="text"
                          value={lavoro && lavoro.textLocation ? lavoro.textLocation : '' }
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          className="form-control"
                          placeholder="Enter testo location..."
                        />
                      </Col>
                    </FormGroup>
                   
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Descrizione mansione
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          name='mansione'
                          rows="3"
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          placeholder="Enter descrizione mansione..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Descrizione abbigliamento
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          name='abbigliamento'
                          rows="3"
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          placeholder="Enter descrizione abbigliamento..."
                        />
                      </Col>
                    </FormGroup>

                    

                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        Descrizione compenso
                      </label>
                      <Col lg="10">
                        <Input
                          id="projectbudget"
                          type="text"
                          name='descCompenso'
                          placeholder="Descrizione compenso"
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          className="form-control"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        Descrizione modalità di pagamento
                      </label>
                      <Col lg="10">
                        <Input
                          id="projectbudget"
                          name="modalitaPaga"
                          type="text"
                          placeholder="Enter descrizione pagamento..."
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          className="form-control"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        Descrizione Data (giorni di lavoro in formato testuale)
                      </label>
                      <Col lg="10">
                        <Input
                          id="timedesc"
                          name="timedesc"
                          type="text"
                          placeholder="es 12-12 maggio "
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          className="form-control"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Note
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          name='note'
                          rows="3"
                          onChange={(e)=>{
                            
                            addDatoLavoro(e)
                          }}
                          placeholder="Enter note..."
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                  
                  
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button onClick={salvataggio} type="submit" color="primary">
                        Crea progetto
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreate
