import PropTypes from 'prop-types'
import React , { useEffect, useState } from "react"
import { Card, CardBody, Col, Media } from "reactstrap"




const MiniCards = props => {
  const axios = require('axios');
  const [couter,setcouter] = useState(null)
  const { title, text, iconClass } = props

  useEffect(() => {
    console.log('eccomiuuu');
    if (text) {
      axios.get('https://www.instagram.com/'+text+'/?__a=1')
      .then(function (response) {
        // handle success
        console.log('risposta');
        console.log(response);
        setcouter(response.data.graphql.user.edge_followed_by.count)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    }
  }, [text])

  return (
    <React.Fragment>
      <Col md="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <p className="text-muted font-weight-medium">{title}</p>
                <h5 className="mb-0"><a  href={'https://www.instagram.com/'+text} target={'_blank'}>{text}</a></h5>
                <p></p>
              </Media>
              
            </Media>
            <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                <span className="avatar-title">
                  {couter}
                </span>
              </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

MiniCards.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}

export default MiniCards
