import React, { useEffect,useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardBody,
  CardFooter,
  Modal,
  Button,
  UncontrolledTooltip
} from "reactstrap"
import algoliasearch from 'algoliasearch/lite';
import classnames from "classnames"
import Select from "react-select"
import AlgoliaLogo from "./algoliaLogo.png";
import { provincie ,capellis,occhis ,numberFormater ,dateCalculate,calculate_age,linguex} from "../../helpers/formatting";
import { CompetenzeListaFunct ,_getCategorieInterne,_getAllCategoriaInt,_getStaffApi,UpdateInfoStaff,_ricercaInterna } from "../../helpers/server_helper";
import Rheostat from 'rheostat';
//import images
//import small from "../../assets/images/small/img-2.jpg"
//import small2 from "../../assets/images/small/img-6.jpg"

import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet";
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
//import { indexOf } from "lodash";

ThemedStyleSheet.registerInterface(aphroditeInterface);

const core = {
  black: "black",
  white: "#fcfcfc",
  grey: "#d8d8d8",
  teal: "#abc4e8",
  lightgrey: "#dbdbdb"
};

const DefaultTheme = {
  rheostat: {
    unit: 8,

    responsive: {
      mediumAndAbove: "@media (min-width: 744px)",
      largeAndAbove: "@media (min-width: 1128px)"
    },

    constants: {
      DEFAULT_HANDLE_WIDTH: 1.5,
      BACKGROUND_HEIGHT: 0.25
    },

    color: {
      ...core,
      progressBar: core.teal,
      focus: core.teal,
      textDisabled: core.lightgrey,

      buttons: {
        defaultDisabledColor: core.lightgrey
      }
    }
  }
};

ThemedStyleSheet.registerTheme(DefaultTheme);


const client = algoliasearch(
  '3VN9LAE1IH',
  '4adcefd5da1ae8ae047903a30e3fcf48'
);

const index = client.initIndex('STAFF');

const BlogList = ({close}) => {
  const [listacateg, setListacateg] =  useState([])
  const [listacomp, setListacomp] =  useState([])
  const [activeTab, toggleTab] = useState("1")
  const [userList, setUserList] = useState([])
  const [sesso,setSesso] = useState(null)
  const [altezza,setAltezza] = useState([150,220])
  const [etaValue,setEtaValue] = useState([dateCalculate(new Date(),-18).getFullYear(),dateCalculate(new Date(),-70).getFullYear()])
  const [annoNascita,setAnnoNascita] = useState(null)
  const [approvato,setApprovato] = useState('SI')
  const [capelli,setCapelli] = useState(null)
  const [occhi,setOcchi] = useState(null)
  const [categoriaInterna,setCategoriaInterna] = useState(null)
  const [competenze,setCompetenze] = useState(null)
  const [lingue,setLingue] = useState(null)
  const [piercing,setPiercing] = useState(null)
  const [tatoo,setTatoo] = useState(null)
  const [province_competenza,setProvince_competenza] = useState(null)
  const [taglia,setTaglia] = useState([36,55])
  const [scarpe,setScarpe] = useState(null)
  const [isLaureato,setIsLaureato] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false)
  const [idUser,setIdUser] = useState(null)
  const [listaInterni, setListaInterni] = useState([])
  const [textRicerca,setTestRicerca] = useState(null)
  const [ListaInterniFilter,setListaInterniFilter] = useState([])
  const [tipoR,setTipoR] = useState('n')
  const [testoRicvercax,setTestoRicvercax] = useState('')
  //const [altezza,setAltezza] =useState([])


  const genetaricerca = () =>{

   // const filters = 'available = 1 AND (category:Book OR NOT category:Ebook) AND _tags:published AND publication_date:1441745506 TO 1441755506 AND inStock > 0 AND author:"John Doe"';
    

   
   let provincieF = ''
   let occhiF = ''
   let capelliF = ''
   let competenzeF = ''
   let categIntF = ''
   let lingIntF = ''
   


   if(lingue){
       
    lingue.forEach((element,index) => {
      let testo =  `lingue.code:${element.value}`
      if(index > 0){
        lingIntF =  `${lingIntF} OR ${testo}`
      }else{
        lingIntF =  `${lingIntF} ${testo}`
      }
      
    });

    lingIntF = `(${lingIntF})`
    
    }else{
      lingIntF = null
  }
 

   if(categoriaInterna){
       
    categoriaInterna.forEach((element,index) => {
      let testo =  `categoriaInterna:${element.value}`
      if(index > 0){
        categIntF =  `${categIntF} OR ${testo}`
      }else{
        categIntF =  `${categIntF} ${testo}`
      }
      
    });

    categIntF = `(${categIntF})`
    
 }else{
  categIntF = null
 }

   if(province_competenza){
       
      province_competenza.forEach((element,index) => {
        let testo =  `province_competenza:${element.value.toLowerCase()}`
        if(index > 0){
          provincieF =  `${provincieF} OR ${testo}`
        }else{
          provincieF =  `${provincieF} ${testo}`
        }
        
      });

      provincieF = `(${provincieF})`
      console.log(provincieF);
   }else{
    provincieF = null
   }


   if(occhi){
       
    occhi.forEach((element,index) => {
      let testo =  `occhi:${element.value}`
      if(index > 0){
        occhiF =  `${occhiF} OR ${testo}`
      }else{
        occhiF =  `${occhiF} ${testo}`
      }
      
    });

    occhiF = `(${occhiF})`
    
 }else{
  occhiF = null
 }


 if(capelli){
       
  capelli.forEach((element,index) => {
        let testo =  `capelli:${element.value}`
        if(index > 0){
          capelliF =  `${capelliF} OR ${testo}`
        }else{
          capelliF =  `${capelliF} ${testo}`
        }
        
      });

      capelliF = `(${capelliF})`
      
    }else{
      capelliF = null
    }


    if(competenze){
       
      competenze.forEach((element,index) => {
            let testo =  `competenze:${element.value}`
            if(index > 0){
              competenzeF =  `${competenzeF} OR ${testo}`
            }else{
              competenzeF =  `${competenzeF} ${testo}`
            }
            
          });
    
          competenzeF = `(${competenzeF})`
          
        }else{
          competenzeF = null
        }



   let teststts = []

    let sessoF =
      sesso  ? `(sesso:${sesso})` : null;

      
    let approvatoF ;
    if(approvato){
      if(approvato == 'SI'){
        approvatoF = `(approvato:${approvato  == 'SI' ? true : false })`;
      }else{
        //approvatoF = `(approvato:false OR NOT approvato:true )`
        approvatoF = `(approvato:false )`
      }
      
    
    }else{
      approvatoF = null
    }

    //laurea
    let isLaureatoF;
    if(isLaureato){
      if(isLaureato == 'SI'){
        isLaureatoF = `(isLaureato:${isLaureato  == 'SI' ? true : false })`;
      }else{
        isLaureatoF = `(isLaureato:false OR NOT isLaureato:true )`
      }
      
    
    }else{
      isLaureatoF = null
    }

      
    let tattoF =
      tatoo  ? `(tatoo:${tatoo == 'SI' ? true : false})` : null;  
    let piercingF =
      piercing  ? `(piercing:${piercing == 'SI' ? true : false})` : null;    


    if(sessoF){
      teststts.push(sessoF)
    }

    if(approvatoF){
      teststts.push(approvatoF)
    }
    if(isLaureatoF){
      teststts.push(isLaureatoF)
    }
    if(tattoF){
      teststts.push(tattoF)
    }
    if(piercingF){
      teststts.push(piercingF)
    }

    if(provincieF){
      teststts.push(provincieF)
    }
    if(occhiF){
      teststts.push(occhiF)
    }
    if(capelliF){
      teststts.push(capelliF)
    }
    if(competenzeF){
      teststts.push(competenzeF)
    }
    if(categIntF){
      teststts.push(categIntF)
    }
    if(lingIntF){
      teststts.push(lingIntF)
    }
    
    teststts.push(`(altezza>=${altezza[0]} AND altezza<${altezza[1]} )`)
    teststts.push(`(taglia>=${taglia[0]} AND taglia<${taglia[1]} )`)
    teststts.push(`(annoNascita>=${etaValue[1]} AND annoNascita<=${etaValue[0]})`)
    
    //console.log(province_competenza);


    
    
    console.log('mostreo test');
    console.log(teststts);

    let filterFrase
    teststts.forEach((element,index) => {
      if(index > 0){
        filterFrase = `${filterFrase} AND ${element}`
      }else{
        filterFrase = `${element}`
      }
    });


    console.log('mostro frade');

    if(!filterFrase){
      filterFrase = ''
    }


    console.log('qui filtro');
    console.log(filterFrase);


    
    index.search('', {
      filters: `${filterFrase}`,
      hitsPerPage: 150,
    }).then(res => {

      setUserList(res.hits)
  
    });
    
    
  }

 const filtraLista= (value) =>{
  var _ = require('lodash');
  var condition = new RegExp(value);
  var result = listaInterni.filter(function (el) {
    return condition.test(el.titolo);
  });
  
  console.log(result);
  setTestRicerca(value)
  setListaInterniFilter(result)
  
 } 


 const eventRiceca = () =>{
   //alert(testoRicvercax.target.value+' tipo '+tipoR)
  let tipo = ''
  switch (tipoR) {
    case 'c':
      tipo = 'cf'
      break;
    case 'e':
      tipo = 'email'
      break;
    case 'i':
      tipo = 'iban'
      break;  
    case 't':
      tipo = 'cell'
      break;   
  
    default:
      break;
  }

   let objC = {
        "typo": tipo,
        "value":testoRicvercax.target.value
    }
   _ricercaInterna(objC,(result)=>{
      setUserList(result.resp.data)
      console.log(result);
   })
 }
  const testoCerca =  (value) =>{
    
    setTestoRicvercax(value)
    //const filters = 'available = 1 AND (category:Book OR NOT category:Ebook) AND _tags:published AND publication_date:1441745506 TO 1441755506 AND inStock > 0 AND author:"John Doe"';
    //'(approvato:false OR NOT approvato:true) AND (sesso:F)'
    if(tipoR == 'n'){
      if(value.target.value.length > 4){
        index.search(value.target.value, {
          /*numericFilters: [
            [
              'nuovoLingue.it > 8'
            ]
          ],*/
          filters: '',
          hitsPerPage: 150,
        }).then(res => {
          //dentro res ci sono info page si usa pafamentro page x paginazione
          console.log(res.hits);
          setUserList(res.hits)
      
        });
      }
    }else{
      
      console.log(value);
    }

    
  }

  const removeLista = (idLista) =>{
    let categInt = []
    let tosave = {uid:idUser.uid}
    idUser.categoriaInterna.forEach(element => {
      if(element._id != idLista){
        categInt.push(element._id)
      }
      
    });
    
    tosave.categoriaInterna = categInt


    UpdateInfoStaff(tosave,(res)=>{
      //console.log('salvato');
      //console.log(res);
      
      apriInfoUser(res._id)
      
    })
  }

  const salvaLista = (idLista) =>{
    let categInt = []
    let tosave = {uid:idUser.uid}
    idUser.categoriaInterna.forEach(element => {
      categInt.push(element._id)
    });
    categInt.push(idLista)
    tosave.categoriaInterna = categInt

    console.log(tosave);

    UpdateInfoStaff(tosave,(res)=>{
      //console.log('salvato');
      //console.log(res);
      
      apriInfoUser(res._id)
      
    })
  }

  const apriInfoUser = (id) =>{
    _getStaffApi(id,(risultato)=>{
      setmodal_standard(true);
      setIdUser(risultato)
      console.log(risultato);
    })
  }

  const handleSelectGroup = (province_competenza) => {
    console.log('selectedGroup');
    console.log(province_competenza);
    setProvince_competenza(province_competenza)
  }

  const handleSelectGroupcapelli = (capelli) => {
    
    setCapelli(capelli)
  }
  const handleSelectGroupocchi = (occhi) => {
    
    setOcchi(occhi)
  }

  const handleSelectGroupocompet = (competenze) => {
    
    setCompetenze(competenze)
  }

  const handleSelectGroplingue = (l) => {
    
    setLingue(l)
  }


  const handleSelectGroupocatint = (categoriaInterna) => {
    
    setCategoriaInterna(categoriaInterna)
  }


  
  const ckIfExist = (id) =>{
    let arrayInter = idUser && idUser.categoriaInterna ? idUser.categoriaInterna  : []
    let exist = arrayInter.map(function(e) { return e._id; }).indexOf(id);
    if(exist > -1){
      return true
    }else{
      return false
    }
  }

  useEffect(() => {

    _getAllCategoriaInt((risultato)=>{
      setListaInterni(risultato)
      setListaInterniFilter(risultato)
    })

    CompetenzeListaFunct((comp)=>{
      
      setListacomp(comp)
    })

    _getCategorieInterne((categint)=>{
      
      setListacateg(categint)
    })

   /* index.search('', {
      //filters: '(approvato:false)',
      hitsPerPage: 50,
    }).then(res => {

      setUserList(res.hits)
  
      // console.log(res);
    });*/
  }, [])


  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard}
        
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Liste appartenenza
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/*<h5>Overflowing text to show scroll behavior {idUser}</h5>*/}
          <div style={{marginBottom:20}}>
            <input onChange={(x)=>filtraLista(x.target.value)}  style={{width:'100%',padding:10,border:'none', border:'1px solid #eee'}} type='text' />
          </div>
          <div style={{maxHeight:300,overflowX:'scroll'}}>
            { ListaInterniFilter.map((item,x)=>{
                    return(
                      <div style={{display:'flex',justifyContent: 'space-between',borderBottom:'1px solid #eee',marginBottom:15}}>
                        <div>
                          <h6 style={{marginBottom:0}}>{item.titolo}</h6>
                          <p style={{fontSize:10,marginTop:0}}>{item.sex}</p>
                        </div>
                        <div>
                        {ckIfExist(item._id) ? (
                          <Button
                          onClick={()=>removeLista(item._id)}
                          color="success"
                          className="btn-rounded waves-effect waves-light"
                          >
                            <i className='bx bx-comment-check' />
                        </Button>
                        ) : (
                          <Button
                            onClick={()=>salvaLista(item._id)}
                            color="danger"
                            className="btn-rounded waves-effect waves-light"
                          >
                            Salva
                        </Button>
                        )}
                        
                         </div>
                        {/*<p>Desc: {item.sex}</p>
                        <p>{item.special ? 'Special' : null}</p>*/}
                      </div>
                      
                    )
                  })}
          </div>
          
          
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          
        </div>
      </Modal>
                    
      <Col style={{height:'100%',overflow:'scroll'}} xl={3} lg={4}>
        <Card>
          <CardBody className="p-4">
            <div className="search-box">
              <img src={AlgoliaLogo}></img>
              <p className="text-muted"></p>
              <div style={{padding:10}}>
                <button onClick={()=>{setTipoR('n')}} style={{border:'none',borderBottom:tipoR == 'n' ? '1px solid #6676ff' : 'none',backgroundColor:'white',marginLeft:0 }}>Nome</button>
                <button onClick={()=>{setTipoR('i')}} style={{border:'none',borderBottom:tipoR == 'i' ? '1px solid #6676ff' : 'none',backgroundColor:'white',marginLeft:10}}>Iban</button>
                <button onClick={()=>{setTipoR('c')}} style={{border:'none',borderBottom:tipoR  == 'c' ? '1px solid #6676ff' : 'none',backgroundColor:'white',marginLeft:10}}>Cf</button>
                <button onClick={()=>{setTipoR('e')}} style={{border:'none',borderBottom:tipoR  == 'e' ? '1px solid #6676ff' : 'none',backgroundColor:'white',marginLeft:10}}>Email</button>
                <button onClick={()=>{setTipoR('t')}} style={{border:'none',borderBottom:tipoR  == 't' ? '1px solid #6676ff' : 'none',backgroundColor:'white',marginLeft:10}}>Cell</button>
              </div>
              <div className="position-relative">
                <input
                  autoFocus={true}
                  type="text"
                  className="form-control rounded bg-light border-light"
                  placeholder="Search..."
                  onChange={(value)=>testoCerca(value)}
                />
                <i className="mdi mdi-magnify search-icon"></i>
              </div>
              <br></br>
              {tipoR != 'n' ? (
                 <button onClick={()=>eventRiceca()} style={{width:'100%',border:'none',backgroundColor:'#5467ff',color:'white',padding:10,borderRadius:10}}>
                 cerca
               </button>
              ) : null}
             
            </div>

            <hr className="my-4" />

            
            
            <div>
              <p className="text-muted">Categories</p>

              
              <ul className="list-unstyled font-weight-medium">
    
              <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Approvati
                  </a>
                </li>


                <div style={{display:'flex'}}>
                  <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3saap3"
                    checked={approvato == 'SI'}
                    onChange={() => {
                      if(approvato != 'SI'){
                        setApprovato('SI')
                      }else{
                        setApprovato(null)
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3saap3"
                  >
                    Si
                  </label>
                  </div>
                  <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3wsapp"
                    checked={approvato == 'NO'}
                    onChange={() => {
                      if(approvato == 'NO'){
                        setApprovato(null)
                      }else{
                        setApprovato('NO')
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3wsapp"
                  >
                    No 
                  </label>
                </div>
                </div>
                <hr className="my-4" />


               

                <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Sesso
                  </a>
                </li>
               
                

                <div style={{display:'flex'}}>
                  <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3"
                    checked={sesso == 'F'}
                    onChange={() => {
                      if(sesso != 'F'){
                        setSesso('F')
                      }else{
                        setSesso(null)
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3"
                  >
                    Donna
                  </label>
                  </div>
                  <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3w"
                    checked={sesso == 'M'}
                    onChange={() => {
                      if(sesso == 'M'){
                        setSesso(null)
                      }else{
                        setSesso('M')
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3w"
                  >
                    Uomo 
                  </label>
                </div>
                </div>

                <hr className="my-4" />

                <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Età
                  </a>
                </li>
                <br></br>
                <div style={{ }}>
                  <Rheostat
                    style={{}}
                    //onSliderDragMove={(x)=>{console.log('pp')}}
                    //onChange={(x)=>{setEtaValue((x.values));console.log('pollo')}}
                    onValuesUpdated={(x)=>setEtaValue((x.values))}
                    min={dateCalculate(new Date(),-18).getFullYear()}
                    max={dateCalculate(new Date(),-70).getFullYear()}
                    values={etaValue}
                  />
                  <div style={{display:'flex',marginTop:20}}>
                    <p style={{width:'50%'}}>{calculate_age(etaValue[0])} anni</p>
                    <p style={{width:'50%',textAlign:'right'}}>{calculate_age(etaValue[1])} anni</p>
                  </div>
                  
                </div>
                
                <hr className="my-4" />

                <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Altezza
                  </a>
                </li>
                <br></br>
                <div style={{ }}>
                  <Rheostat
                    style={{}}
                    //onChange={(x)=>setAltezza(x.values)}
                    onValuesUpdated={(x)=>setAltezza(x.values)}
                    min={150}
                    max={220}
                    values={altezza}
                  />
                  <div style={{display:'flex',marginTop:20}}>
                    <p style={{width:'50%'}}>{altezza[0]} cm</p>
                    <p style={{width:'50%',textAlign:'right'}}>{altezza[1]} cm</p>
                  </div>
                  
                </div>


                <hr className="my-4" />

                <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Taglia
                  </a>
                </li>
                <br></br>
                <div style={{ }}>
                  <Rheostat
                    style={{}}
                    //onChange={(x)=>setAltezza(x.values)}
                    onValuesUpdated={(x)=>setTaglia(x.values)}
                    min={36}
                    max={55}
                    values={taglia}
                  />
                  <div style={{display:'flex',marginTop:20}}>
                    <p style={{width:'50%'}}>{taglia[0]} cm</p>
                    <p style={{width:'50%',textAlign:'right'}}>{taglia[1]} cm</p>
                  </div>
                  
                </div>
                
                <hr className="my-4" />

                <li>
                  <a href="#" className="text-muted py-2 d-block">
                     Laureato
                  </a>
                </li>


                <div style={{display:'flex'}}>
                  <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3saap3l"
                    checked={isLaureato == 'SI'}
                    onChange={() => {
                      if(isLaureato != 'SI'){
                        setIsLaureato('SI')
                      }else{
                        setIsLaureato(null)
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3saap3l"
                  >
                    Si
                  </label>
                  </div>
                  <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3wsappl"
                    checked={isLaureato == 'NO'}
                    onChange={() => {
                      if(isLaureato == 'NO'){
                        setIsLaureato(null)
                      }else{
                        setIsLaureato('NO')
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3wsappl"
                  >
                    No 
                  </label>
                </div>
                </div>
                <hr className="my-4" />

            <div>
              <p className="text-muted mb-2">Tatuaggi</p>
              <div style={{display:'flex'}}>
                  <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3s"
                    checked={tatoo == 'SI'}
                    onChange={() => {
                      if(tatoo != 'SI'){
                        setTatoo('SI')
                      }else{
                        setTatoo(null)
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3s"
                  >
                    Si
                  </label>
                  </div>
                  <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3ws"
                    checked={tatoo == 'NO'}
                    onChange={() => {
                      if(tatoo == 'NO'){
                        setTatoo(null)
                      }else{
                        setTatoo('NO')
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3ws"
                  >
                    No 
                  </label>
                </div>
                </div>
              
            </div>
            

            <hr className="my-4" />


            <div>
              <p className="text-muted mb-2">Piercing</p>
              
              <div style={{display:'flex'}}>
                  <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3sw"
                    checked={piercing == 'SI'}
                    onChange={() => {
                      if(piercing != 'SI'){
                        setPiercing('SI')
                      }else{
                        setPiercing(null)
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3sw"
                  >
                    Si
                  </label>
                  </div>
                  <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3wse"
                    checked={piercing == 'NO'}
                    onChange={() => {
                      if(piercing == 'NO'){
                        setPiercing(null)
                      }else{
                        setPiercing('NO')
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3wse"
                  >
                    No 
                  </label>
                </div>
                </div>
              

                <hr className="my-4" />

            </div>

                
                <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Provincie Competenza{" "}
                    
                  </a>
                  <Select
                      value={province_competenza}
                      isMulti
                      onChange={handleSelectGroup}
                      options={provincie}
                      classNamePrefix="select2-selection"
                    />
                </li>

                <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Capelli
                  </a>
                  <Select
                      value={capelli}
                      isMulti
                      onChange={handleSelectGroupcapelli}
                      options={capellis}
                      classNamePrefix="select2-selection"
                    />
                </li>
                <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Occhi
                  </a>
                  <Select
                      value={occhi}
                      isMulti
                      onChange={handleSelectGroupocchi}
                      options={occhis}
                      classNamePrefix="select2-selection"
                    />
                </li>
                
              </ul>
            </div>

            <hr className="my-4" />

            <div>
              <p className="text-muted">Lingue</p>

              <Select
                value={lingue}
                isMulti
                onChange={handleSelectGroplingue}
                options={linguex}
                menuContainerStyle={{'zIndex': 999}}
                classNamePrefix="select2-selection"
              />
            </div>
            <hr className="my-4" />

            <div>
              <p className="text-muted">Competenze</p>

              <Select
                value={competenze}
                isMulti
                onChange={handleSelectGroupocompet}
                options={listacomp}
                menuContainerStyle={{'zIndex': 999}}
                classNamePrefix="select2-selection"
              />
            </div>

            <hr className="my-4" />

            <div>
              <p className="text-muted">Categorie Interne</p>

              <Select
                styles={{position: 'relative'}}
                value={categoriaInterna}
                isMulti
                onChange={handleSelectGroupocatint}
                options={listacateg}
                menuContainerStyle={{'zIndex': 999}}
                classNamePrefix="select2-selection"
              />
            </div>

            
            <br></br>
            
           
          </CardBody>
          <button style={{marginTop:20}} onClick={()=>genetaricerca()} class="btn btn-secondary waves-effect btn btn-secondary">Cerca</button>
        </Card>
      </Col>


      <Col style={{height:'100%',overflow:'scroll'}} xl={9} lg={8}>
        <Card>
          <ul
            className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
            role="tablist"
          >
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                
              </NavLink>
            </NavItem>
          </ul>
          <Row>
          {userList.map((user,index)=>{
            return(
              <React.Fragment>
            <Col xl="3" sm="6">
              <Card className="text-center">
                <CardBody>
                  {!user.photo && !user.img_profilo ? (
                    <div className="avatar-sm mx-auto mb-4">
                      <span
                        className={
                          "avatar-title rounded-circle bg-soft-" +
                          user.color +
                          " text-" +
                          user.color +
                          " font-size-16"
                        }
                      >
                        
                      </span>
                    </div>
                  ) : (
                    <div className="mb-4">
                      <img
                        style={{width:200,height:200,objectFit:'contain',backgroundColor:'#0c0c0c'}}
                        className="rounded mr-2"
                        src={user.photo ? user.photo : user.img_profilo}
                        alt=""
                      />
                    </div>
                  )}

                  <h5 style={{textTransform:'capitalize'}} className="font-size-15">
                    {user.nome} {user.cognome} 
                    
                  </h5>
                  {user.cell  ? (
                              <React.Fragment>
                                <a style={{float:'left'}} target='_blank' href={'https://wa.me/'+numberFormater(user.cell)}><i style={{fontSize:20}} class="bx bx-phone-outgoing"></i></a><p className="text-muted">{user.cell ?  user.cell  : null}</p>

                              </React.Fragment>
                          ) : null}
                          <a style={{float:'left'}} href= {"mailto:"+user.email}><i style={{fontSize:20}} class="bx bx-mail-send"></i></a><p className="text-muted">{user.email ? user.email  : null}</p>
                          
                  <Row style={{textAlign:'center'}}>
                 
                    <Col>
                      
                      <p className="text-muted">{user.altezza}cm</p>  

                    </Col>
                    <Col>
                    {user && user.data_di_nascita ? (
                      <p className="text-muted">Anno: {new Date(user.data_di_nascita).getFullYear()}</p>
                  ) : null}
                    </Col>
                  
                  </Row> 
                  {user && user.note ? (
                    <div style={{background:'#eee',padding:5,marginBottom:5}}>  
                     <p style={{textAlign: 'left',whiteSpace: 'break-spaces'}}>{user.note}</p> 
                    </div>
                    ) : null}
                  <p style={{textTransform:'uppercase'}} className="text-muted">{user.cf}</p>        
                  <p className="text-muted">{user.approvato ? 'Approvato' : 'Non Approvato'}</p>
                  <p className="text-muted">{user.ExponentPushToken ? <i class="bx bx-notification"></i> : <i style={{color:'red'}} class="bx bx-notification-off"></i>}</p>

                  <div>
                    {/*user.skills.map((skill, key) => (
                      <Link
                        to="#"
                        className="badge badge-primary font-size-11 m-1"
                        key={"_skill_" + key}
                      >
                        {skill.name}
                      </Link>
                    ))*/}
                  </div>
                </CardBody>
                <CardFooter className="bg-transparent border-top">
                  <div className="contact-links d-flex font-size-20">
                    {/*<div className="flex-fill">
                      <Link to="#" id={"message" + user.id}>
                        <i className="bx bx-message-square-dots" />
                        <UncontrolledTooltip
                          placement="top"
                          target={"message" + user.id}
                        >
                          Message
                        </UncontrolledTooltip>
                      </Link>
                    </div>
                    <div className="flex-fill">
                      <Link to="#" id={"project" + user.id}>
                        <i className="bx bx-pie-chart-alt" />
                        <UncontrolledTooltip
                          placement="top"
                          target={"project" + user.id}
                        >
                          Projects
                        </UncontrolledTooltip>
                      </Link>
                    </div>*/}
                    
                    <div className="flex-fill">
                      <i onClick={()=>{apriInfoUser(user.objectID)}} className="bx bx-user-plus" />
                    </div>
                    <div className="flex-fill">
                      <Link onClick={()=>close()} to={"/user-profile/"+(user.objectID ?  user.objectID  : user._id)} id={"profile" +user.objectID}>
                        <i className="bx bx-user-circle" />
                        <UncontrolledTooltip
                          placement="top"
                          target={"profile" + user.objectID}
                        >
                          Profile
                        </UncontrolledTooltip>
                      </Link>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </React.Fragment>
            )
          })}
          </Row>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default BlogList;