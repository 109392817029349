import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row,CardFooter,UncontrolledTooltip,Modal } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"


import { _getStaffUserApi } from "../../helpers/server_helper";
import {getFirebaseBackend} from "../../helpers/firebase_helper";

import { isEmpty } from "lodash"


const fireBaseBackend = getFirebaseBackend()

const Staff = props => {
  const { users, onGetUsers } = props
  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const pageOptions = {
    sizePerPage: 10,
    totalSize: 50, // replace later with size(users),
    custom: true,
  }
  const { SearchBar } = Search



  const creaNuovoUtenteOperatore = () =>{
    fireBaseBackend.registerUser('testoperatore@opertatore.com','Sk8jart83')
    .then((userCredential) => {
      // Signed in 
      var user = userCredential.user;
      console.log('vediamo');
      console.log(user);
      // ...
    })
  }
  useEffect(() => {
    
    _getStaffUserApi((userlist)=>{
      setUserList(userlist)
    })
  }, [users, onGetUsers])

  useEffect(() => {
    if (!isEmpty(users)) {
      setUserList(users)
    }
  }, [users])

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList(
      users.filter(user =>
        Object.keys(user).some(
          key =>
            typeof user[key] === "string" &&
            user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        toggle={() => {
          modal
        }}
      >
        <div className="modal-header">
          <input  style={{width:'100%',border:'1px solid #ddd',padding:10}} type='text' />
          <button
            type="button"
            onClick={() => {
              setModal(!modal)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal(!modal)
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          
        </div>
      </Modal>
          
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="staff_interno" breadcrumbItem="Staff" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                  {userList.map((user,index)=>{
                    return(
                      <React.Fragment>
                    <Col xl="3" sm="6">
                      <Card className="text-center">
                        <CardBody>
                          {!user.img_profilo ? (
                            <div className="avatar-sm mx-auto mb-4">
                              <span
                                className={
                                  "avatar-title rounded-circle bg-soft-" +
                                  user.color +
                                  " text-" +
                                  user.color +
                                  " font-size-16"
                                }
                              >
                                
                              </span>
                            </div>
                          ) : (
                            <div className="mb-4">
                              <img
                                style={{width:200,height:200,objectFit:'contain',backgroundColor:'#0c0c0c'}}
                                className="rounded mr-2"
                                src={user.img_profilo}
                                alt=""
                              />
                            </div>
                          )}

                          <h5 className="font-size-15">
                            {/*<Link to={"/user-profile/"+user._id} className="text-dark">
                              {user.nome} {user.cognome}
                            </Link>*/}
                            {user.nome} {user.cognome}
                          </h5>

                          
                          
                         

                          


                          {user.staffPosition.map((x,i)=>{
                            return <p className="text-muted" key={i}>{x}</p>
                          })}
                          {/*<p className="text-muted">{user.approvato ? 'Approvato' : 'Non Approvato'}</p>
                          <p className="text-muted">{user.ExponentPushToken ? <i class="bx bx-notification"></i> : <i style={{color:'red'}} class="bx bx-notification-off"></i>}</p>*/}

                          <div>
                            {/*user.skills.map((skill, key) => (
                              <Link
                                to="#"
                                className="badge badge-primary font-size-11 m-1"
                                key={"_skill_" + key}
                              >
                                {skill.name}
                              </Link>
                            ))*/}
                          </div>
                        </CardBody>
                        <CardFooter className="bg-transparent border-top">
                          <div className="contact-links d-flex font-size-20">
                            {/*<div className="flex-fill">
                              <Link to="#" id={"message" + user.id}>
                                <i className="bx bx-message-square-dots" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"message" + user.id}
                                >
                                  Message
                                </UncontrolledTooltip>
                              </Link>
                            </div>
                            <div className="flex-fill">
                              <Link to="#" id={"project" + user.id}>
                                <i className="bx bx-pie-chart-alt" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"project" + user.id}
                                >
                                  Projects
                                </UncontrolledTooltip>
                              </Link>
                            </div>*/}
                           
                          </div>
                        </CardFooter>
                      </Card>
                    </Col>
                  </React.Fragment>
                    )
                  })}
                  
                  </Row>
                  <button onClick={()=>setModal(true)} className="btn btn-secondary waves-effect">
                    Aggiungi Staff
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Staff
