import React, {useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button,Modal, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row,TabContent,TabPane,Table } from "reactstrap"
import {_getAllCategoriaInt , _createCategorInt,_getPresentazione,_createpre,_getpre,_updatePresentazione,_getStaffApi} from "../../helpers/server_helper"
import { Widget } from "@uploadcare/react-widget";
import ModalList from "./ProjectOverview/ModalList";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { jsPDF } from "jspdf";
import { Page, Text, View, Document, StyleSheet ,pdf,Image  } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { indexOf } from "lodash"
import algoliasearch from 'algoliasearch/lite';
import styled from "styled-components"
import bgImg from "./bgimg.png"
import src from "@math.gl/web-mercator"


const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

const client = algoliasearch(
  '3VN9LAE1IH',
  '4adcefd5da1ae8ae047903a30e3fcf48'
);
const index = client.initIndex('STAFF');



const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',

  },
  page: { flexDirection: "row", padding: 25 },
  table: {
    fontSize: 10,
    width: '100%',
    display: "grid",
    height: '100%',
    gridTemplateRows: '40% 55%',
    
    
  },
  tableOld: {
    fontSize: 10,
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch"
  },
  row:{
    display: "grid",
    height: '100%',
    gridTemplateColumns: '33% 33% 33%',
  },
  rowOld: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
  },
  cell: {
    maxWidth:'100%',
    maxHeight:'100%',
    height:'100%',
    objectFit: 'contain',
    
  },
  cellPdf: {
    maxWidth:'100%',
    maxHeight:'80%',
    height:'70%',
    objectFit: 'contain',
    
  },
  cellOld: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
    objectFit: 'cover',
    height:'100%',
    backgroundColor:'red'
  },
  header: {
    backgroundColor: "#eee"
  },
  headerText: {
    fontSize: 11,
    fontWeight: 1200,
    color: "#1a245c",
    margin: 8
  },
  tableText: {
    margin: 10,
    fontSize: 10,
  },
  title: {
    fontSize: 24,
    margin:0,
    textAlign: 'left',
  },
  author: {
    marginTop:20,
    fontSize: 12,
    marginTop:0,
    textAlign: 'left',
    marginBottom: 40,
  },
});

const urlCreate = (url) =>{
  console.log('mostro url');
  
  let urlC = new URL(url).pathname
  urlC = urlC.replace("/v0/b/mcm01-a2ec1.appspot.com/o/", "");
  urlC = 'https://mcmimageshow.imgix.net/'+urlC+'?w=500'
  console.log(urlC);
  return urlC
}

// Create Document Component
const MyDocument = ({obg}) => {
    console.log('mostro');
    console.log(obg);
  return(
    <Document>
    
      {obg && obg.map((u,i)=>{
        return(
          <Page orientation='landscape' style={styles.page} size="A4" wrap>
            <View style={styles.table}>
             
              <View style={[styles.rowOld,{marginTop:90,marginBottom:20}]}>
                <View>
                  <Text style={styles.title}>{u.nome}</Text>
                  <Text style={styles.author}>{"Altezza: "+u.altezza+"cm - Taglia: "+u.taglia+" - Scarpe: "+u.scarpe}</Text>
                </View>
                <Image
                  style={{height:80,width:113,float: 'right'}}
                  src={'https://firebasestorage.googleapis.com/v0/b/mcm01-a2ec1.appspot.com/o/logoM%2Flogo%20color-01.png?alt=media&token=b933f4ad-041f-471a-972f-aca3dbb5ef51'}
                />
                </View>
                 <View style={[styles.rowOld]}>
                    {u.galleryImgTrue.map((u,i)=>{
                      return(
                        <View style={{marginRight:10,height:'100%', maxHeight:'100%',display:'flex'}}>
                          <Image
                            style={styles.cellPdf}
                            src={urlCreate(u)}
                          />
                        </View>
                      )
                    })}
                    
                  </View>
            </View>
            <Image style={{position:'absolute',left:0,right:0,top:0,height:'600px',zIndex:-1}} src={bgImg} />

        </Page>
          /*<Page  object-fit="fill" orientation='landscape' size="A4" style={styles.page}>
            <Text style={styles.title}>Don Quijote de la Mancha</Text>
            <Text style={styles.title}>{"Altezza: "+u.altezza+"cm - Taglia: "+u.altezza+" - Scarpe: "+u.scarpe}</Text>
            
            <View styles={styles.view}>
             {u.galleryImgTrue.map((u,i)=>{
                return(
                  <Image
                    style={{height:200, width:300, objectFit: 'cover'}}
                    src={u}
                  />
                )
              })}
            </View>
            
          </Page>*/
        )
      })}  
  
  </Document>
  )
};


const ProjectsCreate = props => {
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [selectedFiles, setselectedFiles] = useState([])
  const [listaInterni, setListaInterni] = useState([])
  const [categoria, setCategoria] = useState({})
  const [special, setSpecial] = useState(false)
  const [titolo, setTitolo] = useState('')
  const [sex, setSex] = useState('')
  const [pre,setPre] = useState({})

  const [selected,setSelected] = useState(null)
  const [show,setShow] = useState(false)


  const [load, setload] = useState(false)

  const [alias, setTalias] = useState('')
  const [ragione_sociale, setragione_sociale] = useState('')
  const [indirizzo, setindirizzo] = useState('')
  const [pIva, setpIva] = useState('')
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_standardList, setModal_standardList] = useState(false)
  
  const [indexObj,setIndexObj] = useState(null)
  const [loading,setLoading] = useState(false)
  const [lista,setLista] = useState([])
  
  const {
    match: { params },
 } = props


 //Print the Invoice
 const printInvoice = () => {
  window.print()
}


const savePdf = async (document, filename) => {
  saveBlob(await pdf(document).toBlob(), filename);
};


const tettetete = async (url) => {
  let blob = await fetch(url).then(r => r.blob());
  console.log('vefdimo blod');
  console.log();
};

const newPrint = () =>{
  const doc = new jsPDF({
    orientation: "landscape"
  });
  pre.obj && pre.obj.map((u,i)=>{
    doc.text(u.nome, 10, 10);
    doc.setFontSize(26);
    doc.text(u.nome, 20, 40);

    doc.setFontSize(18);
    doc.text("Altezza: "+u.altezza+"cm - Taglia: "+u.altezza+" - Scarpe: "+u.scarpe, 20, 50);
    //let nome = {u.nome} {u.altezza} {u.taglia}  {u.scarpe}
    tettetete('https://firebasestorage.googleapis.com/v0/b/montblank-8857d.appspot.com/o/render%2FSchermata%202020-11-17%20alle%2012.00.24.png?alt=media&token=05b65c75-62d4-4595-9113-e5bf30ac2637')
    //let blob = await fetch(url).then(r => r.blob());
    let imggg = document.getElementById('pollo0')
    doc.addImage(imggg, 'JPEG', 30, 20 , 100 ,100);
    
  })

 
  doc.save("two-by-dd.pdf");
}

  useEffect(() => {
    
    if (params && params.id) {
      _getPresentazione(params.id,(results)=>{
        console.log('clinete risult');
        console.log(results);
        results.fSlug = results.slug;
        setPre(results)
      })
    }
    
  }, [])


  const testoCerca =  (value) =>{
    
    if(value.target.value.length > 5){
      index.search(value.target.value, {
        filters: '',
        hitsPerPage: 50,
      }).then(res => {
  
        setLista(res.hits)
    
      });
    }
  }

  const salva = () =>{
    let objUpdate = {...pre}
    objUpdate.id = objUpdate._id
      delete objUpdate.__v;
      delete objUpdate.createdAt;


    
    _updatePresentazione(objUpdate,(res)=>{
      
      res.fSlug = res.slug
      setPre(res)
      alert('dato salvato')
    })
  }

  const cambio = (type,value) =>{
    setload(true)
    let newT = {...pre}
    newT[type] =  value;
    setPre(newT)
    setload(false)
  }

  const startDateChange = date => {
    setstartDate(date)
  }

  const addToStaff1 = (id) =>{
    console.log('scarico id');
    console.log(id);
    let objj = {...pre}
    
    _getStaffApi(id,(risultato)=>{
      console.log(risultato);
      let obj = {
        idUser:risultato._id,
        nome:risultato.nome+' '+risultato.cognome.substring(0, 1),
        imgProfile:risultato.img_profilo,
        galleryImg:risultato.images,
        galleryImgTrue:[],
        altezza:risultato.altezza,
        scarpe:risultato.scarpe,
        taglia:risultato.taglia
      }
      objj.obj.push(obj)
      objj.id = objj._id
      delete objj.__v;
      delete objj.createdAt;
      _updatePresentazione(objj,(result)=>{
        console.log('vediamo r');
        console.log(result);
        setPre(result)
      })
    })

  }
  const endDateChange = date => {
    setendDate(date)
  }

  const addValue = (value,type) =>{
    let obj =  categoria;
    obj[type] = value.target.value;
    
    setCategoria(obj)

  }


  const editUserInfo = (ind) =>{
    setmodal_standard(true)
    setIndexObj(ind)
    console.log(pre);
  }

 

  const isAdded = (img) => {
    let imgss = pre.obj[indexObj].galleryImgTrue;
    let index = imgss.indexOf(img)
    if(index > -1){
      return true
    }else{
      return false
    }
  }

  const removeItem = (index) =>{
    let objj = {...pre}
    let arrays = objj.obj
    arrays.splice(index, 1);
    objj.obj = arrays
    objj.id = objj._id
    delete objj.__v;
    delete objj.createdAt;
    _updatePresentazione(objj,(result)=>{
      console.log('vediamo r');
      console.log(result);
      setPre(result)
    })


  }
  const addRemove = (img,remove) => {
    let objj = {...pre}
    console.log('vediamo');
    
    let imgssT = pre.obj[indexObj].galleryImgTrue;
    let imgss = pre.obj[indexObj].galleryImg;

    if(remove){
      let index = imgssT.indexOf(img)
      imgssT.splice(index, 1);
    }else{
      
      imgssT.push(img)
    }
    
    objj.obj[indexObj].imgssT
    objj.id = objj._id
    delete objj.__v;
    delete objj.createdAt;
    _updatePresentazione(objj,(result)=>{
      console.log('vediamo r');
      console.log(result);
      result.fSlug = result.slug
      setPre(result)
    })

    

  }

 

  const cambiaTiti = (value) =>{
    let pres = {...pre}
    pres.slug = value
    setPre(pres)
  }

  const cambioValore = (value,key,index) =>{
    console.log(value);
    let objtoc = {...pre}
    objtoc.obj[index][key] = value
    setPre(objtoc)
  }

  return (
    <React.Fragment>
      
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="edit" breadcrumbItem="Presentazione" />
          <Modal
        isOpen={modal_standardList}
        toggle={() => {
          modal_standardList
        }}
      >
        <div className="modal-header">
          <input onChange={(value)=>testoCerca(value)} style={{width:'100%',border:'1px solid #ddd',padding:10}} type='text' />
          <button
            type="button"
            onClick={() => {
              setModal_standardList(!modal_standardList)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <Table className="table table-centered table-nowrap">
          <tbody>
          {lista.map((member,index)=>{
              return(
                <tr  onClick={()=>addToStaff1(member.objectID)} style={{backgroundColor: member.active ? '#efef' :'#fff',cursor:'pointer'}} key={"_member_User" + index} >
                    <td style={{ width: "50px" }}>
                      {member.photo !== "Null" ? (
                        <img
                          src={member.photo}
                          className="rounded-circle avatar-xs"
                          alt=""
                        />
                      ) : (
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
                            {member.name.charAt(0)}
                          </span>
                        </div>
                      )}
                    </td>
                    <td>
                      <h5 className="font-size-14 m-0">
                      {member.nome} {member.cognome}
                      </h5>
                    </td>
                    
                    <td>
                      <div>
                        {/*map(member.skills, (skill, key) => (
                          <Link
                            to="#"
                            className="badge badge-primary font-size-11 mr-1"
                            key={"_skill_" + key}
                          >
                            {skill.name}
                          </Link>
                        ))*/}
                      </div>
                    </td>
                  </tr>
              )})}
          </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal_standardList(!modal_standardList)
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          
        </div>
      </Modal>
          <Modal
            isOpen={modal_standard}
            
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Risorsa
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/*<h5>Overflowing text to show scroll behavior {idUser}</h5>*/}
              <div style={{marginBottom:20}}>
                {indexObj || indexObj === 0 ? (
                  <div>
                    {loading ? (
                      <p>....</p>
                    ) : null}
                    <div>
                      <label>Nome</label>
                      <br></br>
                      <input onChange={(e)=>cambioValore(e.target.value,'nome',indexObj)} value={pre.obj[indexObj].nome} type='text' />
                    </div>
                    
                    <div style={{display:'flex',marginTop:20,justifyContent:'space-evenly'}}>
                    <div>
                      <label>Altezza</label>
                      <br></br>
                      <input onChange={(e)=>cambioValore(e.target.value,'altezza',indexObj)}  value={pre.obj[indexObj].altezza} type='text' />
                    </div>
                    <div>
                      <label>Taglia</label>
                      <br></br>
                      <input onChange={(e)=>cambioValore(e.target.value,'taglia',indexObj)} value={pre.obj[indexObj].taglia} type='text' />
                    </div>
                    <div>
                      <label>Scarpe</label>
                      <br></br>
                      <input onChange={(e)=>cambioValore(e.target.value,'scarpe',indexObj)} value={pre.obj[indexObj].scarpe} type='text' />
                    </div>
                    </div>


                    <div style={{marginTop:30,position:'relative',display: 'grid',gridTemplateColumns: '33% 33% 33%'}}>
                      {pre.obj[indexObj].galleryImg.map((img,i)=>{
                        if(isAdded(img)){
                          return(
                            <div style={{padding:5,position:'relative'}}>
                              <div style={{backgroundColor:'#a5a5a5',borderRadius:'30px', position:'absolute',left:2,top:2}}>
                                <p style={{color:'white',padding:10}}>✔️</p>
                              </div>
                              <img onClick={()=>addRemove(img,true)} style={{width:'100%',cursor:'pointer'}} src={img} />
                            </div>
                          )
                        }else{
                          return(
                            <div style={{padding:5}}>
                              <img  onClick={()=>addRemove(img)}style={{width:'100%',opacity:0.8,cursor:'pointer'}} src={img} />
                            </div>
                          )
                        }
                       
                      })}
                    </div>


                  </div>
                ) : null}
              </div>
              
              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                  setIndexObj(null)
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              
            </div>
          </Modal>
       
          <div>
            <input onChange={(x)=>cambiaTiti(x.target.value)} className=" font-size-16 d-print-none" value={pre.slug ? pre.slug : '' } style={{marginBottom:20, width:'30%',border:'1px solid #ddd', padding:10 , backgroundColor:'#eee',borderRadius:5}} type='text' />
            
          </div>
          
          {!pre.fSlug || (pre.fSlug != pre.slug )? (
            <button
            style={{marginBottom:10}}
            onClick={()=>salva()}
            className="btn btn-primary w-md waves-effect waves-light d-print-none"
          >
            Salva modifiche 
          </button>
          ) : null}

          <Row>
              <Col lg="12">
                
                <Card>
                  
                  <CardBody>

                    <div className="invoice-title">

                        
                        <div className="mb-4">
                            <img style={{height:50}} src='https://www.mcmcomunicazione.com/wp-content/themes/mcm/img/MCM_Logo.svg' />
                        </div>
                      </div>
                      <hr />
                      <div style={{marginTop:200}}>
                        {pre.obj && pre.obj.map((u,i)=>{
                          return(
                            <div style={{position:'relative', flexDirection: "row", padding: 25,marginBottom:200,backgroundColor:'lightGray',width:842,height:595}}>
                              <div style={styles.table}>
                                
                                <div>
                                  <div>
                                    <p style={styles.title}>{u.nome}</p>
                                    <p style={styles.author}>{"Altezza: "+u.altezza+"cm - Taglia: "+u.taglia+" - Scarpe: "+u.scarpe}</p>
                                  </div>
                                  <img
                                    style={{height:80,width:113}}
                                    src={'https://firebasestorage.googleapis.com/v0/b/mcm01-a2ec1.appspot.com/o/logoM%2Flogo%20color-01.png?alt=media&token=b933f4ad-041f-471a-972f-aca3dbb5ef51'}
                                  />
                                  </div>
                                  <div style={styles.rowOld}>
                                  {u.galleryImgTrue.map((u,i)=>{
                                          return(
                                            <div style={{marginRight:10,height:'100%', maxHeight:'100%',display:'flex',}}>
                                              <img
                                              style={styles.cell}
                                                src={u}
                                                />
                                            </div>
                                            /*<img
                                                style={styles.cell}
                                                src={u}
                                                />*/
                                            
                                          )
                                        })}
                                  </div>
                                </div>
                              
                              
                              
                              {/*<h1>{u.nome}</h1>
                              <div style={{display:'flex'}}>
                                <p style={{marginRight:10,fontSize:20}}>Altezza: {u.altezza}cm </p>
                                <p style={{marginRight:10,fontSize:20}}> - </p>
                                <p style={{marginRight:10,fontSize:20}}>Taglia: {u.taglia} </p>
                                <p style={{marginRight:10,fontSize:20}}> - </p>
                                <p style={{marginRight:10,fontSize:20}}>Scarpe: {u.scarpe} </p>
                              </div> 
                              <div style={{display: "flex",flexDirection: "row",justifyContent: "space-around",alignContent: "stretch",
                                flexWrap: "nowrap",
                                alignItems: "stretch",
                                flexGrow: 0,
                                flexShrink: 0,
                              }}>
                                {u.galleryImgTrue.map((img,index)=>{
                                  return(
                                    <img id={'pollo'+index} style={{
                                      flexGrow: 1,
                                      flexShrink: 1,
                                      flexBasis: "auto",
                                      alignSelf: "stretch",
                                      objectFit: 'cover',
                                      height:300,
                                    }} src={img}/> 
                                  )
                                })}
                              </div>
                              <div className="d-print-none">
                                <div className="">
                                  <button
                                    onClick={()=>editUserInfo(i)}
                                    className="btn btn-secondary w-md waves-effect waves-light"
                                  >
                                    modifica
                                  </button>
                                  <button
                                    style={{marginLeft:10}}
                                    onClick={()=>removeItem(i)}
                                    className="btn btn-danger w-md waves-effect waves-light"
                                  >
                                    rimuovi
                                  </button>
                                </div>
                                
                              </div>*/}

                              
                        <div style={{position:'absolute'}} className="d-print-none">
                                <div className="">
                                  <button
                                    onClick={()=>editUserInfo(i)}
                                    className="btn btn-secondary w-md waves-effect waves-light"
                                  >
                                    modifica
                                  </button>
                                  <button
                                    style={{marginLeft:10}}
                                    onClick={()=>removeItem(i)}
                                    className="btn btn-danger w-md waves-effect waves-light"
                                  >
                                    rimuovi
                                  </button>
                                </div>
                                
                              </div>
                            </div>
                          )
                        })}

                    <div className="d-print-none">
                      <div className="">
                        <button
                          onClick={()=>setModal_standardList(true)}
                          className="btn btn-primary w-md waves-effect waves-light"
                        >
                          Aggiungi Risorsa
                        </button>
                      </div>
                    </div>
                    </div>

                    {/*<div className="d-print-none">
                      <div className="float-right">
                        <Link
                          to="#"
                          onClick={newPrint/*printInvoice}
                          className="btn btn-success waves-effect waves-light mr-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        
                      </div>
                    </div>*/}

                    
                  <div className="d-print-none">
                      <div className="float-right">
                        <Link
                          to="#"
                          onClick={()=>savePdf(<MyDocument obg={pre.obj}/>, pre.fSlug ? pre.fSlug+'.pdf' : pre.obj[0].nome+".pdf")}
                          className="btn btn-success waves-effect waves-light mr-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        
                      </div>
                    </div>


                  </CardBody>
                </Card>
              </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreate

