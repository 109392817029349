import React, { useEffect,useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  TabContent,
  TabPane,
  CardTitle,
  CardHeader,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import {_getAllPre,_deletePresentazione,_createPresentazione} from "../../helpers/server_helper"
import { dateToString } from "../../helpers/formatting";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import images from "assets/images"
import companies from "assets/images/companies"

import { getProjects } from "store/actions"





const ProjectsList = props => {
  

  const [lavori,setLavori] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }





  const ViewvweList = () =>{
    switch (key) {
      case value:
        
        break;
    
      default:
        break;
    }
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      _getAllPre((result)=>{
       
        console.log(result);
        setLavori(result)
      })
      

      

    }
  }, [])
  

  const addOneEmpty = () =>{
    _createPresentazione((callba)=>{
      _getAllPre((result)=>{
       
        console.log(result);
        setLavori(result)
      })
    })
  }

  const cancellaPre = (id) =>{
    _deletePresentazione(id,()=>{
      _getAllPre((result)=>{
       
        console.log(result);
        setLavori(result)
      })
    })
  }

  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          {/* Render Breadcrumbs */}
          
          
          <CardHeader className="bg-transparent border-bottom">
            <div className="d-flex flex-wrap">
              <div className="mr-2">
                <h5 className="card-title mt-1 mb-0">Presentazioni</h5>
              </div>

              <button onClick={()=>addOneEmpty()} className="btn btn-secondary waves-effect">
                +
              </button>
              
             

              <ul
                className="nav nav-tabs nav-tabs-custom card-header-tabs ml-auto"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      console.log('polll');
                      toggle("1")
                    }}
                  >
                    Aperte
                  </NavLink>
                </NavItem>
                
                {/*<NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    I Miei
                  </NavLink>
                </NavItem>*/}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3")
                    }}
                  >
                    Chiuse
                  </NavLink>
                  </NavItem>
                
                
              </ul>
            </div>
            
          </CardHeader>
          
          

          <TabContent activeTab={activeTab} className="py-4">
            <TabPane tabId="1">
            <Row>
              <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap table-centered table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Nome</th>
                            <th scope="col">Data creazione</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(lavori, (project, index) => (
                              <tr key={index}>
                              <td>
                                
                              </td>
                              <td>
                              {project.slug}
                              </td>
                              <td>
                                {dateToString(project.createdAt)}
                              </td>
                              
                              <td>
                                
                                
                              </td>
                              <td>
                                <div className="team">
                                  
                                </div>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#"
                                    className="card-drop"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <DropdownItem><Link to={"/presentazione/"+project._id} className="text-dark">
                                       mostra
                                    </Link></DropdownItem>
                                    <DropdownItem><span onClick={()=>cancellaPre(project._id)} className="text-dark">
                                       Rimuovi
                                    </span></DropdownItem>


                                    
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>

            {/* special */}
            <TabPane tabId="3">
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap table-centered table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Projects</th>
                            <th scope="col">Due Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Team</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          

          {/*<Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row>*/}
        </Container>
      </div>
    </React.Fragment>
  )
}



export default ProjectsList

