import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card,Col,CardHeader, CardBody, CardTitle,CardText, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { indexOf, map } from "lodash"
import images from "assets/images"
import { dateToString , timeToString } from "../../../helpers/formatting";
import {_getStaffUserApi,UpdateInfoLavoro} from "../../../helpers/server_helper"
import TurnoCard from "./cardTurnoedit";

/*
turnooo(idtime){
  console.log('id');
  console.log(idtime);
  let {arrayTurni} = this.state;
  let filterArray = _.filter(arrayTurni, { 'idDate': idtime });
  console.log('mostro con filtre');
  console.log(filterArray);
  return filterArray.map((x,i)=>{
    return (
      <div style={{display:'flex'}}>
        <FormGroup style={{width:''}} disabled={false} inline={false} intent={Intent.NONE} label={ "Ora inizio"} labelFor="text-input" >
          <TimePicker value={x.start}  onChange={(newTime)=>this._changeTime(newTime,x)}  />
        </FormGroup>
        <FormGroup style={{marginLeft:10}} disabled={false} inline={false} intent={Intent.NONE} label={ "Ora fine"} labelFor="text-input" >
          <TimePicker value={x.end} onChange={(newTime)=>this._changeTime(newTime,x,true)}  />
        </FormGroup>
      </div>
    )
  })
}*/

const TeamMembers = ({giorni, id, team , turni,actionCreate }) => {

  const [teams,setTeams] = useState([])
  const [openR,setOpenR] = useState(false)




  const turnoBox = (idtime) =>{
    
    let filterArray = _.filter(turni, { 'idDate': idtime });
    console.log('booohh');
    console.log(filterArray);

    return filterArray.map((x,i)=>{
      return (
        <td>
          <TurnoCard actionCreate={()=>actionCreate()} turnoCard={x} />
        </td>
      )
    })
    
  }


  const cambiaSelect = (index) =>{
    console.log(index);
    let newArr = [...teams]
    newArr[index].active = !newArr[index].active
    setTeams(newArr)  
  }


  const salvaCambioTeam = () =>{
    //console.log(teams);
    let newReferenti = []
    teams.forEach(element => {
      if(element.active){
        newReferenti.push(element._id)
      }
    });
    let obj = {
      id:id,
      referenti:newReferenti
    }
    
    UpdateInfoLavoro(obj,(result)=>{
      alert('referenti salvati')
    })
  }

  useEffect(() => {
    
    if(turni){
      console.log('mostro turni');
      console.log(turni);
    }
    if (team) {
      let selectedTeam = []
      _getStaffUserApi((results)=>{
        //console.log(results);
        results.forEach(user => {
          
          let isJust = team.indexOf(user._id)
          
          user.active = isJust > -1 ? true : false
          selectedTeam.push(user)
        });
        setTeams(selectedTeam)
      })
      
    }
  }, [turni])


  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Gestione Turni</CardTitle>
        {openR ? (
          <button style={{border:'none'}} onClick={()=>setOpenR(!openR)}><i style={{fontSize:25}} className="mdi mdi-chevron-up"></i></button>
        ) : (
          <button style={{border:'none'}} onClick={()=>setOpenR(!openR)}><i style={{fontSize:25}} className="mdi mdi-chevron-down"></i></button>
        )}
        <br></br>
        

        {openR ? (
          <div className="table-responsive">
          <Table style={{width:'auto'}} className="table table-centered ">
            <tbody style={{ display: 'flex'}}>
            {giorni.map((giorno,i)=>{
                return(
                  <tr style={{backgroundColor:'#fff'}} key={"_member_" + i} >
                    <td style={{ width: "50px" }}>
                      <p style={{fontSize:20,fontWeight:'bold'}}>{dateToString(giorno.date)}</p>
                    </td>
                    {turnoBox(giorno.idDate)}
                    <td>
                      <TurnoCard actionCreate={()=>actionCreate()} idProg={id} giorno={giorno._id} adder={true} turnoCard={false} />
                    </td>
                    
                  </tr>
                )
              })
              }
            </tbody>
          </Table>
          
        </div>
        ) : null}
        
      </CardBody>
    </Card>
  )
}

TeamMembers.propTypes = {
  team: PropTypes.array,
}

export default TeamMembers
