import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Media } from "reactstrap"
import {UpdateInfoStaff} from "../../../helpers/server_helper"


const InputEdit = ({value,tipo,uid,iptype}) =>{

  
  const [testo,setTesto] = useState()
  const [edit,setEdit] = useState(false)



  const formatAdresse = (via) =>{   
    const axios = require('axios');
    axios.get('https://maps.googleapis.com/maps/api/geocode/json?&address='+via+'&key=AIzaSyDSnyIFG854kXKB_U0TG8sfWINHIym_ET0')
        .then(function (response) {
          // handle success
          console.log('risposta foratt addd');
          console.log(response.data);
          let indirizzo = response.data.results[0].formatted_address
          setTesto(indirizzo)
         
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setTesto(via)
        })
        .then(function () {
          // always executed
         
        });
  
  }

  const salva = () =>{
    let userUpdate = {
      uid:uid,
    }
    userUpdate[tipo] = testo


    console.log(userUpdate);
    console.log('quiiiii vediamo');
    
    
    UpdateInfoStaff(userUpdate,(res)=>{
      console.log('salvato');
      console.log(res);
      alert('dato salvato')
    })
  }

  useEffect(() => {
    if(iptype == 'indir' && value){
      formatAdresse(value)
    }else{
      setTesto(value)
    }
    
  }, [value])

  switch (iptype) {
    case 'num':
      return(
        <td>
          {edit ? (
            <div>
              <input type='number' onChange={(e)=>setTesto(e.target.value)}  value={testo} />
              <button onClick={()=>salva()}>salva</button>
            </div>
          ) : (
            <span style={{cursor:'pointer',color:'blue'}} onClick={()=>setEdit(true)}>{testo ? testo : 'edit'}</span>
          )}
        </td>
      )
    case 'indir':
      return(
        <td style={{ whiteSpace: 'normal'}}>
          {edit ? (
            <div>
              <input onChange={(e)=>setTesto(e.target.value)}  value={testo} />
              <button onClick={()=>salva()}>salva</button>
            </div>
          ) : (
            <span style={{cursor:'pointer',color:'blue'}} onClick={()=>setEdit(true)}>{testo ? testo : 'edit'}</span>
          )}
        </td>
      )
    default:
      return(
        <td>
          {edit ? (
            <div>
              <input style={{textTransform: tipo == 'cf' ? 'uppercase' : 'capitalize'}} onChange={(e)=>setTesto(e.target.value)}  value={testo} />
              <button onClick={()=>salva()}>salva</button>
            </div>
          ) : (
            <span style={{cursor:'pointer',color:'blue',textTransform: tipo == 'cf' ? 'uppercase' : 'capitalize'}} onClick={()=>setEdit(true)}>{testo ? testo : 'edit'}</span>
          )}
        </td>
      )
  }

  
}


export default InputEdit