import React, { useEffect, useState,useRef } from "react"
import { Link } from "react-router-dom"
import PropTypes, { array } from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { indexOf, isEmpty } from "lodash"
import {Alert, Col, Container, Row ,Dropdown,DropdownToggle,DropdownMenu,DropdownItem ,CardHeader,NavItem,NavLink,CardBody,Card,CardFooter,Modal,Table, CardTitle} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { getProjectDetail } from "store/projects/actions"
import ProjectDetail from "./projectDetail"
import TeamMembers from "./teamMembers"
import Personale from "./gestionePersonale"
import GiorniTurni from "./giorniTurni"
import OverviewChart from "./overviewChart"
import PrTable from "./projectTable";
import { options, series } from "common/data/projects"
//import AttachedFiles from "./attachedFiles"
//import Comments from "./comments"
import {_FindxlavoroxmeseNew,_createTurnospecialNew,UpdateSpecialturnoNew,_createTurnospecial,_getLavoroApi,_getTurnoApi,_Findxlavoroxmese,UpdateSpecialturno,UpdateInfoLavoro,UpdateGiornoArrinlavoro,ChiudiLavoro,AddStaff,_getstoricoNotifiche,CompetenzeListaFunct,_getModifiche,_getCategorieInterne} from "../../../helpers/server_helper"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


import TabellaTurni from "./TabellaTurni";
import InvitoNot from "../../../components/VerticalLayout/InvitoNot"
import ModalList from "./ModalList";

//import classnames from "classnames"
import { dateToString,dateToStringnew ,giornoString ,makeDataTest ,makeDatanuovo,numberFormater,formatAdresse,legendCategorie} from "helpers/formatting"
import algoliasearch from 'algoliasearch/lite';
import Select from "react-select"
import { provincie } from "../../../helpers/formatting";

const client = algoliasearch(
  '3VN9LAE1IH',
  '4adcefd5da1ae8ae047903a30e3fcf48'
);
const index = client.initIndex('STAFF');

const ProjectsOverview = props => {


  const textAreaRef = useRef(null);
  const [projectDetail,serProjectDetail] = useState([])
  const [giorniArrayString,setGiorniArrayString] = useState([])
  const [turni,setTurni] = useState([])
  const [turniDupli,setTurniPer] = useState([])
  const [activeTab,setactiveTab] = useState('1')
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [fase,setFase] = useState(0)
  const [peapleLegend,setPeaplelegend] = useState({})
  const [minim,setminim] = useState(false)
  const [carica,setcarica] = useState(true)
  const [isOpen,setOpen] = useState(false)
  const [gestore,setgestore] = useState(false)
  const[month,setMonth] = useState(null)
  const[storico,setStorico] = useState([])

  const[comp,setComp] = useState([])
  const[catInt,setCatInt] = useState([])

  const[listaXClinete,setListaXClinete] = useState([])
  const[modallist,setModallist] = useState(false)
  const[userToEdit,setUserToEdit] = useState(null)


  const [start,setStart] = useState(null) 
  const [end,setEnd] = useState(null) 

  const [listaEmail,setListaEmail] = useState('')

 

  const [modal_standard,setModal_standard] = useState(false)
  const [lista,setLista] = useState([])
  
  const [dateLavoro, setDateLavoro] = useState([])


  const [downloadLink, setDownloadLink] = useState('')


  const [loadingTur,setLoadingTur] = useState(false)

  const [modificati,setModificati] =useState([])

  const [colonne,setColonne] = useState([
   /* {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },*/
  ])

  const [dataTabella,setDataTabella] = useState([])

  const [schemaCk, setSchemaCk] = useState({})

  const {
     match: { params },
  } = props



  const generateMailo = (staff) => {
    // This creates the file. 
    // In my case, I have an array, and each item in 
    // the array should be on a new line, which is why
    // I use .join('\n') here.
    //console.log(staff);
    //console.log(staff.join('\n'));
    let lista = ''
    staff.forEach(element => {
      //console.log(element.email);
      if(element.email){
        lista = lista + element.email +';'
      }
       
    });
    console.log(lista);
    setListaEmail(lista)
    
    
  }


  const traduxione = (id) =>{
    let stringa = ''
    comp.forEach(element => {
      if(element.value == id){
        stringa = element.label
      } 
    });
    //console.log(comp);
    return stringa
  }

  const traduxioneInter = (id) =>{
    let stringa = ''
    catInt.forEach(element => {
      if(element.value == id){
        stringa = element.label
      } 
    });
    //console.log(comp);
    return stringa
  }

  const copyCli = () =>{
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    alert('Copied!');
  }

  const makeTextFile = (staff) => {
    // This creates the file. 
    // In my case, I have an array, and each item in 
    // the array should be on a new line, which is why
    // I use .join('\n') here.
    //console.log(staff);
    //console.log(staff.join('\n'));
    let newDateo = ''
    staff.forEach(element => {
      console.log('mostro staff');
      console.log(element);
      console.log();
      let line = element.nome+';'+element.cognome+';'+numberFormater(element.cell,true)+';si\n'
       //let line = formatAdresse(element.residenza)+';'+element.nome+' '+element.cognome+';'+numberFormater(element.cell,true)+';si\n'

      newDateo= newDateo+line
    });
    console.log(newDateo);

    const data = new Blob([newDateo], { type: 'text/plain' })

    // this part avoids memory leaks
    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink)

    // update the download link state
    setDownloadLink(window.URL.createObjectURL(data))
  }


  const gestioneRosa = (id,status) =>{
    let arrBanned = projectDetail.banned ?  [...projectDetail.banned] : []
    let progetto = projectDetail;
    
    let index = arrBanned.indexOf(id)
    console.log(index);

    if(status && index < 0 ){
      arrBanned.push(id)
    }else if(!status && index > -1 ){
      
      arrBanned.splice(index, 1);
    }

   
    progetto.banned = arrBanned


    let obj = {
      id:params.id,
    }
    
    obj.banned = arrBanned


    UpdateInfoLavoro(obj,(results)=>{
      //cleans(results)
      alert('dato salvato')
      
      serProjectDetail(progetto)
      setModallist(false)
    })

    //serProjectDetail(progetto)


  }


  const mettiNascosto = () =>{
    let obj = {
      id:params.id,
    }
    
    obj.incognito = !projectDetail.incognito


    UpdateInfoLavoro(obj,(results)=>{
      //cleans(results)
      alert(results.incognito ? 'il lavoro ora è in incognito' : 'il lavoro ora è visibile per tutti')
      let newsdsd = {...projectDetail}
      newsdsd.incognito = results.incognito
      serProjectDetail(newsdsd)
    })
  }

  const addAndSave = (objg) =>{
      let arrayList = [...listaXClinete]
      arrayList.push(objg)
      setListaXClinete(arrayList)
      setUserToEdit({images:[]})
      setModallist(false)
      alert('salvato')
  }

  const addTolista = (user) =>{
    //let arrayList = [...listaXClinete]
    let obj = {
      _id:user._id,
      nome:user.nome,
      images:user.images,
      altezza:user.altezza,
      data_di_nascita:user.data_di_nascita
    }
    //arrayList.push(obj)
    //setListaXClinete(arrayList)
    console.log(obj);
    setUserToEdit(obj)
    setModallist(true)
  }

  const actionLavoro = (tipo,status) =>{
    setLoadingTur(true)
    let obj = {
      id:params.id,
    }
    if(tipo == 'online'){
      obj.online = status
    }else{
      obj.decided = status
    }


    UpdateInfoLavoro(obj,(results)=>{
      //cleans(results)
      let prog ={...projectDetail}
      prog.online = results.online
      serProjectDetail(prog)
      setLoadingTur(false)
    })
    setFase(0)
  }

  const  getDaysInMonth = (month, year) =>{
    var date = new Date(year, month, 1);
    
   
    
    
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    
    
    return days;
  }


  const actionNovoTurnoUpdate = (e,cell) =>{
    
    setcarica(true)
    let data = [...dataTabella]
    
    
    if(data[cell.row.index][cell.column.id]){
      data[cell.row.index][cell.column.id].max = Number(e);
    }else{
      data[cell.row.index][cell.column.id] = {max:Number(e)};
    }
     
    console.log('cosa salvo!!!');
    console.log('max');
    console.log(Number(e));
    console.log('key');
    
    console.log(cell.column.id);
    console.log('id');
    console.log(data[cell.row.index].idMongo);
  
    
    let obj = {
      uid:data[cell.row.index].idMongo,
      id:data[cell.row.index].idMongo,
      value:{disp:Number(e),open:true},
      dayKey:'giorni.'+cell.column.id
    }

    console.log(obj);


    UpdateSpecialturnoNew(obj,(risultato)=>{
      console.log('cosa succese');
      setcarica(false)
      setDataTabella(data)
      //alert('ok')
    })

    /*let obj = {
      id:data[cell.row.index].mongoId
      daysNumber:

    }*/
    
    
    
    
    
    console.log(e);
    
  }


  const actionC = (e,cell) =>{
    
    setcarica(true)
    console.log('arrivo quiiiii');
    
    let data = [...dataTabella]
    
    
    if(data[cell.row.index][cell.column.id]){
      data[cell.row.index][cell.column.id].max = Number(e);
    }else{
      data[cell.row.index][cell.column.id] = {max:Number(e)};
    }
    
    

    console.log('cosa salvo!!!');

    console.log(data[cell.row.index]);
    console.log('preparo per salvare');
    
    let obj = {
      id:data[cell.row.index].idMongo
    }

    Object.keys(data[cell.row.index]).forEach(key => {
      
      if(key.includes("day")){
        console.log(key, data[cell.row.index][key]);
        if(!obj.daysNumber){
          obj.daysNumber = {};
          obj.daysNumber[key] = Number(data[cell.row.index][key].max);

        }else{
          obj.daysNumber[key] = Number(data[cell.row.index][key].max);

        }
      }

    
      
      
    
    
      
     
    });

    UpdateSpecialturno(obj,(risultato)=>{
      console.log('cosa succese');
      setcarica(false)
      setDataTabella(data)
      //alert('ok')
    })

    /*let obj = {
      id:data[cell.row.index].mongoId
      daysNumber:

    }*/
    
    
    
    
    
    console.log(e);
    
  }


  const pepaleRead = (id,info) =>{
  
    

    return (
      <Col style={{}}>
            <Card style={{backgroundColor:'#ffeed2'}} className="mini-stats-wid">
              <CardBody>
              {/*<div style={{background:'#12375d',padding:5,marginBottom:10,textAlign:'center'}}>
                  {peapleLegend[id] ? (
                    <div>
                      <img style={{width:30,borderRadius:15,textAlign:'center',marginBottom:10}} src={peapleLegend[id].avatar} ></img>
                      <p style={{fontSize:12, color:'white'}}>{peapleLegend[id].nome} {peapleLegend[id].cognome}</p>
                    </div>
                  ) : null }
                
                  {info.ckInStatus ?  <p>ckIn: {new Date(info.ckIn).getHours()}:{new Date(info.ckIn).getMinutes()}</p> : null}
                  {info.ckOutStatus ?  <p>ckOut: {new Date(info.ckOut).getHours()}:{new Date(info.ckOut).getMinutes()}</p> : null}

                </div>*/}
                <div className="d-flex flex-wrap">
                  <div style={{width:'100%'}} className="">
                  {peapleLegend[id] ? (
                    <div>
                    {/* <img style={{width:30,borderRadius:15,textAlign:'center',marginBottom:10}} src={peapleLegend[id].avatar} ></img>*/}
                    <p className="text-muted mb-2">{peapleLegend[id].nome} {peapleLegend[id].cognome}</p>
                    </div>
                  ) : 'no name' }
                    <h5 className="mb-0">{new Date(info.ckIn).getHours()}:{new Date(info.ckIn).getMinutes()} -{">"} {new Date(info.ckOut).getHours()}:{new Date(info.ckOut).getMinutes()}</h5>
                  </div>

                  
                </div>
              </CardBody>
            </Card>
          </Col>
    )
  
  
  }


  const faitabellamese22 = (month,newY,progg) =>{

    console.log('vediamo prog');
    console.log(progg);
    console.log(month);
    console.log('_____');

    let dateM =  month;
    let dateY = new Date(progg.from).getFullYear();
    let newYDate = '2022';

    
    let dateToUse = getDaysInMonth(dateM, newY ? newYDate : dateY);

    let coll = [{
      Header: 'orari',
      accessor: 'orari',
    }];
    dateToUse.forEach(element => {
      coll.push(
        {
          Header: giornoString(element.getDay())+' '+element.getDate(),
          accessor: 'day'+element.getDate(),
        }
      )
    });

    
    let meseAnno =  String(month) +'2022';

    if(month == 0){
      meseAnno = '02022'
    }

    if(projectDetail.newV){

      console.log('vediamo cosa sbaglio ');
      console.log(meseAnno);
      _FindxlavoroxmeseNew(progg._id,meseAnno,(risultato)=>{
        console.log('ecco cosa esce newwww');
        console.log(progg._id+' :id, meseAnno: '+meseAnno);
       
        let dato = risultato;
        console.log('vedimao');
        console.log(dato);
        
        
        let dataDausare = makeDatanuovo(dato)


        console.log('dato testicchito');
        
  
        console.log(dataDausare);
        setcarica(false)
        setDataTabella(dataDausare)
      })
    }else{
      _Findxlavoroxmese(progg._id,meseAnno,(risultato)=>{
        console.log('ecco cosa esce');
        console.log(progg._id+' :id, meseAnno: '+meseAnno);
       
        let dato = risultato;
        
        setTurniPer(dato)
        
        let dataDausare = makeDataTest(dato)
  
        console.log('dato testicchito old sono quiiii');
        console.log(dataDausare);
        setcarica(false)
        setDataTabella(dataDausare)
      })
    }

    

    
    
    
    
    //console.log('mostro colonne');
    //console.log(coll);
    setColonne(coll)
  }

  const faitabellamese = (month,newY,progg) =>{
    let dateM =  month;
    let dateY = new Date(progg.from).getFullYear();
    let newYDate = new Date().getFullYear();

    
    let dateToUse = getDaysInMonth(dateM,newY ? newYDate : dateY);

    let coll = [{
      Header: 'orari',
      accessor: 'orari',
    }];
    dateToUse.forEach(element => {
      coll.push(
        {
          Header: giornoString(element.getDay())+' '+element.getDate(),
          accessor: 'day'+element.getDate(),
        }
      )
    });

    
    let meseAnno =  (month ? month : new Date(progg.from).getMonth())+'2021';

    if(month == 0){
      meseAnno = '02021'
    }

    if(projectDetail.newV){

      console.log('vediamo cosa sbaglio ');
      console.log(meseAnno);
      _FindxlavoroxmeseNew(progg._id,meseAnno,(risultato)=>{
        console.log('ecco cosa esce newwww');
        console.log(progg._id+' :id, meseAnno: '+meseAnno);
       
        let dato = risultato;
        console.log('vedimao');
        console.log(dato);
        
        
        let dataDausare = makeDatanuovo(dato)


        console.log('dato testicchito');
        
  
        console.log(dataDausare);
        setcarica(false)
        setDataTabella(dataDausare)
      })
    }else{
      _Findxlavoroxmese(progg._id,meseAnno,(risultato)=>{
        console.log('ecco cosa esce');
        console.log(progg._id+' :id, meseAnno: '+meseAnno);
       
        let dato = risultato;
        
        setTurniPer(dato)
        
        let dataDausare = makeDataTest(dato)
  
        console.log('dato testicchito old');
        console.log(dataDausare);
        setcarica(false)
        setDataTabella(dataDausare)
      })
    }

    

    
    
    
    
    //console.log('mostro colonne');
    //console.log(coll);
    setColonne(coll)
  }

  const duplicaturno = () =>{
    let meseAnno =  "62022";
    
    console.log(turniDupli);
    turniDupli.forEach((element,i) => {
      if(i > 0){
        //console.log(element);
        let newObj = {
          daysNumber:element.daysNumber,
          lavoro:element.lavoro,
          meseAnno:meseAnno,
          turnoString:element.turnoString
        }

        //console.log(newObj);
        _createTurnospecial(newObj,(rerr)=>{
          console.log('fatto');
  
        })
      }
    });

    

  }


  const creaTurno = (newV) =>{
    
    
    let meseAnno =  (month ? month : new Date(progg.from).getMonth())+'2022';
    let obj = {
      turnoString: start+'-'+end,
      meseAnno:meseAnno,
      lavoro:projectDetail._id
    }

    if(newV){

      let stringMese = ''

      if(obj.meseAnno.length == 6){
        let anno = meseAnno.slice(-4);
        let mese = meseAnno.substring(0, 2)
        console.log('meseeee');
        console.log(mese);
        stringMese = (Number(mese)+1)+'-'+anno
      }else{
        let anno = meseAnno.slice(-4);
        let mese = meseAnno.substring(0, 1)
        stringMese = '0'+(Number(mese)+1)+'-'+anno
      }

      console.log(stringMese); 
      obj.meseAnno = stringMese
      _createTurnospecialNew(obj,(rerr)=>{
        setcarica(true)
        _FindxlavoroxmeseNew(projectDetail._id,meseAnno,(risultato)=>{
        
       
        let dato = risultato;
        
        let dataDausare = makeDatanuovo(dato)
  
        console.log(dataDausare);
        setcarica(false)
        setDataTabella(dataDausare)
      })
      })
    }else{
      _createTurnospecial(obj,(rerr)=>{
        setcarica(true)
      _Findxlavoroxmese(projectDetail._id,meseAnno,(risultato)=>{
        
       
        let dato = risultato;
        
        let dataDausare = makeDataTest(dato)
  
        console.log(dataDausare);
        setcarica(false)
        setDataTabella(dataDausare)
      })
      })
    }

    
   
    


  }






const midificati = (id) =>{
  _getModifiche(id ? id :projectDetail._id,(result)=>{
    console.log(result);
    setModificati(result)
  })
}

  const testoCerca =  (value) =>{
    
    if(value.target.value.length > 5){
      index.search(value.target.value, {
        filters: '',
        hitsPerPage: 50,
      }).then(res => {
  
        setLista(res.hits)
    
      });
    }
  }

  const isInList = (id) =>{
    var index = projectDetail.banned.indexOf(id);
    if(index > -1){
      return true
    }else{
      return false
    }
  }

  const isSeleted = (id) =>{
    var index = projectDetail.selected.findIndex(x => x._id === id);
    if(index > -1){
      return true
    }else{
      return false
    }
  }

  const getPapataci2021 = (lavoro,month) =>{

    setMonth(month)
    console.log('mese cambio');
    console.log(month);
    
    
    if(month || month == 0){
      
      faitabellamese(month,true,lavoro)
    }


  }


  const getPapataci2022 = (lavoro,month) =>{

    setMonth(month)
    console.log('mese cambio 2022');
    console.log(month);
    
    
    if(month || month == 0){
      //alert('qui okkk')
      faitabellamese22(month,true,lavoro)
    }


  }


  const apertoNotifica = (id,letto) =>{
    if(letto){
      if(letto[id]){
        return true
      }else{
        return false
      } 
    }
    
  }

  const candidato = (id) =>{
    const found = projectDetail.staff1.some(el => el._id === id);
    if(found){
      return true 
    }else{
      return false
    }
    
  }

  const cambioDay = () =>{
    let obj = {
      id:params.id,
      array:giorniArrayString
    }

    UpdateGiornoArrinlavoro(obj,(risultato)=>{
      console.log('ok risult');
      console.log(risultato);
    })
   
  }

  const addToStaff1 = (idUser) =>{
    let obj = {
      uid:params.id,
      staff1:idUser
    }
    AddStaff(obj,(result)=>{
      _getLavoroApi(params.id,(results)=>{
        //console.log(results);
        //console.log('mostro i giorni');
        //console.log(results.giorni);
        let arraysGiorni = []
        results.giorni.forEach(element => {
          arraysGiorni.push(dateToString(element.date))
        });
        setGiorniArrayString(arraysGiorni)

        cleans(results)
        //serProjectDetail(results)
        

        setModal_standard(false)

      })
     
    })

  }

  const cleans = (obj) =>{
    let usa = obj
    
    var staff = obj.staff1;
    let jsonObject = staff.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    
    //var uniqueNames = [];


    /*$.each(staff, function(i, el){
        console.log(el._id);
        if($.inArray(el._id, uniqueNames._id) === -1) uniqueNames.push(el);
    });*/
    usa.staff1 = uniqueArray.reverse()
    serProjectDetail(usa)
    _getTurnoApi(params.id,(results)=>{
      console.log('mostro turni');
      console.log(results);
      setTurni(results)
      setLoadingTur(false)
    })

  }

  const handleSelectGroupocompet = (competenze) =>{
      console.log(competenze);
      let arr = []
      if(competenze){
        competenze.forEach(element => {
          arr.push(element.value)
        });
        ckstttoptim(arr,'competenze')
      }else{
        ckstttoptim(null,'competenze')
      }
      
     
  } 

  const handleSelectGroupocInt = (competenze) =>{
    console.log(competenze);
    let arr = []
    if(competenze){
      competenze.forEach(element => {
        arr.push(element.value)
      });
      ckstttoptim(arr,'categoriaInterna')
    }else{
      ckstttoptim(null,'categoriaInterna')
    }
    
   
} 


const azzera = (type) =>{
  let ckkk = {...schemaCk}
  delete ckkk[type] 
  setSchemaCk(ckkk)
}

  const handleSelectGroup = (gruppo) =>{
    console.log(gruppo);
    let arr = []
    if(gruppo){
      gruppo.forEach(element => {
        arr.push(element.value)
      });
      ckstttoptim(arr,'pCompetenza')
    }else{
      ckstttoptim(null,'pCompetenza')
    }

} 

const salvaSchemaCk = () =>{

  let obj = {
    id:params.id,
  }
  obj.schemaCk = schemaCk;


  UpdateInfoLavoro(obj,(results)=>{
    //cleans(results)
    alert('dato salvato')
  })
}

const ckstttoptim = (value,type) =>{
  let ckkk = {...schemaCk}
  ckkk[type] = value
  setSchemaCk(ckkk)
}

  useEffect(() => {
    if (params && params.id) {

      
      _getstoricoNotifiche(params.id,(notifiche)=>{
        console.log('storico');
        console.log(notifiche);
        setStorico(notifiche)
      })

      CompetenzeListaFunct((comp)=>{
      
        setComp(comp)
      })



      _getCategorieInterne((risultato)=>{
        console.log('vediamo cated');
        console.log(risultato);

        setCatInt(risultato)
      })
      
      //setCatInt
      midificati(params.id)
  
      _getLavoroApi(params.id,(results)=>{
        //console.log(results);
        //console.log('mostro i giorni');
        //console.log(results.giorni);
        let arraysGiorni = []
        
        results.giorni.forEach(element => {
          console.log('ved');
          console.log(element);
          arraysGiorni.push(dateToStringnew(element.date))
        });

        console.log('mostro schema');
        console.log(results);
        console.log(results.schemaCk);
        setSchemaCk(results.schemaCk ? results.schemaCk : {} )

        console.log('vediamo data stringonaaa');
        console.log(arraysGiorni.date);

        setGiorniArrayString(arraysGiorni)
        cleans(results)
        //serProjectDetail(results)

        

        console.log('qui progetto e mo!');
        console.log(results);


        setgestore(results.referenti.includes(JSON.parse(localStorage.getItem("authUser")).mongoDbUid))
        
        

        let peapleLegend = []
        results.selected.forEach(element => {
          peapleLegend[element._id] = {
            nome:element.nome,
            cognome:element.cognome,
            avatar:element.img_profilo ? element.img_profilo : ''
          }
        });
        setPeaplelegend(peapleLegend)
        


        setMonth(new Date().getMonth())


        if(results.newV){
          
        }else{
          //qui cambiato getPapataci2021
          getPapataci2022(results,new Date().getMonth())
        }
        



      })

      _getTurnoApi(params.id,(results)=>{
        console.log('mostro turni');
        console.log(results);
        setTurni(results)
      })
      
    }
  }, [])


  const modificatotemp = (id) =>{
    console.log('vediamooooo');
    console.log(id);
    let pos = modificati.map(function(e) { return e.staff; }).indexOf(id);
    console.log(pos);
    if(pos > -1){
      return true
    }else{
      return false
    }

  }

  return (
    <React.Fragment>
      
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          modal_standard
        }}
      >
        <div className="modal-header">
          <input onChange={(value)=>testoCerca(value)} style={{width:'100%',border:'1px solid #ddd',padding:10}} type='text' />
          <button
            type="button"
            onClick={() => {
              setModal_standard(!modal_standard)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <Table className="table table-centered table-nowrap">
          <tbody>
          {lista.map((member,index)=>{
              return(
                <tr  onClick={()=>addToStaff1(member.objectID)} style={{backgroundColor: member.active ? '#efef' :'#fff',cursor:'pointer'}} key={"_member_User" + index} >
                    <td style={{ width: "50px" }}>
                      {member.photo !== "Null" ? (
                        <img
                          src={member.photo}
                          className="rounded-circle avatar-xs"
                          alt=""
                        />
                      ) : (
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
                            {member.name.charAt(0)}
                          </span>
                        </div>
                      )}
                    </td>
                    <td>
                      <h5 className="font-size-14 m-0">
                      {member.nome} {member.cognome}
                      </h5>
                    </td>
                    
                    <td>
                      <div>
                        {/*map(member.skills, (skill, key) => (
                          <Link
                            to="#"
                            className="badge badge-primary font-size-11 mr-1"
                            key={"_skill_" + key}
                          >
                            {skill.name}
                          </Link>
                        ))*/}
                      </div>
                    </td>
                  </tr>
              )})}
          </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal_standard(!modal_standard)
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Aggiungi
          </button>
        </div>
      </Modal>
      <div className="page-content">
      {modificati.length > 0 ? (
        <Alert color="warning" role="alert">
        Ci sono nuove candidature e/o cambi sulle disponibilità
      </Alert>
      ) : null}
      
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Lavoro" />

          {!isEmpty(projectDetail) && (
            <>
          
            <Row style={{marginBottom:20}}>
            
            {projectDetail && projectDetail.chiuso ? (
              <p style={{fontWeight:'bold'}}>Lavoro Chiuso</p>
            ) : (
              <React.Fragment>



              { gestore ? (
                <React.Fragment>

              <Dropdown
                isOpen={btnprimary1}
                toggle={() => setBtnprimary1(!btnprimary1)}
                >
                {projectDetail.online ? (
                  <DropdownToggle tag="button" className="btn btn-success">
                  <i className="bx bx-check-double font-size-16 align-middle mr-2"></i>{" "} 
                    Visibine nell'app <i className="mdi mdi-chevron-down"/>
                  </DropdownToggle>
                ) : (
                  <DropdownToggle tag="button" className="btn btn-warning">
                  <i className="bx bx-check-double font-size-16 align-middle mr-2"></i>{" "} 
                  In Bozza <i className="mdi mdi-chevron-down"/>
                  </DropdownToggle>
                )}

                <DropdownMenu>
                  <DropdownItem onClick={() => {if(window.confirm('Prima di mettere visibile la scheda del lavoro, controlla di aver inserito i tutti i parametri compresi i turni del lavoro!! clicca ok se hai controllato e vuoi mettere visibile la scheda')){actionLavoro('online',true)}}}>Metti visibile</DropdownItem>

                  
                  <DropdownItem onClick={()=>actionLavoro('online',false)}>Metti in Bozza</DropdownItem>
                </DropdownMenu>
              </Dropdown>{" "}
                </React.Fragment>
                
              ) : (
                <React.Fragment>

              <Dropdown
                isOpen={btnprimary1}
                toggle={() => setBtnprimary1(!btnprimary1)}
                >
                {projectDetail.online ? (
                  <DropdownToggle tag="button" className="btn btn-success">
                  <i className="bx bx-check-double font-size-16 align-middle mr-2"></i>{" "} 
                    Visibine nell'app <i className="mdi mdi-chevron-down"/>
                  </DropdownToggle>
                ) : (
                  <DropdownToggle tag="button" className="btn btn-warning">
                  <i className="bx bx-check-double font-size-16 align-middle mr-2"></i>{" "} 
                  In Bozza <i className="mdi mdi-chevron-down"/>
                  </DropdownToggle>
                )}

                
              </Dropdown>{" "}
                </React.Fragment>
              )}
              <button onClick={gestore ? ()=>{setOpen(true)} : ()=>alert('non hai il permesso')} style={{marginLeft:10}} class="waves-effect waves-light btn btn-primary">Invita</button> 
              {projectDetail.incognito ? (
                <button  onClick={ ()=>{mettiNascosto()} } style={{marginLeft:10}} class="waves-effect waves-light btn btn-primary">Lavoro incognito</button> 

              ) : (
                <button  onClick={ ()=>{mettiNascosto()} } style={{marginLeft:10}} class="waves-effect waves-light btn btn-outline-primary">Lavoro incognito</button> 

              )}

                {(!projectDetail.special ||  !projectDetail.newV) && !projectDetail.decided ? (
                  <button onClick={() => {if(window.confirm('Vuoi entrare nella fase di gestione evento, accettando invieremo la conferma ai selezionati')){actionLavoro('inix',!projectDetail.decided)};}} style={{marginLeft:10}} class="waves-effect btn btn-outline-success">Inizia fase gestione Lavoro</button> 
                ) : (
                  null
                )}
                {(!projectDetail.special || !projectDetail.newV ) && projectDetail.decided ? (
                  <button onClick={() => {if(window.confirm("Vuoi uscire dalla fase gestione ? facendo cosi l'evento tornerà online")){actionLavoro('inix',!projectDetail.decided)};}} style={{marginLeft:10}} class="waves-effect waves-light btn btn-success">Inizia fase gestione Lavoro</button> 
                ) : (
                  null
                )}


                {gestore ? (
                    <button onClick={() => {if(window.confirm("Vuoi chiudere il lavoro? cosi facendo verranno cancellati alcuni dati")){ChiudiLavoro({uid:projectDetail._id},(r)=>console.log(r))};}}  class="waves-effect btn btn-danger" style={{float:'right',position:'absolute',right:'40px'}}>Termina Lavoro</button>

                ) : (
                  <button onClick={() => {if(window.confirm("Vuoi chiudere il lavoro? cosi facendo verranno cancellati alcuni dati")){ChiudiLavoro({uid:projectDetail._id},(r)=>console.log(r))};}}  class="waves-effect btn btn-danger" style={{float:'right',position:'absolute',right:'40px'}}>Termina Lavoro</button>

                )}
              </React.Fragment>
              
            )}  
            

              
              
             
              </Row>

              <Row style={{marginBottom:20}}>
                {fase == 0 ? (
                    <button style={{marginRight:10}} class="waves-effect waves-light btn btn-primary">Settaggi</button> 
                ) : (
                  <button onClick={()=>setFase(0)} style={{marginRight:10}} class="waves-effect btn btn-outline-primary">Settaggi</button> 

                )}

                {fase == 2 ? (
                    <button style={{marginRight:10}} class="waves-effect waves-light btn btn-primary">Gestione Staff</button> 
                ) : (
                  <button onClick={()=>{setFase(2);midificati()}} style={{marginRight:10}} class="waves-effect btn btn-outline-primary">Gestione Staff</button> 

                )}

                {fase == 1 ? (
                    <button style={{marginRight:10}} class="waves-effect waves-light btn btn-primary">Gestione Lavoro</button> 
                ) : (
                  <button onClick={()=>{setFase(1);midificati()}} style={{marginRight:10}} class="waves-effect btn btn-outline-primary">Gestione Lavoro</button> 

                )}
              </Row>
              <CardHeader className="bg-transparent border-bottom">
            <div className="">
              
              
            </div>
          </CardHeader>
              

              {fase == 0 ? (
                <Row>
                <Col lg="8">
                  <ProjectDetail gestore={gestore} project={projectDetail} />
                  
                </Col>

                <Col lg="4">
                  <TeamMembers gestore={gestore} id={projectDetail._id} team={projectDetail.referenti} />
                  <div style={{backgroundColor:'#eee',padding:5,width:'100%',border:'1px solid #ddd'}}>
                
              <div style={{}}>
                <h2 style={{padding:5}} >Controllo requisiti</h2>
              <a href="#" className="text-muted py-2 d-block mb-2">
                      <i className="mdi mdi-chevron-right mr-1"></i> Sesso{" "}
                      
                    </a>
                  <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3d"
                    checked={schemaCk.sesso == 'F'}
                    onChange={() => {
                      if(schemaCk.sesso != 'F'){
                        //setSesso('F')
                        ckstttoptim('F','sesso')
                      }else{
                        //setSesso(null)
                        ckstttoptim(null,'sesso')
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3d"
                  >
                    Donna
                  </label>
                  </div>
                  <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckcolor3wd"
                    checked={schemaCk.sesso == 'M'}
                    onChange={() => {
                      if(schemaCk.sesso == 'M'){
                        //setSesso(null)
                        ckstttoptim(null,'sesso')
                      }else{
                        ckstttoptim('M','sesso')
                        //setSesso('M')
                      }
                      
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckcolor3wd"
                  >
                    Uomo 
                  </label>
                </div>
                </div>


                <div>
                  <div style={{display: 'flex',justifyContent: 'space-between'}}>   
                  <div><i className="mdi mdi-chevron-right mr-1"></i>Provincie Competenza{" "}</div>
                  <button onClick={()=>azzera('pCompetenza')} style={{border:'none',color:'red'}}>azzera</button>
                  </div>
                    
                    <div style={{padding:10,display:'flex'}}>
                      {schemaCk && schemaCk.pCompetenza && schemaCk.pCompetenza.map((e)=>{
                        return(
                          <div style={{backgroundColor:'#ddd',padding:10,marginRight:5}}>
                            {e}
                          </div>
                        )
                      }) }
                    </div>

                    <Select
                        //value={province_competenza}
                        isMulti
                        onChange={handleSelectGroup}
                        options={provincie}
                        classNamePrefix="select2-selection"
                      />
                </div>
              <div>
                <br></br>
                
                <div style={{display: 'flex',justifyContent: 'space-between'}}>   
                  <div><i className="mdi mdi-chevron-right mr-1"></i>Competenze{" "}</div>
                  <button onClick={()=>azzera('competenze')} style={{border:'none',color:'red'}}>azzera</button>
                  </div>
                  <div style={{display:'grid'}}>
                {schemaCk && schemaCk.competenze && schemaCk.competenze.map((e)=>{
                        return(
                          <div style={{marginBottom:10, backgroundColor:'#ddd',padding:10,marginRight:5}}>
                            {traduxione(e)}
                          </div>
                        )
                      }) }
                </div>      
                <br></br>
                <div>
                  
                  
                  
                </div>
                <Select
                  style={{marginTop:5}} 
                  //value={competenze}
                  isMulti
                  onChange={handleSelectGroupocompet}
                  options={comp}
                  menuContainerStyle={{'zIndex': 999}}
                  classNamePrefix="select2-selection"
                />
              </div>
              <div>
                <br></br>
                  
                <div style={{display: 'flex',justifyContent: 'space-between'}}>   
                <div><i className="mdi mdi-chevron-right mr-1"></i>Categirie Interne{" "}</div>
                <button onClick={()=>azzera('categoriaInterna')} style={{border:'none',color:'red'}}>azzera</button>
                </div>
                <div style={{display:'grid'}}>
                {schemaCk && schemaCk.categoriaInterna && schemaCk.categoriaInterna.map((e)=>{
                        return(
                          <div style={{marginBottom:10, backgroundColor:'#ddd',padding:10,marginRight:5}}>
                            {traduxioneInter(e)}
                          </div>
                        )
                      }) }
                </div> 
                <br></br>
                <div>
                  
                  
                  
                </div>
                <Select
                  style={{marginTop:5}} 
                  //value={competenze}
                  isMulti
                  onChange={handleSelectGroupocInt}
                  options={catInt}
                  menuContainerStyle={{'zIndex': 999}}
                  classNamePrefix="select2-selection"
                />
              </div>

                  <button style={{marginTop:10,width:'100%'}} onClick={()=>salvaSchemaCk()} class="mt-10 btn btn-primary waves-effect waves-light btn btn-primary">Salva</button>

              </div>
                </Col>
              </Row>
              ) : (
                null
              )}


            {(!projectDetail.special || !projectDetail.newV) && fase == 2 ? (
              <React.Fragment>
                <ModalList  gestioneRosa={(x,y)=>gestioneRosa(x,y)} isOpen={modallist} user={userToEdit} chiudi={()=>{setModallist(false);setUserToEdit(null)}}  actionSave={(obj)=>addAndSave(obj)}/>
               <button style={{border:'none',background:'transparent',color:'black', padding:20}} onClick={()=>setModal_standard(true)} >Aggiungi staff</button>
               <button style={{border:'none',background:'transparent',color:'black', padding:20}} onClick={()=>console.log('')} >ck_congruenza</button>
               <button style={{border:'none',background:'transparent',color:'black', padding:20}} onClick={()=>midificati()} >prova modifica</button>
                <Row>
                
                {projectDetail.staff1.map((user,index)=>{
                    return(
                      <React.Fragment>
                       
                    <Col xl="3" sm="6">
                      <Card style={{backgroundColor: isInList(user._id) ?'red' : null,opacity:isInList(user._id) ? 0.2 : 1}} className="text-center">
                        <div style={{marginTop:10}}>
                          <button onClick={()=>addTolista(user)} style={{border:'none',fontSize:20}}><i class="bx bx-credit-card-front"></i></button>
                          <button style={{border:'none',fontSize:20}}><i class="bx bx-edit"></i></button>
                        </div>
                        <CardBody>
                          {modificatotemp(user._id) ? <p style={{padding:5}} className="badge badge-warning font-size-11 mr-1">modificato</p> : null}
                          {/*user.competenze.map((x,i)=>{
                            if(x == '5e278516f1df30031efaf8d8' || x == '5e278523f1df30031efaf8d9'){
                              return <p>ok</p>
                            }
                           
                          })*/}

                          { !user.typoDI || !user.sEmissione || !user.cittadinanza || !user.cID ? (
                            <div style={{display:'flex'}}>
                              <p style={{padding:10,background:'red',color:'white'}} className="badge  font-size-11 mr-1" >Dati da richiedere</p>

                              {user.cell  ? (
                                      <a target='_blank' href={'https://wa.me/'+numberFormater(user.cell)}><p className="text-muted"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M8.26 1.289l-1.564.772c-5.793 3.02 2.798 20.944 9.31 20.944.46 0 .904-.094 1.317-.284l1.542-.755-2.898-5.594-1.54.754c-.181.087-.384.134-.597.134-2.561 0-6.841-8.204-4.241-9.596l1.546-.763-2.875-5.612zm7.746 22.711c-5.68 0-12.221-11.114-12.221-17.832 0-2.419.833-4.146 2.457-4.992l2.382-1.176 3.857 7.347-2.437 1.201c-1.439.772 2.409 8.424 3.956 7.68l2.399-1.179 3.816 7.36s-2.36 1.162-2.476 1.215c-.547.251-1.129.376-1.733.376"/></svg></p></a>
                                  ) : null}
                            </div>
                          ) : <p style={{padding:10,background:'green',color:'white'}} className="badge  font-size-11 mr-1" >Dati OK</p> }

                          {!user.img_profilo ? (
                            <div className="avatar-sm mx-auto mb-4">
                              <span
                                className={
                                  "avatar-title rounded-circle bg-soft-" +
                                  user.color +
                                  " text-" +
                                  user.color +
                                  " font-size-16"
                                }
                              >
                                
                              </span>
                            </div>
                          ) : (
                            <div className="mb-4">
                              <img
                                style={{width:200,height:200,objectFit:'contain',backgroundColor:'#0c0c0c'}}
                                className="rounded mr-2"
                                src={user.img_profilo}
                                alt=""
                              />
                            </div>
                          )}

                          <h5 style={{textTransform:'capitalize'}} className="font-size-15">
                            
                              {user.nome} {user.cognome}
                            
                          </h5>
                          {user.cell  ? (
                                      <a target='_blank' href={'https://wa.me/'+numberFormater(user.cell)}><p className="text-muted">{user.cell ?  user.cell  : null}</p></a>
                                  ) : null}
                                  <a href= {"mailto:"+user.email}><p className="text-muted">{user.email ? user.email  : null}</p></a>
                          <Row style={{textAlign:'center'}}>
                            <Col>
                              <p className="text-muted">{user.altezza}cm</p>  

                            </Col>
                            <Col>
                            {user && user.data_di_nascita ? (
                              <p className="text-muted">Anno: {new Date(user.data_di_nascita).getFullYear()}</p>
                          ) : null}
                            </Col>
                          
                          </Row>
                          {/*user.residenza*/}
                          {user.residenza || !user.residenza == '' ? (
                             <p className="text-muted"><span style={{fontWeight:'bold'}}>Residenza: </span>{ user.newResidenza && user.newResidenza.provincia}</p>

                          ) : null}

                          {user.domicilio ? (
                              <p className="text-muted"><span style={{fontWeight:'bold'}}>Domicilio: </span>{user.domicilio.split(",").length > 4 ? user.domicilio.split(",")[3] : user.domicilio.split(",")[2]}</p>

                          ) : null}
        
                          {/*<p className="text-muted">{user.approvato ? 'Approvato' : 'Non Approvato'}</p>
                          <p className="text-muted">{user.ExponentPushToken ? <i class="bx bx-notification"></i> : <i style={{color:'red'}} class="bx bx-notification-off"></i>}</p>*/}

                          <div>
                            {/*user.skills.map((skill, key) => (
                              <Link
                                to="#"
                                className="badge badge-primary font-size-11 m-1"
                                key={"_skill_" + key}
                              >
                                {skill.name}
                              </Link>
                            ))*/}
                          </div>

                          <div style={{display:'flex',flexWrap: 'wrap'}}>
                          {user.province_competenza.map((pr,i)=>{
                              return(
                                <div style={{padding:10}}><p>{pr}</p></div>
                              )
                            })}
                          </div>
                        </CardBody>
                        <CardFooter className="bg-transparent border-top">
                          <div className="contact-links d-flex font-size-20">
                            {/*<div className="flex-fill">
                              <Link to="#" id={"message" + user.id}>
                                <i className="bx bx-message-square-dots" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"message" + user.id}
                                >
                                  Message
                                </UncontrolledTooltip>
                              </Link>
                            </div>
                            <div className="flex-fill">
                              <Link to="#" id={"project" + user.id}>
                                <i className="bx bx-pie-chart-alt" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"project" + user.id}
                                >
                                  Projects
                                </UncontrolledTooltip>
                              </Link>
                            </div>*/}
                            
                           
                            <div className="flex-fill">
                              
                                <a target="_blank" href={"/user-profile/"+user._id}><i className="bx bx-user-circle" /></a>
                                
                            </div>
                          </div>
                        </CardFooter>
                      </Card>
                    </Col>
                  </React.Fragment>
                    )
                  })}
                

                
              </Row>
              </React.Fragment>
              ) : (
                null
              )}

            {(projectDetail.special || projectDetail.newV) && fase == 1 ? (
                <Row>
                <Col style={{position:'relative'}} lg={minim ? '11' : '8'}>
                  <Card>
                    <CardBody>
                      <h3>{projectDetail.titolo}</h3>

                      
                    </CardBody>
                    </Card>
                    
                    <Row style={{marginBottom:20}}>
                    
                    <button onClick={()=>{getPapataci2021(projectDetail,7)}} style={{marginRight:10,color:month == 7 ? 'red' : null}} class="btn ">Agosto</button> 
                    <button onClick={()=>{getPapataci2021(projectDetail,8)}} style={{marginRight:10,color:month == 8 ? 'red' : null}} class="btn ">Settembre</button> 
                    <button onClick={()=>{getPapataci2021(projectDetail,9)}} style={{marginRight:10,color:month == 9 ? 'red' : null}} class="btn ">Ottobre</button>
                    <button onClick={()=>{getPapataci2021(projectDetail,10)}} style={{marginRight:10,color:month == 10 ? 'red' : null}} class="btn ">Novembre</button> 
                    <button onClick={()=>{getPapataci2021(projectDetail,11)}} style={{marginRight:10,color:month == 11 ? 'red' : null}} class="btn ">Dicembre</button>
                    <button onClick={()=>{getPapataci2022(projectDetail,0)}} style={{marginRight:10,color:month == 0 ? 'red' : null}} class="btn ">Gennaio</button>
                    <button onClick={()=>{getPapataci2022(projectDetail,1)}} style={{marginRight:10,color:month == 1 ? 'red' : null}} class="btn ">Febbraio</button>
                    <button onClick={()=>{getPapataci2022(projectDetail,2)}} style={{marginRight:10,color:month == 2 ? 'red' : null}} class="btn ">Marzo</button>
                    <button onClick={()=>{getPapataci2022(projectDetail,3)}} style={{marginRight:10,color:month == 3 ? 'red' : null}} class="btn ">Aprile</button> 
                    <button onClick={()=>{getPapataci2022(projectDetail,4)}} style={{marginRight:10,color:month == 4 ? 'red' : null}} class="btn ">Maggio</button> 
                    <button onClick={()=>{getPapataci2022(projectDetail,5)}} style={{marginRight:10,color:month == 5 ? 'red' : null}} class="btn ">Giugno</button> 
                    <button onClick={()=>{getPapataci2022(projectDetail,6)}} style={{marginRight:10,color:month == 6 ? 'red' : null}} class="btn ">Luglio</button> 

                  </Row>

                  {projectDetail.newV ? (
                     <TabellaTurni titolo={projectDetail.titolo} month={month} lavoroID={projectDetail._id} columns={colonne} data={dataTabella} actionA={(e,cell)=>actionNovoTurnoUpdate(e,cell)} functionName={(x,info)=>console.log()} />

                  ) : (
                    <TabellaTurni duplicamento={()=>duplicaturno()} month={month} lavoroID={projectDetail._id} columns={colonne} data={dataTabella} actionA={(e,cell)=>actionC(e,cell)} functionName={(x,info)=>pepaleRead(x,info)}  carica={carica} />
                  ) }
                  
                
                  <div style={{padding:10}}>
            {/*{
        turnoString: this.state.startTurnoH+':'+this.state.startTurnoM+'-'+this.state.endTurnoH+':'+this.state.endTurnoM,
        meseAnno: this.state.selectMese+''+new Date().getFullYear(),
        lavoro:this.props.match.params.id
      } */}
            <div className="form-group row">
              <label
                htmlFor="example-time-input"
                className="col-md-2 col-form-label"
              >
                Start
              </label>
              <div className="col-md-10">
                <input
                  onChange={(e)=>setStart(e.target.value)}
                  className="form-control"
                  type="time"
                  defaultValue="13:45:00"
                  id="example-time-input"
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-time-input"
                className="col-md-2 col-form-label"
              >
                End
              </label>
              <div className="col-md-10">
                <input
                  onChange={(e)=>setEnd(e.target.value)}
                  className="form-control"
                  type="time"
                  defaultValue="13:45:00"
                  id="example-time-input"
                />
              </div>
            </div>
            <button style={{border:'none',padding:20}} disabled={!start || !end} onClick={()=>creaTurno(projectDetail.newV ? true : null)}>Crea turno</button>
          </div>
                 </Col>

                <Col style={{position:'relative',zIndex:9}} lg={minim ? '1' : '4'} >
                  <p style={{fontSize:20,fontWeight:'900',cursor:'pointer'}} onClick={()=>{setminim(!minim)}}>{minim ? '<' : '>'}</p>
                  {minim ? <p>Staff</p> : <Personale gestore={gestore} id={projectDetail._id} team={projectDetail.selected} /> }
                </Col>
              </Row>
              ) : (
                null
              )}

            
              {(!projectDetail.special && !projectDetail.newV) && fase == 1 ? (
                <React.Fragment>
                <Row>
                 <Col lg="4">
                     <div className="form-group mb-4">
                         <label>Giorni di lavoro</label>
                         <div className="input-group">
                           <Flatpickr
                             className="form-control d-block"
                             placeholder="dd M,yyyy"
                             options={{
                               inline: false,
                               altInput: true,
                               altFormat: "m/d/Y",
                               mode: "multiple",
                               dateFormat: "m/d/Y",
                               //minDate:new Date(),
                               defaultDate: giorniArrayString,
                               onChange: function(selectedDates, dateStr, instance) {
                                 console.log('mostra tutto');
                                 //console.log(selectedDates);
                                 let arrDate = dateStr.split(",");
                                  console.log(arrDate);
                                  let arrSort = arrDate.sort(function(a,b){
                                    
                                    return new Date(a) - new Date(b);
                                  });
                                 setGiorniArrayString(arrSort);
                             },
                             }}
                           />
                         </div>
                         <br></br>
                         <button onClick={()=>cambioDay()} class="waves-effect btn btn-outline-secondary">Salva</button> 
                       </div>
                   </Col>
                   </Row> 
                 <Row>
                   <Col lg="12">
                     {loadingTur ? (
                       <p>.....</p>
                     ) : (
                      <GiorniTurni actionCreate={()=>_getTurnoApi(params.id,(results)=>{
                        setTurni(results)
                      })} giorni={projectDetail.giorni} id={projectDetail._id} turni={turni} />
                     )}
                     
                   </Col>
                   
                   
                 </Row>
                
                 
                 
                 <PrTable banned={projectDetail.banned} arrayUser={projectDetail.staff1} arrayTurni={turni} id={params.id} giorni={projectDetail.giorni} options={options} series={series} modificati={modificati} />
                 
                 <Col lg="12">
                   <Card>
                      <CardBody>
                          <CardTitle>Storico invio (beta)</CardTitle>
                        <div style={{display:'flex',marginTop:30}}>
                            {storico.map((n,i)=>{
                              return(
                                <div style={{border:' 1px solid #eee',padding:5,marginRight:10}} key={'notis'+i}>
                                    <p style={{textAlign:'center',background:'#000',padding:10,borderRadius:10,color:'white'}}>{dateToString(n.createdAt)}</p>
                                    <button style={{width:'100%',borderRadius:10}} class="waves-effect waves-light btn btn-primary" onClick={()=>generateMailo(n.staff)}>Genera email list</button>
                                    <div style={{width: 237,padding: 10}}>
                                    
                                    <textarea
                                          style={{width:'100%'}}
                                        ref={textAreaRef}
                                        value={listaEmail}
                                      />
                                      <a onClick={()=>copyCli()}>copia</a>
                                      <a  target='_black' href={"mailto:"+listaEmail+""}>Manda</a>
                                      
                                    </div>
                                  <div style={{maxHeight: 200,overflowY: 'scroll', padding:5}}>
                                    {n.staff.map((user,k)=>{
                                      return(
                                        <div key={'userx'+k}>
                                          <p style={{fontWeight:'bold',marginTop:10}}>{user.nome} {user.cognome} {candidato(user._id) ? <i style={{color:'orange',fontSize: 24}} class="bx bx-comment-check"></i> : null}  {apertoNotifica(user._id,n.letto) ? <i style={{color:'green',fontSize: 24}} class="bx bx-comment-check"></i> : null}</p>
                                          {/*user.cell  ? (
                                              <a target='_blank' href={'https://wa.me/'+numberFormater(user.cell)}><p className="text-muted">{user.cell ?  user.cell  : null}</p></a>
                                          ) : null*/}
                                         
                                        </div> 
                                      )
                                    })}
                                  </div>
                                      <a onClick={()=>makeTextFile(n.staff)}>Crea Sms Dw</a>
                                      
                                  <div>
                                  {downloadLink ? (<a
                                        // this attribute sets the filename
                                        download='list.txt'
                                        // link to the download URL
                                        href={downloadLink}
                                      >
                                        sms
                                      </a>) : null}
                                      </div>
                                </div>
                              )
                            })}
                        </div>  
                      </CardBody>
                    </Card>
                   </Col>
             </React.Fragment>
              ) : (
                null
              )}
              
              
              {(projectDetail.special || projectDetail.newV) ? (
                null
              ) : (
                null
              )}

            </>
          )}
        </Container>
      </div>
      {isOpen ? (
        <div style={{position:'fixed',top:0,left:0,right:0,bottom:0,backgroundColor:'white',zIndex:3000000}}>
        <i onClick={()=>setOpen(false)} style={{fontSize:30}} className="mdi mdi-close-thick"></i>
        <div>
          <Row style={{height:'90vh'}}>
            <InvitoNot idLavoro={params.id} close={()=>setOpen(false)} />
          </Row>
        </div> 
      </div>
      ) : null}
      
    </React.Fragment>
  )
}

ProjectsOverview.propTypes = {
  projectDetail: PropTypes.any,
  match: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
}

const mapStateToProps = ({ projects }) => ({
  projectDetail: projects.projectDetail,
})

const mapDispatchToProps = dispatch => ({
  onGetProjectDetail: id => dispatch(getProjectDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsOverview))
