import React, { useEffect,useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  CardTitle
} from "reactstrap"
import algoliasearch from 'algoliasearch/lite';

import classnames from "classnames"
import Select from "react-select"
import AlgoliaLogo from "./algoliaLogo.png";
import { provincie ,capellis,occhis ,numberFormater,dateCalculate,calculate_age, linguex } from "../../helpers/formatting";
import { CompetenzeListaFunct ,_getCategorieInterne ,_createNotifica,_getLavoriC } from "../../helpers/server_helper";

//import images
import small from "../../assets/images/small/img-2.jpg"
import small2 from "../../assets/images/small/img-6.jpg"
import { Button } from "bootstrap";

import Rheostat from 'rheostat';

import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet";
import aphroditeInterface from 'react-with-styles-interface-aphrodite';

ThemedStyleSheet.registerInterface(aphroditeInterface);

const core = {
  black: "black",
  white: "#fcfcfc",
  grey: "#d8d8d8",
  teal: "#abc4e8",
  lightgrey: "#dbdbdb"
};

const DefaultTheme = {
  rheostat: {
    unit: 8,

    responsive: {
      mediumAndAbove: "@media (min-width: 744px)",
      largeAndAbove: "@media (min-width: 1128px)"
    },

    constants: {
      DEFAULT_HANDLE_WIDTH: 1.5,
      BACKGROUND_HEIGHT: 0.25
    },

    color: {
      ...core,
      progressBar: core.teal,
      focus: core.teal,
      textDisabled: core.lightgrey,

      buttons: {
        defaultDisabledColor: core.lightgrey
      }
    }
  }
};

ThemedStyleSheet.registerTheme(DefaultTheme);

const client = algoliasearch(
  '3VN9LAE1IH',
  '4adcefd5da1ae8ae047903a30e3fcf48'
);

const index = client.initIndex('STAFF');

const InvitoList = ({idLavoro,close}) => {
  const [listacateg, setListacateg] =  useState([])
  const [listacomp, setListacomp] =  useState([])
  const [activeTab, toggleTab] = useState("1")
  const [userList, setUserList] = useState([])
  const [sesso,setSesso] = useState(null)
  const [altezza,setAltezza] = useState([150,220])
  const [etaValue,setEtaValue] = useState([dateCalculate(new Date(),-18).getFullYear(),dateCalculate(new Date(),-70).getFullYear()])
  const [annoNascita,setAnnoNascita] = useState(null)
  const [approvato,setApprovato] = useState(null)
  const [capelli,setCapelli] = useState(null)
  const [occhi,setOcchi] = useState(null)
  const [categoriaInterna,setCategoriaInterna] = useState(null)
  const [competenze,setCompetenze] = useState(null)
  const [lingue,setLingue] = useState(null)
  const [piercing,setPiercing] = useState(null)
  const [tatoo,setTatoo] = useState(null)
  const [province_competenza,setProvince_competenza] = useState(null)
  const [taglia,setTaglia] = useState([36,55])
  const [scarpe,setScarpe] = useState(null)
  const [isLaureato,setIsLaureato] = useState(null)

  const [numPerP,setNumPerp] = useState(30)


  const [openSend,setOpenSend] = useState(false)
  const [infoPage,setInfopage] = useState(null)
  const [selezionati,setSelezionati] = useState([])
  const [testo,setTesto] = useState('Ciao abbiamo un nuovo lavoro che potrebbe interressarti!')
  const [downloadLink, setDownloadLink] = useState('')



  const [lavoriTouse,setLavoriTouse] = useState([])
  const [seletedLavoro,setSeletedLavoro] = useState(null)



  const generateMailo = (staff) => {
    // This creates the file. 
    // In my case, I have an array, and each item in 
    // the array should be on a new line, which is why
    // I use .join('\n') here.
    //console.log(staff);
    //console.log(staff.join('\n'));
    let lista = ''
    staff.forEach(element => {
      //console.log(element.email);
      if(element.email){
        lista = lista + element.email +';'
      }
       
    });
    console.log(lista);
    //setListaEmail(lista)
    
    
  }


  const makeTextFile = (staff) => {
    // This creates the file. 
    // In my case, I have an array, and each item in 
    // the array should be on a new line, which is why
    // I use .join('\n') here.
    //console.log(staff);
    //console.log(staff.join('\n'));
    console.log(staff);
    let newDateo = ''
    staff.forEach(element => {
      console.log('mostro staff');
      console.log(element);
      console.log();
      let line = element.nome2+';'+element.cognome+';'+numberFormater(element.cell,true)+';si\n'
       //let line = formatAdresse(element.residenza)+';'+element.nome+' '+element.cognome+';'+numberFormater(element.cell,true)+';si\n'

      newDateo= newDateo+line
    });
    console.log(newDateo);

    const data = new Blob([newDateo], { type: 'text/plain' })

    // this part avoids memory leaks
    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink)

    // update the download link state
    //console.log(window.URL.createObjectURL(data));
    setDownloadLink(window.URL.createObjectURL(data))
  }



  const arrayUsersss = () =>{
    let arrayUser = []
    selezionati.forEach(element => {
      arrayUser.push(
        {
          "$oid": element.id
      })
    });
    console.log(JSON.stringify(arrayUser));
  }


  const sendNotificher = () =>{
      
    let arrayUser = []
      
      selezionati.forEach(element => {
        arrayUser.push(element.id)
      });
      console.log(arrayUser);

      /*
      _createNotifica({
        type: 'info',
        desc:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        titolo:'yoooo',
        staff:['5f0c3b5247bc99523321b4df','60473612c1b5717d77d218fb'],
      },(resultat)=>{
        console.log('qui result');
        console.log(resultat);
      })*/

    _createNotifica({
      type: 'workAsk',
      desc:testo,
      idJob:idLavoro,
      titolo:'Nuovo lavoro',
      staff:arrayUser,
    },(resultat)=>{
      alert('inviato')
      console.log(resultat);
    })
  }

  const selectTutti = (deseleziona) =>{
    let selezionatiN = [...selezionati]
    if(deseleziona){
      userList.forEach(element => {
        let indexTorem = selezionatiN.findIndex(x => x.id === element.objectID);
        if (indexTorem > -1 ) {
          selezionatiN.splice(indexTorem, 1);
          setSelezionati(selezionatiN)
        }else{
          
        }
  
      });
    }else{
      userList.forEach(element => {
        let indexTorem = selezionatiN.findIndex(x => x.id === element.objectID);
        if (indexTorem > -1 ) {
          
        }else{
          console.log('vedimaop');
          console.log(element);
          let toAdd = {
            id:element.objectID,
            nome:element.nome+' '+element.cognome,
            nome2:element.nome,
            cognome:element.cognome,
            img:element.photo,
            cell:element.cell,
            email:element.email
          }
          selezionatiN.push(toAdd)
          setSelezionati(selezionatiN)
        }
  
      });
    }
    
   
    
    
    
  }


  const selectTuttiOldWork = (deseleziona) =>{
    let selezionatiN = [...selezionati]
    
    if(deseleziona){
      seletedLavoro && lavoriTouse[0].data[seletedLavoro-1].staff && lavoriTouse[0].data[seletedLavoro-1].staff.map((element,i)=>{
      //userList.forEach(element => {
        let indexTorem = selezionatiN.findIndex(x => x.id === element._id);
        if (indexTorem > -1 ) {
          selezionatiN.splice(indexTorem, 1);
          setSelezionati(selezionatiN)
        }else{
          
        }
  
      });
    }else{
      seletedLavoro && lavoriTouse[0].data[seletedLavoro-1].staff && lavoriTouse[0].data[seletedLavoro-1].staff.map((element,i)=>{
      //userList.forEach(element => {
        let indexTorem = selezionatiN.findIndex(x => x.id === element._id);
        if (indexTorem > -1 ) {
          
        }else{
          console.log('vedimaop');
          console.log(element);
          let toAdd = {
            id:element._id,
            nome:element.nome+' '+element.cognome,
            nome2:element.nome,
            cognome:element.cognome,
            img:element.img_profilo,
            cell:element.cell,
            email:element.email
          }
          selezionatiN.push(toAdd)
          setSelezionati(selezionatiN)
        }
  
      });
    }
   }

  const isSelect = (indexId) => {
    let selezionatiOld = [...selezionati]
    let indexTorem = selezionatiOld.findIndex(x => x.id === indexId);
    if (indexTorem > -1) {
      return true
    }else{
      return false
    }
  }
  const remove = (indexId) =>{
    
    let selezionatiOld = [...selezionati]
    let indexTorem = selezionatiOld.findIndex(x => x.id === indexId);
    if (indexTorem > -1) {
      selezionatiOld.splice(indexTorem, 1);
      setSelezionati(selezionatiOld)
    }
  }

  const addSelezione = ( user) =>{
    let selezionatiN = [...selezionati]
    
    let indexTorem = selezionatiN.findIndex(x => x.id === user.objectID);
    if (indexTorem > -1) {
      selezionatiN.splice(indexTorem, 1);
      setSelezionati(selezionatiN)
    }else{
      let toAdd = {
        id:user.objectID,
        nome:user.nome+' '+user.cognome,
        nome2:user.nome,
        cognome:user.cognome,
        img:user.photo,
        cell:user.cell,
        email:user.email
      }
      selezionatiN.push(toAdd)
      setSelezionati(selezionatiN)
    }
    
    

  }


  const addSelezioneFromWorks = (user) =>{
    let selezionatiN = [...selezionati]
    
    let indexTorem = selezionatiN.findIndex(x => x.id === user._id);
    if (indexTorem > -1) {
      selezionatiN.splice(indexTorem, 1);
      setSelezionati(selezionatiN)
    }else{
      let toAdd = {
        id:user._id,
        nome:user.nome+' '+user.cognome,
        nome2:user.nome,
        cognome:user.cognome,
        img:user.img_profilo,
        cell:user.cell,
        email:user.email
      }
      selezionatiN.push(toAdd)
      setSelezionati(selezionatiN)
    }
    
    

  }




  const getPagination = infopage => {
    let content = [];
   
    if(infopage && infopage.pagine > 0){
      for (let i = 0; i < infopage.pagine; i++) {
      
        content.push(<button onClick={()=>genetaricerca(i)} style={{border:'none',fontSize:20,width:40,height:40, backgroundColor:i == infopage.paginaC ? 'black' : null,color:i == infopage.paginaC ? 'white' : "black"}}>{i + 1}</button>);
      }
    }
    
    return content;
  };



  const genetaricerca = (paginone) =>{

   // const filters = 'available = 1 AND (category:Book OR NOT category:Ebook) AND _tags:published AND publication_date:1441745506 TO 1441755506 AND inStock > 0 AND author:"John Doe"';
    

   
   let provincieF = ''
   let occhiF = ''
   let capelliF = ''
   let competenzeF = ''
   let categIntF = ''
   let lingIntF = ''



   if(lingue){
       
    lingue.forEach((element,index) => {
      let testo =  `lingue.code:${element.value}`
      if(index > 0){
        lingIntF =  `${lingIntF} OR ${testo}`
      }else{
        lingIntF =  `${lingIntF} ${testo}`
      }
      
    });

    lingIntF = `(${lingIntF})`
    
    }else{
      lingIntF = null
  }

   if(categoriaInterna){
       
    categoriaInterna.forEach((element,index) => {
      let testo =  `categoriaInterna:${element.value}`
      if(index > 0){
        categIntF =  `${categIntF} OR ${testo}`
      }else{
        categIntF =  `${categIntF} ${testo}`
      }
      
    });

    categIntF = `(${categIntF})`
    
 }else{
  categIntF = null
 }

   if(province_competenza){
       
      province_competenza.forEach((element,index) => {
        let testo =  `province_competenza:${element.value.toLowerCase()}`
        if(index > 0){
          provincieF =  `${provincieF} OR ${testo}`
        }else{
          provincieF =  `${provincieF} ${testo}`
        }
        
      });

      provincieF = `(${provincieF})`
      console.log(provincieF);
   }else{
    provincieF = null
   }


   if(occhi){
       
    occhi.forEach((element,index) => {
      let testo =  `occhi:${element.value}`
      if(index > 0){
        occhiF =  `${occhiF} OR ${testo}`
      }else{
        occhiF =  `${occhiF} ${testo}`
      }
      
    });

    occhiF = `(${occhiF})`
    
 }else{
  occhiF = null
 }


 if(capelli){
       
  capelli.forEach((element,index) => {
        let testo =  `capelli:${element.value}`
        if(index > 0){
          capelliF =  `${capelliF} OR ${testo}`
        }else{
          capelliF =  `${capelliF} ${testo}`
        }
        
      });

      capelliF = `(${capelliF})`
      
    }else{
      capelliF = null
    }


    if(competenze){
       
      competenze.forEach((element,index) => {
            let testo =  `competenze:${element.value}`
            if(index > 0){
              competenzeF =  `${competenzeF} OR ${testo}`
            }else{
              competenzeF =  `${competenzeF} ${testo}`
            }
            
          });
    
          competenzeF = `(${competenzeF})`
          
        }else{
          competenzeF = null
        }



   let teststts = []

    let sessoF =
      sesso  ? `(sesso:${sesso})` : null;

      
    let approvatoF ;
    if(approvato){
      if(approvato == 'SI'){
        approvatoF = `(approvato:${approvato  == 'SI' ? true : false })`;
      }else{
        approvatoF = `(approvato:false OR NOT approvato:true )`
      }
      
    
    }else{
      approvatoF = `(approvato:true)`
    }
    

    //laurea
    let isLaureatoF;
    if(isLaureato){
      if(isLaureato == 'SI'){
        isLaureatoF = `(isLaureato:${isLaureato  == 'SI' ? true : false })`;
      }else{
        isLaureatoF = `(isLaureato:false OR NOT isLaureato:true )`
      }
      
    
    }else{
      isLaureatoF = null
    }

    let tattoF =
      tatoo  ? `(tatoo:${tatoo == 'SI' ? true : false})` : null;  
    let piercingF =
      piercing  ? `(piercing:${piercing == 'SI' ? true : false})` : null;    


    if(sessoF){
      teststts.push(sessoF)
    }

    if(approvatoF){
      teststts.push(approvatoF)
    }
    if(isLaureatoF){
      teststts.push(isLaureatoF)
    }
    if(tattoF){
      teststts.push(tattoF)
    }
    if(piercingF){
      teststts.push(piercingF)
    }

    if(provincieF){
      teststts.push(provincieF)
    }
    if(occhiF){
      teststts.push(occhiF)
    }
    if(capelliF){
      teststts.push(capelliF)
    }
    if(competenzeF){
      teststts.push(competenzeF)
    }
    if(categIntF){
      teststts.push(categIntF)
    }
    if(lingIntF){
      teststts.push(lingIntF)
    }
    

    teststts.push(`(altezza>=${altezza[0]} AND altezza<${altezza[1]} )`)
    teststts.push(`(taglia>=${taglia[0]} AND taglia<${taglia[1]} )`)
    teststts.push(`(annoNascita>=${etaValue[1]} AND annoNascita<=${etaValue[0]})`)
    //console.log(province_competenza);


    
    
    console.log('mostreo test');
    console.log(teststts);

    let filterFrase
    teststts.forEach((element,index) => {
      if(index > 0){
        filterFrase = `${filterFrase} AND ${element}`
      }else{
        filterFrase = `${element}`
      }
    });


    console.log('mostro frade');

    if(!filterFrase){
      filterFrase = ''
    }
    console.log(filterFrase);


    
    index.search('', {
      filters: `${filterFrase}`,
      hitsPerPage: numPerP,
      page: paginone ? paginone : 0,
    }).then(res => {
      console.log(res);

      setInfopage({numero:res.nbHits,pagine:res.nbPages,paginaC:res.page})
      setUserList(res.hits)
  
    });
    
    
  }

  const testoCerca =  (value) =>{
    
    
    //const filters = 'available = 1 AND (category:Book OR NOT category:Ebook) AND _tags:published AND publication_date:1441745506 TO 1441755506 AND inStock > 0 AND author:"John Doe"';
    //'(approvato:false OR NOT approvato:true) AND (sesso:F)'

    if(value.target.value.length > 4){
      index.search(value.target.value, {
        /*numericFilters: [
          [
            'nuovoLingue.it > 8'
          ]
        ],*/
        filters: '',
        hitsPerPage: 20,
      }).then(res => {
        //dentro res ci sono info page si usa pafamentro page x paginazione
        //console.log(res);
        setUserList(res.hits)
    
      });
    }
  }

  const handleSelectGroup = (province_competenza) => {
    console.log('selectedGroup');
    console.log(province_competenza);
    setProvince_competenza(province_competenza)
  }

  const handleSelectGroupcapelli = (capelli) => {
    
    setCapelli(capelli)
  }
  const handleSelectGroupocchi = (occhi) => {
    
    setOcchi(occhi)
  }

  const handleSelectGroupocompet = (competenze) => {
    
    setCompetenze(competenze)
  }

  const handleSelectGroplingue = (l) => {
    
    setLingue(l)
  }


  const handleSelectGroupocatint = (categoriaInterna) => {
    
    setCategoriaInterna(categoriaInterna)
  }


  
  const ricercaLavoro = (page,titolo)=>{
    _getLavoriC(0,titolo,(lavori)=>{
      console.log('vediamo che fà');
      console.log(lavori);
      setLavoriTouse(lavori)
      setSeletedLavoro(null)
      
    })
  }


  useEffect(() => {

    CompetenzeListaFunct((comp)=>{
      
      setListacomp(comp)
    })

    _getCategorieInterne((categint)=>{
      
      setListacateg(categint)
    })

   /* index.search('', {
      //filters: '(approvato:false)',
      hitsPerPage: 50,
    }).then(res => {

      setUserList(res.hits)
  
      // console.log(res);
    });*/
  }, [])


  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }
  return (
    <React.Fragment>
      <Col style={{height:'100%',overflow:'scroll'}} xl={3} lg={3}>
      {activeTab == 1 ? (
        <Card>
        <CardBody className="p-4">
          <div className="search-box">
            <img src={AlgoliaLogo}></img>
            
            <p className="text-muted"></p>
            <div className="position-relative">
              <input
                type="text"
                className="form-control rounded bg-light border-light"
                placeholder="Search..."
                onChange={(value)=>testoCerca(value)}
              />
              <i className="mdi mdi-magnify search-icon"></i>
            </div>
          </div>

          <hr className="my-4" />

          <div>
            

            
            <ul className="list-unstyled font-weight-medium">
  
            

              <li>
                <a href="#" className="text-muted py-2 d-block">
                  <i className="mdi mdi-chevron-right mr-1"></i> Sesso
                </a>
              </li>
             
              

              <div style={{display:'flex'}}>
                <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckcolor3"
                  checked={sesso == 'F'}
                  onChange={() => {
                    if(sesso != 'F'){
                      setSesso('F')
                    }else{
                      setSesso(null)
                    }
                    
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckcolor3"
                >
                  Donna
                </label>
                </div>
                <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckcolor3w"
                  checked={sesso == 'M'}
                  onChange={() => {
                    if(sesso == 'M'){
                      setSesso(null)
                    }else{
                      setSesso('M')
                    }
                    
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckcolor3w"
                >
                  Uomo 
                </label>
              </div>
              </div>

              <hr className="my-4" />


              <li>
                <a href="#" className="text-muted py-2 d-block">
                  <i className="mdi mdi-chevron-right mr-1"></i> Età
                </a>
              </li>
              <br></br>
              <div style={{ }}>
                <Rheostat
                  style={{}}
                  //onChange={(x)=>setEtaValue((x.values))}
                  onValuesUpdated={(x)=>setEtaValue((x.values))}
                  min={dateCalculate(new Date(),-18).getFullYear()}
                  max={dateCalculate(new Date(),-70).getFullYear()}
                  values={etaValue}
                />
                <div style={{display:'flex',marginTop:20}}>
                  <p style={{width:'50%'}}>{calculate_age(etaValue[0])} anni</p>
                  <p style={{width:'50%',textAlign:'right'}}>{calculate_age(etaValue[1])} anni</p>
                </div>
                
              </div>
              
              <hr className="my-4" />

              <li>
                <a href="#" className="text-muted py-2 d-block">
                  <i className="mdi mdi-chevron-right mr-1"></i> Altezza
                </a>
              </li>
              <br></br>
              <div style={{ }}>
                <Rheostat
                  style={{}}
                  //onChange={(x)=>setAltezza(x.values)}
                  onValuesUpdated={(x)=>setAltezza(x.values)}
                  min={150}
                  max={220}
                  values={altezza}
                />
                <div style={{display:'flex',marginTop:20}}>
                  <p style={{width:'50%'}}>{altezza[0]} cm</p>
                  <p style={{width:'50%',textAlign:'right'}}>{altezza[1]} cm</p>
                </div>
                
              </div>
              
              <hr className="my-4" />

              <li>
                  <a href="#" className="text-muted py-2 d-block">
                    <i className="mdi mdi-chevron-right mr-1"></i> Taglia
                  </a>
                </li>
                <br></br>
                <div style={{ }}>
                  <Rheostat
                    style={{}}
                    //onChange={(x)=>setAltezza(x.values)}
                    onValuesUpdated={(x)=>setTaglia(x.values)}
                    min={36}
                    max={55}
                    values={taglia}
                  />
                  <div style={{display:'flex',marginTop:20}}>
                    <p style={{width:'50%'}}>{taglia[0]} cm</p>
                    <p style={{width:'50%',textAlign:'right'}}>{taglia[1]} cm</p>
                  </div>
                  
                </div>

              <li>
                <a href="#" className="text-muted py-2 d-block">
                   Laureato
                </a>
              </li>


              <div style={{display:'flex'}}>
                <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckcolor3saap3l"
                  checked={isLaureato == 'SI'}
                  onChange={() => {
                    if(isLaureato != 'SI'){
                      setIsLaureato('SI')
                    }else{
                      setIsLaureato(null)
                    }
                    
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckcolor3saap3l"
                >
                  Si
                </label>
                </div>
                <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckcolor3wsappl"
                  checked={isLaureato == 'NO'}
                  onChange={() => {
                    if(isLaureato == 'NO'){
                      setIsLaureato(null)
                    }else{
                      setIsLaureato('NO')
                    }
                    
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckcolor3wsappl"
                >
                  No 
                </label>
              </div>
              </div>
              <hr className="my-4" />
          <div>
            <p className="text-muted mb-2">Tatuaggi</p>
            <div style={{display:'flex'}}>
                <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckcolor3s"
                  checked={tatoo == 'SI'}
                  onChange={() => {
                    if(tatoo != 'SI'){
                      setTatoo('SI')
                    }else{
                      setTatoo(null)
                    }
                    
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckcolor3s"
                >
                  Si
                </label>
                </div>
                <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckcolor3ws"
                  checked={tatoo == 'NO'}
                  onChange={() => {
                    if(tatoo == 'NO'){
                      setTatoo(null)
                    }else{
                      setTatoo('NO')
                    }
                    
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckcolor3ws"
                >
                  No 
                </label>
              </div>
              </div>
            
          </div>
          

          <hr className="my-4" />


          <div>
            <p className="text-muted mb-2">Piercing</p>
            
            <div style={{display:'flex'}}>
                <div style={{marginRight:20}} className="custom-control custom-checkbox custom-checkbox-info mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckcolor3sw"
                  checked={piercing == 'SI'}
                  onChange={() => {
                    if(piercing != 'SI'){
                      setPiercing('SI')
                    }else{
                      setPiercing(null)
                    }
                    
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckcolor3sw"
                >
                  Si
                </label>
                </div>
                <div className="custom-control custom-checkbox custom-checkbox-info mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckcolor3wse"
                  checked={piercing == 'NO'}
                  onChange={() => {
                    if(piercing == 'NO'){
                      setPiercing(null)
                    }else{
                      setPiercing('NO')
                    }
                    
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckcolor3wse"
                >
                  No 
                </label>
              </div>
              </div>
            

              <hr className="my-4" />

          </div>

              
              <li>
                <a href="#" className="text-muted py-2 d-block">
                  <i className="mdi mdi-chevron-right mr-1"></i> Provincie Competenza{" "}
                  
                </a>
                <Select
                    value={province_competenza}
                    isMulti
                    onChange={handleSelectGroup}
                    options={provincie}
                    classNamePrefix="select2-selection"
                  />
              </li>

              <li>
                <a href="#" className="text-muted py-2 d-block">
                  <i className="mdi mdi-chevron-right mr-1"></i> Capelli
                </a>
                <Select
                    value={capelli}
                    isMulti
                    onChange={handleSelectGroupcapelli}
                    options={capellis}
                    classNamePrefix="select2-selection"
                  />
              </li>
              <li>
                <a href="#" className="text-muted py-2 d-block">
                  <i className="mdi mdi-chevron-right mr-1"></i> Occhi
                </a>
                <Select
                    value={occhi}
                    isMulti
                    onChange={handleSelectGroupocchi}
                    options={occhis}
                    classNamePrefix="select2-selection"
                  />
              </li>
              
            </ul>
          </div>

          <hr className="my-4" />

          <div>
            <p className="text-muted">Lingue</p>

            <Select
              value={lingue}
              isMulti
              onChange={handleSelectGroplingue}
              options={linguex}
              menuContainerStyle={{'zIndex': 999}}
              classNamePrefix="select2-selection"
            />
          </div>

          <hr className="my-4" />

          <div>
            <p className="text-muted">Competenze</p>

            <Select
              value={competenze}
              isMulti
              onChange={handleSelectGroupocompet}
              options={listacomp}
              menuContainerStyle={{'zIndex': 999}}
              classNamePrefix="select2-selection"
            />
          </div>

          <hr className="my-4" />

          <div>
            <p className="text-muted">Categorie Interne</p>

            <Select
              styles={{position: 'relative'}}
              value={categoriaInterna}
              isMulti
              onChange={handleSelectGroupocatint}
              options={listacateg}
              menuContainerStyle={{'zIndex': 999}}
              classNamePrefix="select2-selection"
            />
          </div>

          
          <br></br>
          
         
        </CardBody>
        <button style={{marginTop:20}} onClick={()=>genetaricerca()} class="btn btn-secondary waves-effect btn btn-secondary">Cerca</button>
      </Card>
      ) : null}
        
        {activeTab == 2 ? (
          <Card>
          <CardBody className="p-4">
            <div className="search-box">
             
              
              <p className="text-muted">Ricerca Per Nome Lavoro</p>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control rounded bg-light border-light"
                  placeholder="Search..."
                  onChange={(value)=>ricercaLavoro(0,value.target.value)}
                />
                <i className="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
            { lavoriTouse.length > 0 && lavoriTouse[0].data.map((lav,i)=>{
              return(
                <div onClick={()=>setSeletedLavoro(i+1)}  style={{background:seletedLavoro && seletedLavoro == i+1 ? "#f4433636" : '#eee',padding:10,marginTop:10}} key={'lavorinooo'+lav._id}>
                  <div>
                    <p><strong>{lav.titolo}</strong></p>
                    <p style={{fontSize:10}}>{lav.descrizione}</p>
                  </div>
                </div>
              )
            })}
            
 
           
          </CardBody>
          {/*<button style={{marginTop:20}} onClick={()=>genetaricerca()} class="btn btn-secondary waves-effect btn btn-secondary">Cerca</button>*/}
        </Card>
        ) : null}

      </Col>


      <Col style={{height:'100%',overflow:'scroll'}} xl={7} lg={5}>
        {!openSend ? (
          <Card>
          <div style={{background:'white',position: 'sticky',top:0,zIndex:4,padding:5}}> 


          {activeTab == 1 ? (
                      <CardTitle>Persone trovate : {infoPage && infoPage.numero ? infoPage.numero : 0 }</CardTitle>

          ) : null}

          {activeTab == 2 ? (
                      <CardTitle>Persone trovate : {seletedLavoro && lavoriTouse[0].data[seletedLavoro-1].staff ? lavoriTouse[0].data[seletedLavoro-1].staff.length : null}</CardTitle>

          ) : null}

          <Row style={{marginLeft:0}} >

          {activeTab == 1 ? (
              <React.Fragment>
                <p  style={{cursor:'pointer',margin:0,marginRight:10}} onClick={()=>{selectTutti()}} >seleziona tutti</p>
                <p   style={{cursor:'pointer',margin:0}} onClick={()=>{selectTutti(true)}}  style={{cursor:'pointer',margin:0, marginLeft:10,marginRight:10}}>deseleziona tutti</p>
            </React.Fragment> ) : null}


            {activeTab == 2 ? (
              <React.Fragment>
                <p  style={{cursor:'pointer',margin:0,marginRight:10}} onClick={()=>{selectTuttiOldWork()}} >seleziona tutti</p>
                <p  style={{cursor:'pointer',margin:0}} onClick={()=>{selectTuttiOldWork(true)}}  style={{cursor:'pointer',margin:0, marginLeft:10,marginRight:10}}>deseleziona tutti</p>
            </React.Fragment>
            ) : null }
            
            {activeTab == 1 ? (
              <React.Fragment>
                   <p style={{margin:0,marginRight:10}}>Numero Per pagina</p>
                    <select onChange={(e)=>setNumPerp(e.target.value)} >
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
              </React.Fragment>
            ) : null}
           
          </Row>
          </div>
          <ul
            className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
            role="tablist"
          >
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                Ricerca per parametri
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2");
                  ricercaLavoro(0,'')
                }}
              >
                Da vecchi lavori
              </NavLink>
            </NavItem>

          </ul>
          <Row>
           <React.Fragment>
            {activeTab == 1 ? (
              <React.Fragment>
                {userList.map((user,index)=>{
            return(
              <React.Fragment>
            <Col xl="3" sm="6">
              <Card style={{backgroundColor:isSelect(user.objectID) ? '#feef5f' : null}} className="text-center">
                <CardBody>
                  {!user.photo ? (
                    <div className="avatar-sm mx-auto mb-4">
                      <span
                        className={
                          "avatar-title rounded-circle bg-soft-" +
                          user.color +
                          " text-" +
                          user.color +
                          " font-size-16"
                        }
                      >
                        
                      </span>
                    </div>
                  ) : (
                    <div className="mb-4">
                      <img
                        style={{width:'100%',height:150,objectFit:'contain',backgroundColor:'#0c0c0c'}}
                        className="rounded mr-2"
                        src={user.photo}
                        alt=""
                      />
                    </div>
                  )}

                  <h5 style={{textTransform:'capitalize'}} className="font-size-15">
                    <a  target='_blank' href={'https://dashboardmcmstaff.vercel.app/user-profile/'+user.objectID} className="text-dark">
                      {user.nome} {user.cognome}
                    </a>
                  </h5>
                  {user.cell  ? (
                              <a target='_blank' href={'https://wa.me/'+numberFormater(user.cell)}><p className="text-muted">{user.cell ?  user.cell  : null}</p></a>
                          ) : null}
                          <a href= {"mailto:"+user.email}><p className="text-muted">{user.email ? user.email  : null}</p></a>
                  <Row style={{textAlign:'center'}}>
                    <Col>
                      <p className="text-muted">{user.altezza}cm</p>  

                    </Col>
                    <Col>
                    {user && user.data_di_nascita ? (
                      <p className="text-muted">Anno: {new Date(user.data_di_nascita).getFullYear()}</p>
                  ) : null}
                    </Col>
                  
                  </Row>   
                  {user && user.note ? (
                    <div style={{background:'#eee',padding:5,marginBottom:5}}>  
                     <p style={{textAlign: 'left',whiteSpace: 'break-spaces'}}>{user.note}</p> 
                    </div>
                    ) : null}
                         
                  <p className="text-muted">{user.approvato ? 'Approvato' : 'Non Approvato'}</p>
                  <p className="text-muted">{user.ExponentPushToken ? <i class="bx bx-notification"></i> : <i style={{color:'red'}} class="bx bx-notification-off"></i>}</p>

                  <div>
                    {/*user.skills.map((skill, key) => (
                      <Link
                        to="#"
                        className="badge badge-primary font-size-11 m-1"
                        key={"_skill_" + key}
                      >
                        {skill.name}
                      </Link>
                    ))*/}
                  </div>
                </CardBody>
                <CardFooter className="bg-transparent border-top">
                  <div className="contact-links d-flex font-size-20">
                    {/*<div className="flex-fill">
                      <Link to="#" id={"message" + user.id}>
                        <i className="bx bx-message-square-dots" />
                        <UncontrolledTooltip
                          placement="top"
                          target={"message" + user.id}
                        >
                          Message
                        </UncontrolledTooltip>
                      </Link>
                    </div>
                    <div className="flex-fill">
                      <Link to="#" id={"project" + user.id}>
                        <i className="bx bx-pie-chart-alt" />
                        <UncontrolledTooltip
                          placement="top"
                          target={"project" + user.id}
                        >
                          Projects
                        </UncontrolledTooltip>
                      </Link>
                    </div>*/}
                    <div className="flex-fill">
                      {/*<Link  to={"/user-profile/"+user.objectID} id={"profile" +user.objectID}>
                        <i className="bx bx-user-circle" />
                        <UncontrolledTooltip
                          placement="top"
                          target={"profile" + user.objectID}
                        >
                          Profile
                        </UncontrolledTooltip>
                      </Link>*/}
                      <button onClick={()=>{addSelezione(user)}} style={{border:'none'}}>Seleziona</button>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            
          </React.Fragment>
            )
          })}
              </React.Fragment>
            ) : null}


            {activeTab == 2 ? (
               
               seletedLavoro && lavoriTouse[0].data[seletedLavoro-1].staff && lavoriTouse[0].data[seletedLavoro-1].staff.map((user,i)=>{
                 return(
                  <React.Fragment>
                  <Col xl="3" sm="6">
                    <Card style={{backgroundColor:isSelect(user._id) ? '#feef5f' : null}} className="text-center">
                      <CardBody>
                        {!user.photo && !user.img_profilo  ? (
                          <div className="avatar-sm mx-auto mb-4">
                            <span
                              className={
                                "avatar-title rounded-circle bg-soft-" +
                                user.color +
                                " text-" +
                                user.color +
                                " font-size-16"
                              }
                            >
                              
                            </span>
                          </div>
                        ) : (
                          <div className="mb-4">
                            <img
                              style={{width:'100%',height:150,objectFit:'contain',backgroundColor:'#0c0c0c'}}
                              className="rounded mr-2"
                              src={user.photo ? user.photo : user.img_profilo}
                              alt=""
                            />
                          </div>
                        )}
      
                        <h5 style={{textTransform:'capitalize'}} className="font-size-15">
                          <a  target='_blank' href={'https://dashboardmcmstaff.vercel.app/user-profile/'+user.objectID ? user.objectID :user._id } className="text-dark">
                            {user.nome} {user.cognome}
                          </a>
                        </h5>
                       
                        {user.cell  ? (
                                    <a target='_blank' href={'https://wa.me/'+numberFormater(user.cell)}><p className="text-muted">{user.cell ?  user.cell  : null}</p></a>
                                ) : null}
                        <a href= {"mailto:"+user.email}><p className="text-muted">{user.email ? user.email  : null}</p></a>

                        <Row style={{textAlign:'center'}}>
                          <Col>
                           {/*<p className="text-muted">{user.altezza}cm</p>  */}
      
                          </Col>
                          <Col>
                          {/*user && user.data_di_nascita ? (
                            <p className="text-muted">Anno: {new Date(user.data_di_nascita).getFullYear()}</p>
                        ) : null */}
                          </Col>
                        
                        
                        </Row>  
                        
                        {user && user.note ? (
                        <div style={{background:'#eee',padding:5,marginBottom:5}}>  
                         <p style={{textAlign: 'left',whiteSpace: 'break-spaces'}}>{user.note}</p> 
                        </div>
                        ) : null}
    
                        
      
                        <div>
                          
                        </div>
                      </CardBody>
                      <CardFooter className="bg-transparent border-top">
                        <div className="contact-links d-flex font-size-20">
                          
                          <div className="flex-fill">
                            
                            <button onClick={()=>{addSelezioneFromWorks(user)}} style={{border:'none'}}>Seleziona</button>
                          </div>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                  
                
                </React.Fragment>
                 )
               }) 
              
             ) : null}

          </React.Fragment> 
          
          
          </Row>
          {activeTab == 1 ? (
            <Row>
            {getPagination(infoPage)}
          </Row>
          ) : null}
          
        </Card>

        ) : (
          <Card>
            <CardTitle>Manda un messaggio 
            </CardTitle>
            <CardTitle>Persone a cui manderai il messaggio : {selezionati.length }</CardTitle>
            <div style={{marginTop:50}}>
              <p>scrivi un messaggi da inviare allo staff</p>
              <div>
                <textarea style={{border:'1px solid #eee',padding:10,backgroundColor:'#eee', width:300}} value={testo} onChange={(e)=>setTesto(e.target.value)}>

                </textarea>
              </div>
              <Row style={{marginTop:40,marginBottom:40}}>
                <button style={{marginRight:10,backgroundColor:'black'}} class="btn btn-secondary waves-effect btn btn-secondary" onClick={()=>{setOpenSend(false)}}>Ritorna alla ricerca</button>
                <button style={{backgroundColor:'#556ee6'}} class="btn btn-secondary waves-effect btn btn-secondary" onClick={()=>{sendNotificher()}}>Invia</button>

              </Row>
            </div>
          </Card>
        )}
        
      </Col>
      <Col style={{height:'100%',overflow:'scroll'}} xl={2} lg={3}>
        <Card>
          <CardTitle>Staff selezionato {selezionati.length}</CardTitle>
          <div style={{marginTop:50,position:'relative'}}>
          {selezionati.map((u,i)=>{
            return(
              <div style={{position:'relative'}}>
                <Row>
                  <div className="avatar-sm mb-4">
                    <img
                      style={{width:50,height:50,objectFit:'contain',backgroundColor:'#0c0c0c'}}
                      className="rounded mr-2"
                      src={u.img}
                      alt=""
                    />
                  </div>
                  <div style={{padding:10,width:'70%'}}>
                    <p>{u.nome}</p>
                  </div>
                </Row>
                <button style={{position:'absolute',bottom:0,right:0,  border:'none',backgroundColor:'none'}} onClick={()=>remove(u.id)}><i style={{color:'red'}} className="mdi mdi-account-remove"></i></button>
              </div>
            )
          })}
          </div>
          {selezionati.length > 0 ? (
            <>
             <button style={{backgroundColor:'#f1b44c',border:'none'}} class="btn btn-secondary waves-effect btn btn-secondary" onClick={()=>setOpenSend(true)}>Crea messaggio</button>
             {/*
             <button style={{backgroundColor:'#f1b44c',border:'none'}} class="btn btn-secondary waves-effect btn btn-secondary" onClick={()=>generateMailo(selezionati)}>Genera email</button>
             <button style={{backgroundColor:'#f1b44c',border:'none'}} class="btn btn-secondary waves-effect btn btn-secondary" onClick={()=>makeTextFile(selezionati)}>Genera Sms</button>
             */}
             
             {downloadLink ? (<a
                                        // this attribute sets the filename
                                        download='list.txt'
                                        // link to the download URL
                                        href={downloadLink}
                                      >
                                        sms
                                      </a>) : null}
             </>
          ) : null}
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default InvitoList;