import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Badge,
  Card,
  CardBody,
  Col,
  Spinner,
  Media,
  UncontrolledTooltip,} from "reactstrap"
import { Link } from "react-router-dom"
import { indexOf, map } from "lodash"
import images from "assets/images"
import {_updateItemTurno} from "../../../helpers/server_helper"
import { timeToString ,numberFormater} from "../../../helpers/formatting";






const ItemTurno = ({cell, altro }) => {

  const [valueC ,setValueC] = useState({}) 
  const [loading ,setLoading] = useState(true) 


  useEffect(() => {
    setValueC(cell)
    setLoading(false)
  }, [cell])

  const cambiovalue = (value)=>{
      setLoading(true)
      let obj = {
        id:cell.idItem
      }
      obj.position = value
      _updateItemTurno(obj,(risultato)=>{
        
        let newC = cell;
        newC.status = value
        setValueC(newC)
        setLoading(false)
        
      })
    } 

      

      let colorStaur = '#74788d'
      switch (valueC.status) {
        case "candidati":
          colorStaur = '#74788d'
          break;
        case "selezionati":
          colorStaur = '#34c38f'
          break;
        case "interessanti":
          colorStaur = '#f1b44c'
          break;
      
        default:
          break;
      }

      console.log('vediamo se posso');
      console.log();

      switch (valueC.type) {
        case 'text':
          return (<div>
                    <p style={{color:'black', fontWeight:'300',fontSize:16,padding:10,marginTop:10, textTransform: 'uppercase'}}>{cell.text}</p>
                    {/*<div style={{display:'flex'}}>
                    <div style={{width:20,height:20,borderRadius:10, cursor:'pointer',background:'rgb(190, 211, 243)'}} onClick={()=>provaAction(cell,'candidati')}/>
                    <div style={{width:20,height:20,borderRadius:10, cursor:'pointer',background:'rgb(246, 185, 59)'}} onClick={()=>provaAction(cell,'interessanti')}/>
                    <div style={{width:20,height:20,borderRadius:10, cursor:'pointer',background:'rgb(0, 107, 118)'}} onClick={()=>provaAction(cell,'selezionati')}/>
                    </div>*/}
                  </div>
                );
        case 'card':
          return (
           
          <Card>
            <CardBody  id={'cardId'+valueC.user[0]._id}  style={{backgroundColor:colorStaur}}>
              <p>{loading ?  <Spinner color="primary" /> : null}</p>
              {valueC.user[0].typoDI}
              <a target='_blank' href={'https://wa.me/'+numberFormater(valueC.user[0].cell)}><p style={{color:'black'}} className="">{valueC.user[0].cell ?  valueC.user[0].cell  : null}</p></a>
              
              <Media> 
                
               <a target="_blank" href={'/user-profile/'+valueC.user[0]._id}>
                <img
                    src={valueC.user[0].img_profilo}
                    className="rounded-circle avatar-xs m-1"
                    alt=""
                  />
              </a> 
              
                

                {/*<Media style={{padding:10}} className="overflow-hidden" body>
                  <h5 style={{color:'white'}} className="text-truncate font-size-15">
                    {cell.user[0].nome} {cell.user[0].cognome}
                  </h5>
                  <p className=" mb-4" style={{color:'white'}} ></p>
                  <li style={{color:'white',fontSize:17}} className="list-inline-item mr-3" id="dueDate">
                  {" CkIn : "}{timeToString(cell.ckIn)}
                  
                </li>
                <li style={{color:'white',fontSize:17}} className="list-inline-item mr-3" id="comments">
                  {" Ckout : "}
                  {timeToString(cell.ckOut)}
                  
                </li>
                  <div className="team">
                    
                  </div>
                </Media>*/}
              </Media>
              
              {valueC.ckIn ? (
                <React.Fragment>
                   <li style={{color:'white',fontSize:17}} className="list-inline-item mr-3" id="dueDate">
                {" CkIn : "}{timeToString(valueC.ckIn)}
                
              </li>
              <li style={{color:'white',fontSize:17}} className="list-inline-item mr-3" id="comments">
                {" Ckout : "}
                {timeToString(valueC.ckOut)}
                
              </li>
                </React.Fragment>
               
              ) : null}

              <UncontrolledTooltip placement="top" target={'cardId'+valueC.user[0]._id}>
                   {valueC.user[0].nome} {valueC.user[0].cognome}
                  </UncontrolledTooltip>
            </CardBody>
            <div className="px-4 py-3 border-top">
              <select onChange={(e)=>cambiovalue(e.target.value)}>
                <option  selected={valueC.status == "selezionati"} value="selezionati">Selezionato</option>
                {/*<option  selected={cell.status == "interessanti"} value="interessanti">Interessante</option>*/}
                <option selected={valueC.status == "candidati"} value="candidati">Candidato</option>
              </select>
            </div>
          </Card>
            /*<div onClick={()=>console.log('ppp')} style={{background:bgd(cell.status),padding: 0,borderRadius: 5,cursor:'pointer'}}>
              <div style={{display:'flex'}}><div style={{width:40,height:40}}>{cell.user[0].img_profilo ? <img src={cell.user[0].img_profilo} alt='.' style={{width: '100%', height: '100%', borderTopLeftRadius: 5, objectFit: 'cover', background: 'rgb(246, 185, 59)'}}/> : null}</div>
              <p style={{marginLeft:10,fontWeight:'700',color:'#122436'}}>{cell.user[0].cognome} {cell.user[0].nome}</p></div>
              {cell.status === 'selezionati' ? (
                <div style={{display:'',padding:10,background:'#0099a9',color: 'black',textAlign: 'center'}}><p style={{marginRight:5}}>check in: <strong style={{fontWeight:800}}>{cell.ckIn ? getDateD(cell.ckIn) : null}</strong></p>
                <p>check out: <strong style={{fontWeight:800}}>{cell.ckOut ? getDateD(cell.ckOut) : null}</strong></p></div>
              ) : null }
            </div>*/
          );
        default:
          return <p>...</p>
      }
  
}



export default ItemTurno
