import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { indexOf, map } from "lodash"
import images from "assets/images"
import {_getStaffUserApi,UpdateInfoLavoro} from "../../../helpers/server_helper"

const TeamMembers = ({id, team,gestore }) => {

  const [teams,setTeams] = useState([])



  const cambiaSelect = (index) =>{
    console.log(index);
    let newArr = [...teams]
    newArr[index].active = !newArr[index].active
    setTeams(newArr)  
  }


  const salvaCambioTeam = () =>{
    //console.log(teams);
    let newReferenti = []
    teams.forEach(element => {
      if(element.active){
        newReferenti.push(element._id)
      }
    });
    let obj = {
      id:id,
      referenti:newReferenti
    }
    
    UpdateInfoLavoro(obj,(result)=>{
      alert('referenti salvati')
    })
  }

  useEffect(() => {
    if (team) {
      let selectedTeam = []
      _getStaffUserApi((results)=>{
        //console.log(results);
        results.forEach(user => {
          
          let isJust = team.indexOf(user._id)
          
          user.active = isJust > -1 ? true : false
          selectedTeam.push(user)
        });
        setTeams(selectedTeam)
      })
      
    }
  }, [team])


  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Team Members</CardTitle>

        <div className="table-responsive">
          <Table className="table table-centered table-nowrap">
            <tbody>
              {map(teams, (member, k) => (
                <tr style={{backgroundColor: member.active ? '#efef' :'#fff',cursor:'pointer'}} key={"_member_" + k} onClick={gestore ? ()=>cambiaSelect(k) : ()=>alert('non hai il permesso')}>
                  <td style={{ width: "50px" }}>
                    {member.img_profilo !== "Null" ? (
                      <img
                        src={member.img_profilo}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    ) : (
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
                          {member.name.charAt(0)}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    <h5 className="font-size-14 m-0">
                    {member.nome}
                    </h5>
                  </td>
                  <td>
                    <div>
                      {/*map(member.skills, (skill, key) => (
                        <Link
                          to="#"
                          className="badge badge-primary font-size-11 mr-1"
                          key={"_skill_" + key}
                        >
                          {skill.name}
                        </Link>
                      ))*/}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {gestore ? (
          <button onClick={()=>salvaCambioTeam()} class="btn btn-primary waves-effect waves-light btn btn-primary">Salva</button>
        ) : (
          <p></p>
        )}
      </CardBody>
    </Card>
  )
}

TeamMembers.propTypes = {
  team: PropTypes.array,
}

export default TeamMembers
