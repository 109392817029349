import React, {useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
import {_getAllCategoriaInt , _createCategorInt} from "../../helpers/server_helper"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ProjectsCreate = () => {
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [selectedFiles, setselectedFiles] = useState([])
  const [listaInterni, setListaInterni] = useState([])
  const [categoria, setCategoria] = useState({})
  const [special, setSpecial] = useState(false)
  const [titolo, setTitolo] = useState('')
  const [sex, setSex] = useState('')



  useEffect(() => {
    
      _getAllCategoriaInt((risultato)=>{
        setListaInterni(risultato)
      })
    
  }, [])

  const startDateChange = date => {
    setstartDate(date)
  }

  const endDateChange = date => {
    setendDate(date)
  }

  const addValue = (value,type) =>{
    let obj =  categoria;
    obj[type] = value.target.value;
    
    setCategoria(obj)

  }


  const salva = () =>{
    let obj = {
      titolo:titolo,
      sex:sex,
      special:special
    };
    
    _createCategorInt(obj,(result)=>{
      alert('salvato')
      setTitolo('')
      setSex('')
      setSpecial(false)
      _getAllCategoriaInt((risultato)=>{
        setListaInterni(risultato)
      })
    })

  }


  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Gestisci categorie interne" />

          <Row>
            <Col lg="4">
              <Card style={{ maxHeight: 400,overflowX: 'scroll'}}>
                <CardBody>
                  {listaInterni.map((item,x)=>{
                    return(
                      <div>
                        <h5>{item.titolo}</h5>
                        <p>Desc: {item.sex}</p>
                        <p>{item.special ? 'Special' : null}</p>
                      </div>
                      
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
            <Col lg="8">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Crea nuova categoria interna</CardTitle>
                  <Form>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Nome categoria
                      </Label>
                      <Col lg="10">
                        <Input
                          id="projectname"
                          name="projectname"
                          type="text"
                          className="form-control"
                          value={titolo }
                          onChange={(e)=>setTitolo(e.target.value)}
                          placeholder="Enter nome categoria"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Descrizione categoria
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          value={sex}
                          onChange={(e)=>setSex(e.target.value)}
                          rows="3"
                          placeholder="Enter descrizine categoria"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Col lg="5">
                        <div className="custom-control custom-checkbox custom-checkbox-primary mb-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheckcolor1"
                              checked={special}
                              onChange={() => {
                                setSpecial(!special)
                              }}
                            />

                            <label
                              className="custom-control-label"
                              htmlFor="customCheckcolor1"
                            >
                              special
                            </label>
                          </div>
                        <div>
                        
                        </div>
                        </Col>
                    </FormGroup>

                    

                    
                  </Form>
                  
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button disabled={false} onClick={()=>salva()} type="submit" color="primary">
                        Crea Categoria
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreate
