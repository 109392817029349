import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Media ,Row} from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"
import images from "assets/images"
import { useTable } from 'react-table'
import { _createTurnospecial  } from "../../../helpers/firebase_helper";
import { _ckinalllavoroxmese ,_Findxlavoroxmese } from "../../../helpers/server_helper";
import { timeToString,makeDataTest } from "../../../helpers/formatting";
import {
  JsonToExcel
} from 'react-json-excel';



const className = 'class-name-for-style ',
  fields = {
    "staff": "staff",
    "giorno":"giorno",
    "ckin": "Ckin",
    "ckOut": "CkOut",
    "difference": "Ore di lavoro"
  },
  style = {
    padding: "5px"
  },
  dataxxx = [
    { index: 0, guid: 'asdf231234'},
    { index: 1, guid: 'wetr2343af'}
  ];

const Comments = ({ columns, titolo, data ,actionA ,functionName,carica,lavoroID,month,duplicamento }) => {

  const text = "esporta "+titolo
  const filename = 'Excel-report '+titolo;
  let giorni = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]

  const [gestione,setGestione] = useState(true)
  const [loading,setLoading] = useState(true) 
  const [start,setStart] = useState(null) 
  const [end,setEnd] = useState(null)
  const [userXT,setUserXT] =  useState([])
  const [staffExort,setStaffExport] =  useState([])

  console.log('vediamo il meseee');
  console.log(month);

  let meseAnno =  ( String(month)+'2022');

 // let meseAnno =  (month ? month : new Date(progg.from).getMonth())+'2021';

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  const msToTime = (duration,exporter)  => {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
  
    if(exporter){
      return hours + ":" + minutes ;
    }else{
      return 'h: '+hours + ":" + minutes ;
    }
    
  }

  const differcTime = (date2,date1,exporter) =>{
    if(date2 && date1 ){
      let one = new Date(date1)
      let two = new Date(date2)
      var Difference_In_Time = two.getTime() - one.getTime();
      return msToTime(Difference_In_Time,exporter) 
    }else{
      return null
    }
    
  }


  const magiaExport = (dati) =>{
    let newDatone = []
    dati.forEach(element => {
      let nome = element.idStaff.cognome+' '+element.idStaff.nome;
      Object.keys(element.giorni).map((key,i)=>{
        //console.log('gioni');
        //console.log(key);
        newDatone.push({ staff: element.idStaff.cognome+' '+element.idStaff.nome, 
        giorno: codificaNome(key), 
        ckin:element.giorni[key][Object.keys(element.giorni[key])[0]].ckin ? timeToString(element.giorni[key][Object.keys(element.giorni[key])[0]].ckin) : 'no',
        ckOut:element.giorni[key][Object.keys(element.giorni[key])[0]].ckOut ? timeToString(element.giorni[key][Object.keys(element.giorni[key])[0]].ckOut) : 'no',
        difference:differcTime(element.giorni[key][Object.keys(element.giorni[key])[0]].ckOut,element.giorni[key][Object.keys(element.giorni[key])[0]].ckin,true)})
      })
     
      
      
    });

    console.log('esporto');
    console.log(newDatone);

    setStaffExport(newDatone)
  }

  const gestioneStaff = () =>{

    let stringMese = ''

      if(meseAnno.length == 6){
        let anno = meseAnno.slice(-4);
        let mese = meseAnno.substring(0, 2)
        console.log('meseeee');
        console.log(mese);
        stringMese = (Number(mese)+1)+'-'+anno
      }else{
        let anno = meseAnno.slice(-4);
        let mese = meseAnno.substring(0, 1)
        stringMese = '0'+(Number(mese)+1)+'-'+anno
      }


      alert('sono quiiii '+stringMese+' lav: '+lavoroID)



    
    console.log(stringMese);
    console.log(lavoroID);

    
    //chiamo funxione ma quale
    _ckinalllavoroxmese(lavoroID,stringMese,(userChek)=>{
      console.log('risultato');
      console.log(userChek);
      magiaExport(userChek)
      setUserXT(userChek)
      setGestione(false)
    })


    {/*
    
    _Findxlavoroxmese(lavoroID,'02022',(userChek)=>{
      console.log('risultato vediamo che fare');  
      console.log(makeDataTest(userChek));
      //magiaExport(userChek)
      setUserXT(makeDataTest(userChek))
      setGestione(false)
    })
  */}



  }


  

  const creaTurno = () =>{
    let obj = {
      turnoString: start+'-'+end,
      meseAnno:meseAnno,
      lavoro:lavoroID
    }
    console.log(obj);
   
    


  }

  useEffect(() => {
      
      
    
  }, [data])
  
  const codificaNome = (key) =>{
    let numeroD = key.replace('Day','')
    return numeroD+'/10/2021'
  }
  const today = (key) =>{
    let numeroD = key.replace('Day','')
    let todays = new Date().getDate()
    if( numeroD == todays){
      return true
    }else{
      return false
    }
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4"></CardTitle>
        <Row style={{marginBottom:20}}>
         
          {gestione ? (
              <button style={{marginRight:10}} class="waves-effect waves-light btn btn-primary">Turni</button> 
          ) : (
            <button onClick={()=>setGestione(true)}style={{marginRight:10}} class="waves-effect btn btn-outline-primary">Turni</button> 

          )}

          {!gestione ? (
              <button style={{marginRight:10}} class="waves-effect waves-light btn btn-primary">*Staff</button> 
          ) : (
            <button onClick={()=>gestioneStaff()}style={{marginRight:10}} class="waves-effect btn btn-outline-primary">*Staff</button> 

          )}


            
        {/*<button onClick={()=>duplicamento()}style={{marginRight:10}} class="waves-effect btn btn-outline-primary">Duplicate</button> */}
            

        </Row>

        
        
        <div style={{overflowX: 'scroll', maxHeight: '60vh'}} className="text-center mt-4 pt-2">
        
        {gestione ? (
          <table {...getTableProps()}>
          <thead >
            {headerGroups.map(headerGroup => (
              <tr  style={{border:'1px solid'}} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th style={{backgroundColor:'#ddd',padding:20,border:'1px solid'}} {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              let newthis = this;
              prepareRow(row)
              return (
                <tr style={{border:'1px solid'}} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    if(cell.column.Header != 'orari'){
                      return <td style={{border:'1px solid'}} onClick={()=>console.log(cell)} {...cell.getCellProps()}>
                      {cell.value ? (
                           cell.value.max < 1 ? (
                             gestione ? (
                              <p style={{margin:5,background:'#ff7979',padding:10,borderRadius:20, color:'black'}} onClick={()=>console.log(cell)}><input style={{width:'100%',background: 'none',border: 'none'}} onChange={(e)=>actionA(e.target.value,cell)} type='Number' value={cell.value ? cell.value.max : 0}/></p>

                             ) : <Card style={{backgroundColor:'#ff7979'}} className="mini-stats-wid">
                             <CardBody>
                               </CardBody>
                               </Card>

                          ) : (
                            <div>
                              {cell.value.persona && !gestione ? Object.keys(cell.value.persona).map((key,i)=>{
                                return <div key={i}>{functionName(key,cell.value.persona[key])}</div>
                              }) : null}
                              {cell.value && cell.value.nome ? cell.value.nome.map((x,i)=>{
                                return <p>{x.nome}</p>
                              }) : null}
                              {gestione ? (
                                 <p style={{margin:5,background:'#bed3f3',padding:10,borderRadius:20, color:'black'}} onClick={()=>console.log(cell)}><input style={{width:'100%',background: 'none',border: 'none'}} onChange={(e)=>actionA(e.target.value,cell)} type='Number' value={cell.value ? cell.value.max : 0}/></p>
                              ) : null}
                            </div>
                        )
                      ) :
                      gestione ? (  
                       <p style={{margin:5,background:'#ff7979',padding:10,borderRadius:20, color:'black'}} onClick={()=>console.log(cell)}><input style={{width:'100%',background: 'none',border: 'none'}} onChange={(e)=>actionA(e.target.value,cell)} type='Number' value={cell.value ? cell.value.max : null}/></p>
                      ) : (
                        <Card style={{backgroundColor:'#ff7979'}} className="mini-stats-wid">
                        <CardBody>
                          </CardBody>
                          </Card>
                      )
                    }
                      </td>
                    }else{
                      return <td onClick={()=>console.log(cell)} {...cell.getCellProps()}>
                      
                      <p style={{margin:5,fontWeight:'900',width:100}} onClick={()=>console.log(cell)}>{cell.render('Cell')}</p>
                      </td>
                    }
                    
                  } )}
                </tr>
              )
            })}
          </tbody>
        </table>
        
        ) : (
          <div>
             
             <div style={{position:'absolute',top:45,left:150}}><JsonToExcel
              data={staffExort}
              className={className}
              filename={filename}
              fields={fields}
              style={style}
              text={text}
            /></div>
          <div style={{whiteSpace: 'nowrap'}}>
          
          

          
          {userXT.map((tsb,i)=>{
            return(
              <div style={{display: 'inline-block'}} >
              <table style={{borderCollapse:'collapse'}}>
                <tr style={{border:'1px solid #ddd'}}>
                  <th style={{zIndex:999999,backgroundColor:tsb.giorni['Dayundefined'] ?'red':'#eee',width:'100%'}}></th>
                  <th style={{zIndex:999999,backgroundColor:tsb.giorni['Dayundefined'] ?'red':'#eee',width:'100%'}}>
                  <p style={{fontWeight:'bold',padding:10}}>{tsb.idStaff.cognome} {tsb.idStaff.nome}</p>
                  </th>

                </tr>

                
                
              {giorni.map((day,index)=>{
                return(
                  <tr>
                    <td style={{ border:'1px solid #ddd',padding:8,color: today('Day'+day)? 'red' : 'black'}} >{codificaNome('Day'+day)}</td>
                    {tsb.giorni['Day'+day] && Object.keys(tsb.giorni['Day'+day]).map((turnoKey, i) => (
                        <td style={{border:'1px solid #ddd',padding:8}} >
                          <div style={{backgroundColor:'#eee',width:200,height:120,padding:10,borderRadius:10}}>
                           
                            <p style={{marginTop:10}}>Ckin: {timeToString(tsb.giorni['Day'+day][turnoKey].ckin)}</p>
                            <p>CkOut: {timeToString(tsb.giorni['Day'+day][turnoKey].ckOut)}</p>
                            <div style={{padding:'5',backgroundColor:'#008200',color:'#fff',marginBottom:10,borderRadius:5}}>{differcTime(tsb.giorni['Day'+day][turnoKey].ckOut,tsb.giorni['Day'+day][turnoKey].ckin)}</div>
                          </div>
                        </td>
                    ))}
                    {!tsb.giorni['Day'+day] ? (
                      <td style={{border:'1px solid #ddd',padding:8}} >
                      <div style={{backgroundColor:'#fff',width:200,height:120, padding:10,borderRadius:10}}>
                       
                        
                      </div>
                    </td>
                    ) : null }
                  </tr>
                )
              })}
              
                </table>
              </div>
            )
          })}




          </div>
          </div>
        )}

        
        </div>
      </CardBody>
      
    </Card>
  )
}

Comments.propTypes = {
  comments: PropTypes.array,
}

export default Comments
