import firebase from "firebase/app"

// Add the Firebase products that you want to use
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import { indexOf } from "lodash"

//export const fireDB = firebase.firestore();

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig)
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          
          user.getIdToken().then(function(idToken) {
            console.log('tokennnn');
            console.log(idToken);
          })
          user.getIdTokenResult()
          .then((idTokenResult) => {
           
            console.log('oookkkkk');
            console.log(idTokenResult.claims);
            console.log(idTokenResult);
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.admin) {
              console.log('aggiungo admin');
              let newUser = JSON.stringify(user)
              let newUserInfo = JSON.parse(newUser)
              newUserInfo.admin = true;
              newUserInfo.mongoDbUid = idTokenResult.claims.mongoDbUid;
                console.log(newUserInfo);
                localStorage.setItem("authUser", JSON.stringify(newUserInfo))
            } else {


              

              if (!!idTokenResult.claims.operetor_staff) {
                let newUser2 = JSON.stringify(user)
                let newUserInfo2 = JSON.parse(newUser2)
                newUserInfo2.operetor_staff = true;
                newUserInfo2.mongoDbUid = idTokenResult.claims.mongoDbUid;
                //alert(idTokenResult.claims.mongoDbUid)
                console.log('coseee');
                console.log(newUserInfo2);
                localStorage.setItem("authUser", JSON.stringify(newUserInfo2))
                
              } else {
                // Show regular user UI.
                localStorage.removeItem("authUser")
                
              }
              // Show regular user UI.
              
              //this.logout()
             
              
            }
          })
          .catch((error) => {
            console.log(error);
          })
          
          /*let newUser = JSON.stringify(user)
          let ok = JSON.parse(newUser)
          var userInfo = firebase.database().ref('users/' + user.uid );
          userInfo.on('value', (snapshot) => {
            const data = snapshot.val();
          if(data){
              ok.mongoId = data.mongoId;
              ok.imageProfile = data.imageProfile;
              ok.nomeCompleto = data.name+' '+data.cognome;
              ok.staffPo = data.roles;
              let admin = data.roles.indexOf('STAFF_W')
              console.log('quiiii  salvooo');
              if(admin > -1){
                localStorage.setItem("authUser", JSON.stringify(ok))
              }else{
                localStorage.removeItem("authUser")
              }
            }else{
              localStorage.removeItem("authUser")
            }
            
           
            
          });*/



          /*if(user.email == 'cainoeraabele@gmail.com'){
            localStorage.setItem("authUser", JSON.stringify(user))
          }else{
            localStorage.removeItem("authUser")
          }*/
          
          
          localStorage.setItem("authUser", JSON.stringify(user))
        } else {
          localStorage.removeItem("authUser")
        }
      })
    }
  }


  

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    console.log('qui dovrei fare controllo x admin');
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            console.log('mosytro user');
            console.log(user);
            firebase.auth().currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                console.log('fase 3');
                console.log(idTokenResult.claims);
                // Confirm the user is an Admin.
                if (!!idTokenResult.claims.admin) {
                  // Show admin UI.
                    //alert('eccoti admin')
                    let newUser = JSON.stringify(user)
                    let newUserInfo = JSON.parse(newUser)
                    newUserInfo.admin = true;
                    newUserInfo.mongoDbUid = idTokenResult.claims.mongoDbUid;
                    localStorage.setItem("authUser", JSON.stringify(newUserInfo))
                    console.log(firebase.auth().currentUser);
                    resolve(firebase.auth().currentUser)
                } else {


                  if (!!idTokenResult.claims.operetor_staff) {
                    let newUser2 = JSON.stringify(user)
                    let newUserInfo2 = JSON.parse(newUser2)
                    newUserInfo2.operetor_staff = true;
                    
                    newUserInfo2.mongoDbUid = idTokenResult.claims.mongoDbUid;
                    localStorage.setItem("authUser", JSON.stringify(newUserInfo2))
                    
                    resolve(firebase.auth().currentUser)
                  } else {
                    // Show regular user UI.
                    console.log('no no puoi entrare');
                    alert('no no non sei un admin');
                    reject(this._handleError('no no non sei un admin'))
                    //this.logout()
                    //ocalStorage.removeItem("authUser")
                    
                  }
                 /* // Show regular user UI.
                  console.log('no no non sei un admin');
                  alert('no no non sei un admin');
                  reject(this._handleError('no no non sei un admin'))
                  //this.logout()
                  //ocalStorage.removeItem("authUser")*/
                  
                }
                
              })
              .catch((error) => {
                console.log(error);
              })

            /* old
             console.log('quiiii 1');
            resolve(firebase.auth().currentUser)*/
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem("authUser")
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {}
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(data.idToken, data.token)
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token)
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase.auth().signInWithCredential(credential)
          .then(user => {
            resolve(this.addNewUserToFirestore(user))
          })
          .catch(error => {
            reject(this._handleError(error))
          })
      } else {
        reject(this._handleError(error))
      }
    })
  }

  addNewUserToFirestore = (user) => {
    const collection = firebase.firestore().collection("users")
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    }
    collection.doc(firebase.auth().currentUser.uid).set(details)
    return { user, details }
  }

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    console.log('qui controllo se auth ??');
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
