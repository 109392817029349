import React, { useEffect, useState } from "react"
import PropTypes, { array } from "prop-types"
import { Card, Modal,CardBody, CardTitle, Media ,Row,Button} from "reactstrap"



const ModalList = ({ isOpen , user ,actionSave ,chiudi,gestioneRosa }) => {

  const [modal_standard,setModal_standard] = useState(false) 
  const [obj,setObj] = useState({}) 
  const [simg,setSimg] = useState([]) 



  const actionBannes = (bannato) =>{
    if(bannato){
      console.log('è bannato');
      gestioneRosa(user._id,true)
    }else{
      console.log('ripristino');
      gestioneRosa(user._id,false)
    }
   
  }

  const aggiungi = () =>{
    let objaggiungi = {...obj}
    objaggiungi.images = simg
    console.log(objaggiungi);
    actionSave(objaggiungi)
  }

  const cvaleu = (v) =>{
    let objx = {...obj}
    objx.altezza = v
    setObj(objx)
  }
  const isselet = (id) =>{
    let imggg = [...simg]
    return imggg.includes(id)
  }
  const imgSAdd = (id) =>{
    let imggg = [...simg]
    imggg.push(id)
    setSimg(imggg)
  }
  const imgSRemove = (id) =>{
    let imggg = [...simg]
    const index = imggg.indexOf(id);
    if (index > -1) {
      imggg.splice(index, 1);
    }
    
    setSimg(imggg)
  }

  useEffect(
    () => {
      console.log('ooooo');
      setModal_standard(isOpen)
      setObj(user ? user : {images:[]} )
    },
    [isOpen,user],
  );
  /*useEffect(() => {
      
      console.log('mostro  objettop');
      console.log(user);
      setModal_standard(isOpen)
      setObj(user)
    
  }, [user])*/
  

  return (
    <Modal
        isOpen={modal_standard}
        toggle={() => {
          modal_standard
        }}
      >
        {user ? (
          <div style={{width:'100%'}} className="modal-header">
            <Card style={{width:'100%'}}>
              <CardTitle>Qui puoi gestire la visualizazione della risorsa nella rosa dei candidati</CardTitle>
              <CardBody>
                
              </CardBody>
              <Button style={{marginBottom:10}} onClick={()=>actionBannes(true)} type="button" color="primary">
                           Elimina dalla rosa
                          </Button>
              <Button style={{marginBottom:10}} onClick={()=>actionBannes(false)} type="button" color="success">
                Riabilita la risorsa
              </Button>           
              <Button onClick={()=>chiudi()} type="button" color="danger">
                Chiudi
              </Button>
            </Card>
          </div>
        ) : null}
        
    </Modal>
  )
}

ModalList.propTypes = {
  
}

export default ModalList
