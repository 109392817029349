import React, {useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row,TabContent,TabPane,Table } from "reactstrap"
import {_getAllCategoriaInt , _createCategorInt,_getClineti,_createCliente} from "../../helpers/server_helper"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ProjectsCreate = () => {
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [selectedFiles, setselectedFiles] = useState([])
  const [listaInterni, setListaInterni] = useState([])
  const [categoria, setCategoria] = useState({})
  const [special, setSpecial] = useState(false)
  const [titolo, setTitolo] = useState('')
  const [sex, setSex] = useState('')

  const [selected,setSelected] = useState(null)


  const [load, setload] = useState(false)

  const [alias, setTalias] = useState('')
  const [ragione_sociale, setragione_sociale] = useState('')
  const [indirizzo, setindirizzo] = useState('')
  const [pIva, setpIva] = useState('')
  



  useEffect(() => {
    
    _getClineti((risultato)=>{
      console.log(risultato);
        setListaInterni(risultato)
      })
    
  }, [])


  const cambio = (type,value) =>{
    setload(true)
    let newT = {...selected}
    newT[type] =  value;
    setSelected(newT)
    /setload(false)
  }

  const startDateChange = date => {
    setstartDate(date)
  }

  const endDateChange = date => {
    setendDate(date)
  }

  const addValue = (value,type) =>{
    let obj =  categoria;
    obj[type] = value.target.value;
    
    setCategoria(obj)

  }


  const salva = (uplo) =>{
    
    if(uplo){

    }else{
      let obj = {
        alias,
        ragione_sociale,
        indirizzo,
        pIva,
      };
      
      _createCliente(obj,(result)=>{
        alert('salvato')
        setTalias('')
        setragione_sociale('')
        setindirizzo('')
        setpIva('')
        _getClineti((risultato)=>{
          setListaInterni(risultato)
        })
      })
    }

    

  }


  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Clienti" />

          <Row>
            <Col lg="7">
              <div style={{maxHeight: '65vh',overflow: 'scroll'}}>
                <CardBody>
               
            <TabPane tabId="1">
            <Row>
              <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap table-centered table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Nome</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listaInterni.map((x,i)=>{
                            return(
                              <tr key={i}>
                              <td>
                                { x.logo ? (
                                  <img
                                    src={x.logo}
                                    alt=""
                                    className="avatar-sm"
                                  />
                                ):(
                                  <p>no Img</p>
                                )
                                }
                                
                              </td>
                              <td>
                                <Link
                                    to={`/cliente/${x._id}`}
                                    className="text-dark"
                                  >
                                  <h5>{x.alias}</h5>
                                </Link>
                              </td>
                              </tr>
                            )
                          })}
                          
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>

            {/* special */}
          





                </CardBody>
              </div>
            </Col>
            <Col lg="4">
              {selected ? (
                <Card>
                  <p>{load ? '..' : null}</p>
                <CardBody>
                  <CardTitle className="mb-4">{selected.alias}</CardTitle>
                  <Form>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Nome 
                      </Label>
                      <Col lg="10">
                        <Input
                          id="projectname"
                          name="projectname"
                          type="text"
                          className="form-control"
                          value={selected.alias ? selected.alias : '' }
                          onChange={(e)=>cambio('alias',e.target.value)}
                          placeholder="Enter nome "
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Ragine Sociale
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={selected.ragione_sociale ? selected.ragione_sociale : ''}
                          onChange={(e)=>cambio('ragione_sociale',e.target.value)}
                          rows="3"
                          placeholder="Enter Ragine Sociale"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        indirizzo
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={selected.indirizzo ? selected.indirizzo : ''}
                          onChange={(e)=>cambio('indirizzo',e.target.value)}
                          rows="3"
                          placeholder="Enter indirizzo"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        PIva
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={selected.pIva ? selected.pIva : ''}
                          onChange={(e)=>cambio('pIva',e.target.value)}
                          rows="3"
                          placeholder="Enter PIva"
                        />
                      </Col>
                    </FormGroup>
                    

                    

                    
                  </Form>
                  
                  <Row className="justify-content-end">
                    <Col lg="10">
                      {/*<Button disabled={false} onClick={()=>salva(true)} type="submit" color="primary">
                        Salva
                      </Button>*/}
                      <Button style={{backgroundColor:'black'}} disabled={false} onClick={()=>setSelected(null)} type="submit" >
                        chiudi
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              ): (
                <Card>
                <CardBody>
                  <CardTitle className="mb-4">Crea Cliente</CardTitle>
                  <Form>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Nome 
                      </Label>
                      <Col lg="10">
                        <Input
                          id="projectname"
                          name="projectname"
                          type="text"
                          className="form-control"
                          value={alias }
                          onChange={(e)=>setTalias(e.target.value)}
                          placeholder="Enter nome "
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Ragine Sociale
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={ragione_sociale}
                          onChange={(e)=>setragione_sociale(e.target.value)}
                          rows="3"
                          placeholder="Enter Ragine Sociale"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        indirizzo
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={indirizzo}
                          onChange={(e)=>setindirizzo(e.target.value)}
                          rows="3"
                          placeholder="Enter indirizzo"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        PIva
                      </Label>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          value={pIva}
                          onChange={(e)=>setpIva(e.target.value)}
                          rows="3"
                          placeholder="Enter PIva"
                        />
                      </Col>
                    </FormGroup>
                    

                    

                    
                  </Form>
                  
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button disabled={false} onClick={()=>salva()} type="submit" color="primary">
                        Crea cliente
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreate
