import React, { useEffect,useState } from "react"

import { Row, Col, Card, CardBody, CardTitle, CardSubtitle,Spinner ,Pagination,PaginationItem,PaginationLink,FormGroup,Label,InputGroup,Button} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import  "./testcsc.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { _getAllFeedPagin } from "../../helpers/server_helper";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { makeDataPerXl } from "./makeData";
import {
  JsonToExcel
} from 'react-json-excel';
const ResponsiveTables = () => {

  const [items,setItems] = useState([])
  const [metaInfo,setMeta] = useState({})
  const [loading,setLoadind] = useState(true)
  const [showF,setShowF] = useState(false)
  const [showW,setShowW] = useState(false)
  const [xlDate,setXldata] = useState([])
  const [filter,setfilter] = useState({
    'createdAt': { 
      '$gte': new Date('2022/03/01')
    }
  })
  const className = 'class-name-for-style';

  let fieldsTabella = {
    "giornoString": "Data",
    "referentio":'Staff',
    "idLavoro": "POP UP",
    "trial": "TRIAL",
    "gusto":"GUSTO STICK",
    "gender":"GENDER",
    "age":"ETA'",
    "cfumi":"COSA FUMI",
    "brand":"BRAND",
    "dcac":"DOVE CI HAI CONOSCIUTO",
    "gadget":"GADGET",
    "acquisto":"ACQUISTO",
    "feedback":"FEEDBACK TRIAL"
  };


  const filterDate = (value) => {
    let filters = {...filter}
    if(value.length > 1){
      filters.createdAt = {
        '$gte': new Date(value[0]),
        '$lte': new Date(value[1]),
      }
    }else{
      filters.createdAt = {
        '$gte': new Date(value[0])
      }
    }
    setfilter(filters)
  }

  const addFilter = (type,value) =>{
    let filters = {...filter}
    if(value.target.value == 'remove'){
      delete filters[type];
    }else{
      filters[type] = value.target.value
    }
    
    setfilter(filters)
  }



  const Filtra = () => {
    console.log(filter);
    setShowF(false)
    _getAllFeedPagin(0,filter,(strutture)=>{
      console.log('vediamo che fà');
      console.log(strutture);
      setItems(strutture[0].data)
      setMeta(strutture[0].meta)
      setLoadind(false)

      
      setShowW(true)
    })
  }


  const Export = () => {
    console.log(filter);
    _getAllFeedPagin('tutto',filter,(strutture)=>{
      console.log('vediamo che fà');
      let newsDATAXL = makeDataPerXl(strutture[0].data,true);
      console.log('mostrop dato');
      setXldata(newsDATAXL)
      setShowF(true)
    })
  }


  const vaiallaPagina = (pagina) =>{
    setLoadind(true)
      console.log('pagina');
      console.log(pagina);
      _getAllFeedPagin(pagina,filter,(strutture)=>{
        console.log('vediamo che fà');
        console.log(strutture);
        setItems(strutture[0].data)
        setMeta(strutture[0].meta)
        setLoadind(false)
      })
  }


  const Pagine = () =>{
    var pagine = [];
    if(metaInfo.pages){
      
      for (var i = 0; i < metaInfo.pages; i++) {
        let pagina = i+1;
        let objjj = (
          <PaginationItem active={pagina === metaInfo.page} key={i}>
            <PaginationLink numero={'po'}
              onClick={(e) => vaiallaPagina(pagina)}
              href="#"
            >
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        )
        pagine.push(objjj);
      }
    }
    
    return pagine
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))

      _getAllFeedPagin(1,filter,(strutture)=>{
        console.log('mosyttro');
        console.log(strutture);
        setItems(strutture[0].data)
        setMeta(strutture[0].meta)
        setLoadind(false)
      })

      /*_getAllStruttureApi((result)=>{
       
        console.log(result);
        setStrutture(result)
      })*/
      

      

    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Feedback" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Feedback </CardTitle>
                  <CardSubtitle className="mb-3">
                    Qui puoi vedere il report dei feedback
                  </CardSubtitle>

                  <Row>
                    <Col>
                    <FormGroup className="mb-4">
                      <Label>Date</Label>
                      <InputGroup>
                        <Flatpickr
                           className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            onChange: function(selectedDates, dateStr, instance) {
                              console.log('dateStr');
                              console.log(selectedDates);
                              filterDate(selectedDates)
                          }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    </Col>
                    <Col>
                    <FormGroup className="mb-4">
                      <Label>Gender</Label>
                      <InputGroup>
                      <div className="col-md-10">
                        <select onChange={(e)=>addFilter('gender',e)} className="form-control">
                          <option value="remove">Select</option>
                          <option value="M">M</option>
                          <option value="F">F</option>
                        </select>
                      </div>
                      </InputGroup>
                    </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-4">
                        <Label>Pop up</Label>
                        <InputGroup>
                        <div className="col-md-10">
                          <select onChange={(e)=>addFilter('idLavoro',e)} className="form-control">
                            <option value="remove">Select</option>
                            <option value='5f306d8dafda4977669aaa1f'>Arese</option>
                            <option value='5f4ad33b1799ce00172400ca'>Gran Reno</option>
                            <option value='624d748775142ceb63718be0'>Centro Sarca</option>
                            <option value='5f4acd871799ce00172400c8'>Adigeo</option>
                            <option value='5f4ac91f1799ce00172400b8'>i Gigli</option>
                            <option value='5f4ac6671799ce00172400b7'>Maximo</option>
                            <option value='5f4ac3cd1799ce00172400b6'>Euroma 2</option>
                            <option value='5f4a8f6e1799ce001724009b'>Roma Est</option>
                            <option value='6063446f183576001789d5f2'>Elnòs</option>
                            <option value='60f84cb4e7df8715a429daf7'>Porte di Roma</option>
                            <option value='620192aa25cc814c13fe7942'>Porte di Roma esterno</option>
                            <option value='61a54985b5deb87d70929d81'>Megalò</option>
                            <option value='624d748775142ceb63718be0'>Centro Sarca</option>
                            <option value='5f46efc690d3000017412109'>CAROSELLO</option>
                            <option value='62b1beee0b5fd17fb3692cb2'>CUORE ADRIATICO</option>
                            <option value='5f4a3f691799ce0017240069'>FIORDALISO</option>
                            <option value='62b1be780b5fd17fb3692cb1'>LE GRU</option>
                            <option value='62b1befc0b5fd17fb3692cb3'>LE TERRAZZE</option>
                            <option value='62ba0d9e67b1e1b33951c620'>LEONE SHOPPING CENTER</option>
                            <option value='60db547cefe1b7079c418221'>ORIO CENTER</option>
                            <option value='62ba0d8c67b1e1b33951c61f'>PORTE DI MESTRE</option>
                            <option value='62ba0d7967b1e1b33951c61e'>ROMAGNA SHOPPING VALLEY</option>
              
                          </select>
                        </div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <Button
                        onClick={()=>{Filtra()}}
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Filtra
                      </Button>
                      {showF ? (
                        <JsonToExcel
                        data={xlDate}
                        className={className}
                        filename={'report Feedback '+new Date().toDateString()}
                        fields={fieldsTabella}
                        style={{padding: 8, background: '#34c38f', border: 'none', color: 'white', borderRadius: 6,marginLeft:20}}
                        text={'Scarica exel'}
                      />
                      ) : (
                        showW ? (
                          <Button
                        style={{marginLeft:20}}
                        onClick={()=>{Export()}}
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Genera exel
                      </Button> 
                        ) : null
                        
                      )
                      }
                    </Col>
                    <Col>
                    <Col>
                    

                    </Col>
                    <Col></Col>
                    
                    </Col>
                    
                  </Row>
                  
                  <br></br>  
                  {loading ? (
                    <div style={{textAlign:'center',padding:30}}>
                      <Spinner color="secondary" />
                  </div>
                  ) : (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>Record</Th>
                            <Th data-priority="1">DATA</Th>
                            <Th data-priority="3">Staff</Th>
                            <Th data-priority="1">POP UP</Th>
                            <Th data-priority="3">#TRIAL</Th>
                            <Th data-priority="3">GUSTO STICK</Th>
                            <Th data-priority="6">GENDER</Th>
                            <Th data-priority="6">ETA</Th>
                            <Th data-priority="6">COSA FUMI</Th>
                            <Th data-priority="3">BRAND</Th>
                            <Th data-priority="6">DOVE CI HAI CONOSCIUTO</Th>
                            <Th data-priority="6">GADGET</Th>
                            <Th data-priority="6">ACQUISTO</Th>
                            <Th data-priority="6">FEEDBACK TRIAL</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {items.map((i,x)=>{
                            return(
                              <Tr key={'sales_'+x}>
                                <Th>
                                   <span className="co-name">{metaInfo.page > 1 ? (((metaInfo.page-1)*metaInfo.limit)+(x+1)) : x+1  }</span>
                                </Th>
                                <Td>{i.giornoString}</Td>
                                <Td>{i.operatore ? i.operatore.cognome+' '+i.operatore.nome : 'no' }</Td>
                                <Td>{i.popUp.titolo}</Td>
                                <Td>{i.trial}</Td>
                                <Td>{i.gusto}</Td>
                                <Td>{i.gender}</Td>
                                <Td>{i.age}</Td>
                                <Td>{i.cfumi}</Td>
                                <Td>{i.brand}</Td>
                                <Td>{i.dcac}</Td>
                                <Td>{i.gadget ? 'si' : 'no'}</Td>
                                <Td>{i.acquisto ? 'si' : 'no'}</Td>
                                <Td>{i.feedback}</Td>
                                
                              </Tr>
                            )
                          })}
                          
                          
                        </Tbody>
                      </Table>
                    </div>
                  </div> )}
                </CardBody>
              </Card>
            </Col>
            
            <Col lg="12">
              
              
            </Col>
            <div style={{ display: 'contents'}}>
            <Pagination style={{display: 'contents'}} className="paginationx pagination-rounded justify-content-center mt-2 mb-5">
              <Pagine />
            </Pagination>
               
              </div>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResponsiveTables
