import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"



const CardUser = () => {
  const [imageProfile,setImageProfile] = useState(null)
  const [nome,setNome] = useState('')
  useEffect(() => {
    
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      console.log('vediamo oggg');
      console.log(obj);
      setImageProfile(obj.photoURL)
      setNome(obj.email)
    }
  }, [])
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <Media>
                    <div className="mr-3">
                      <img
                        src={imageProfile}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media className="align-self-center" body>
                      <div className="text-muted">
                        <p className="mb-2">Bentornato/a nella Dashboard </p>
                        <h5 className="mb-1">{nome}</h5>
                        <p className="mb-0"></p>
                      </div>
                    </Media>
                  </Media>
                </Col>

                {/*<Col lg="4" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Total Projects
                          </p>
                          <h5 className="mb-0">48</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Projects
                          </p>
                          <h5 className="mb-0">40</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Clients
                          </p>
                          <h5 className="mb-0">18</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>*/}

                <Col lg="4" className="d-none d-lg-block">
                  <div className="clearfix mt-4 mt-lg-0">
                    {/*<Dropdown
                      isOpen={this.state.settings_Menu}
                      toggle={this.toggleSettings}
                      className="float-right"
                    >
                      <DropdownToggle
                        tag="button"
                        className="btn btn-primary"
                      >
                        <i className="bx bxs-cog align-middle mr-1"/>{" "}
                        Setting
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem href="#">Action</DropdownItem>
                        <DropdownItem href="#">Another action</DropdownItem>
                        <DropdownItem href="#">Something else</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>*/}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default CardUser


