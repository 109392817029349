import { useTable } from 'react-table'
import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import { _testTabellaTurniApi,_updateItemTurno,_resetModifiche } from "../../../helpers/server_helper";
import {
  Card,
  CardBody,
  Media,
  UncontrolledTooltip,
} from "reactstrap"
//import { ViewApi } from '@fullcalendar/react';
import { dateToString,timeToString } from "../../../helpers/formatting";
//import { Alert } from 'bootstrap';
import ItemCard from "./cardItem";
import {
  JsonToExcel
} from 'react-json-excel';



var _ = require('lodash');



const className = 'class-name-for-style',
  filename = 'Excel-file',
  fields = {
    "indexss": "Indexsxss",
    "guidss": "GUIDsss"
  },
  style = {
    padding: "5px"
  },
  datax = [
    { index: 0, guid: 'asdf231234'},
    { index: 1, guid: 'wetr2343af'}
  ],
  text = "scarica csv";

const Styles = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    border: 1px solid #151f29;

    tr {
      border: 1px solid #ddd;
      color:'white';
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th{
      text-align: center;
      background:rgba(255, 255, 255, 0.12);
    }
    td:nth-child(1) {  
      background: #fff;
      position: sticky; 
      left: 0;
      margin-left: -100px;
      z-index: 2;
    }
    th,
    td {
      color: black;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;

      :last-child {
        border-right: 0;
      }
    }
    tr th {
      background: #292f42;
      color: white;
      position: sticky;
    }
    th {
      background: white;
      position: sticky;
      top: 0; /* Don't forget this, required for the stickiness */
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    
      
    }
    thead  { position: sticky; top: 0; z-index: 3; }
  }
`


function Table({ columns, data,modificati }) {
  // Use the state and functions returned from useTable to build your UI


  const modificatotemp = (row) =>{
    
    let pos = modificati.map(function(e) { return e.staff; }).indexOf(row.values.nome.id);
    console.log(pos);
    if(pos > -1){
      return true
    }else{
      return false
    }

  }
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })




  function bgd(x){
    switch (x) {
      case 'interessanti':
        return 'rgb(246, 185, 59)';
      case 'selezionati':
        return 'rgb(0, 107, 118)';
      default:
        return 'rgb(190, 211, 243)'
    }
  }
  
  function getDateD(x){
    let ora =  new Date(x).getHours();
    let minuti = new Date(x).getMinutes();
    if(ora < 10){
      ora = 0+''+ora;
    }
    if(minuti < 10){
      minuti = 0+''+minuti;
    }
  
    let ck = ora+':'+minuti
    return ck
  }
  
  function renderCard(cell,altro){
    

    const cambiovalue = (value)=>{
      let obj = {
        id:cell.idItem
      }
      obj.position = value
      _updateItemTurno(obj,(risultato)=>{
        alert('ok')
        cell.status = value
      })
    } 

      //console.log('mostrpo cell');
      //console.log(cell);

      let colorStaur = '#74788d'
      switch (cell.status) {
        case "candidati":
          colorStaur = '#74788d'
          break;
        case "selezionati":
          colorStaur = '#34c38f'
          break;
        case "interessanti":
          colorStaur = '#f1b44c'
          break;
      
        default:
          break;
      }

      switch (cell.type) {
        case 'text':
          return (<div>
                    <p style={{color:'black', fontWeight:'300',fontSize:16,padding:10,marginTop:10, textTransform: 'uppercase'}}>{cell.text}</p>
                    {/*<div style={{display:'flex'}}>
                    <div style={{width:20,height:20,borderRadius:10, cursor:'pointer',background:'rgb(190, 211, 243)'}} onClick={()=>provaAction(cell,'candidati')}/>
                    <div style={{width:20,height:20,borderRadius:10, cursor:'pointer',background:'rgb(246, 185, 59)'}} onClick={()=>provaAction(cell,'interessanti')}/>
                    <div style={{width:20,height:20,borderRadius:10, cursor:'pointer',background:'rgb(0, 107, 118)'}} onClick={()=>provaAction(cell,'selezionati')}/>
                    </div>*/}
                  </div>
                );
        case 'card':
          return (
           
          <Card>
            <CardBody  id={'cardId'+cell.user[0]._id}  style={{backgroundColor:colorStaur}}>
              <Media> 
                <p>{cell.idItem}</p>
               <a href={'/user-profile/'+cell.user[0]._id}>
                <img
                    src={cell.user[0].img_profilo}
                    className="rounded-circle avatar-xs m-1"
                    alt=""
                  />
              </a> 
              
                

                {/*<Media style={{padding:10}} className="overflow-hidden" body>
                  <h5 style={{color:'white'}} className="text-truncate font-size-15">
                    {cell.user[0].nome} {cell.user[0].cognome}
                  </h5>
                  <p className=" mb-4" style={{color:'white'}} ></p>
                  <li style={{color:'white',fontSize:17}} className="list-inline-item mr-3" id="dueDate">
                  {" CkIn : "}{timeToString(cell.ckIn)}
                  
                </li>
                <li style={{color:'white',fontSize:17}} className="list-inline-item mr-3" id="comments">
                  {" Ckout : "}
                  {timeToString(cell.ckOut)}
                  
                </li>
                  <div className="team">
                    
                  </div>
                </Media>*/}
              </Media>
              
              {cell.ckIn ? (
                <React.Fragment>
                   <li style={{color:'white',fontSize:17}} className="list-inline-item mr-3" id="dueDate">
                {" CkIn : "}{timeToString(cell.ckIn)}
                
              </li>
              <li style={{color:'white',fontSize:17}} className="list-inline-item mr-3" id="comments">
                {" Ckout : "}
                {timeToString(cell.ckOut)}
                
              </li>
                </React.Fragment>
               
              ) : null}

              <UncontrolledTooltip placement="top" target={'cardId'+cell.user[0]._id}>
                   {cell.user[0].nome} {cell.user[0].cognome}
                  </UncontrolledTooltip>
            </CardBody>
            <div className="px-4 py-3 border-top">
              <select onChange={(e)=>cambiovalue(e.target.value)}>
                <option  selected={cell.status == "selezionati"} value="selezionati">Selezionato</option>
                {/*<option  selected={cell.status == "interessanti"} value="interessanti">Interessante</option>*/}
                <option selected={cell.status == "candidati"} value="candidati">Candidato</option>
              </select>
            </div>
          </Card>
            /*<div onClick={()=>console.log('ppp')} style={{background:bgd(cell.status),padding: 0,borderRadius: 5,cursor:'pointer'}}>
              <div style={{display:'flex'}}><div style={{width:40,height:40}}>{cell.user[0].img_profilo ? <img src={cell.user[0].img_profilo} alt='.' style={{width: '100%', height: '100%', borderTopLeftRadius: 5, objectFit: 'cover', background: 'rgb(246, 185, 59)'}}/> : null}</div>
              <p style={{marginLeft:10,fontWeight:'700',color:'#122436'}}>{cell.user[0].cognome} {cell.user[0].nome}</p></div>
              {cell.status === 'selezionati' ? (
                <div style={{display:'',padding:10,background:'#0099a9',color: 'black',textAlign: 'center'}}><p style={{marginRight:5}}>check in: <strong style={{fontWeight:800}}>{cell.ckIn ? getDateD(cell.ckIn) : null}</strong></p>
                <p>check out: <strong style={{fontWeight:800}}>{cell.ckOut ? getDateD(cell.ckOut) : null}</strong></p></div>
              ) : null }
            </div>*/
          );
        default:
          return <p>...</p>
      }
  
    }

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          console.log('vediamo le roww');
          console.log(row);
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
            {row.cells.map(cell => {
              console.log('celle');
              console.log(cell);
              let altro = {turno: (cell.column ? cell.column.Header : null),giorno: (cell.column ? cell.column.parent.Header : null)};
              return <td style={{backgroundColor:modificatotemp(row) ? '#f1b44c5c' : null}} {...cell.getCellProps()}>{cell.value ?  <ItemCard  cell={cell.value} altro={altro}  /> : <div style={{textAlign:'center'}}><button style={{border:'none',padding:10,fontWeight:'bold'}} onClick={()=>console.log('id turno'+cell.column.id+' id user '+cell.row.values.nome.id)} >Add</button></div>}</td>
            })}
          </tr>
          )
        })}
      </tbody>
    </table>
  )
}


function App({ giorni,id ,arrayTurni,arrayUser,banned,modificati}){


  const [collonne, setcolonne] = useState([])
  const [data, setData] = useState([])
  const [selected, setSelected] = useState(false)

  const [testvolonne,settestvolonne] = useState({
    "indexss": "Indexsxss",
    "guidss": "GUIDsss"
  })
  const [exportDatat,setExportData] =  useState([])
  const [generato,setGenera] = useState(false)




 


  const creaStrutturaSelect = () =>{


    let test = [];
    
    test.push({
      Header:'Giorni',
      columns: [{
        Header:'Turni',
        accessor:'nome',
      }]
    })

    //console.log('qui vediamo i giorni');
    //console.log(giorni);

    giorni.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return   new Date(a.date) - new Date(b.date);
    });

    //qui cosa faccio?? creo le colonne
    giorni.forEach((item, i) => {
      let giorni = {};
      giorni.Header = dateToString(item.date)
      
      let filterArray = _.filter(arrayTurni, { 'idDate': item.idDate });
      let columns = [];
      filterArray.forEach((turno, ii) => {
        columns.push({
            Header: ''+turno.start.getHours()+':'+(turno.start.getMinutes() < 10 ? '0'+turno.start.getMinutes() : turno.start.getMinutes()  )+' '+turno.end.getHours()+':'+(turno.end.getMinutes() < 10 ? '0'+turno.end.getMinutes() : turno.end.getMinutes()  ),
            accessor: turno.id
          });
      });
      giorni.columns = columns;
      test.push(giorni);
    });

    //console.log('header');
    //console.log(test);
    //header

    

    setcolonne(test)

    
    _testTabellaTurniApi(id,true,(results)=>{
      console.log('jdjdjdjdj');
      //console.log('qui vediamo cosa risop');
      //console.log(results);

      
      //results.push({nome:{id:'noId'}})
      let newS = []
      results.map((x,i)=>{
        //console.log(x);
      })

      console.log('fino qui ok ');
      console.log(results);
      setData(results)
     
    })

}

const mapOrder = (array, order) =>{
  console.log('eccomiu');
  console.log(array);
  array.sort( function (a, b) {
    
    console.log('bohhh');
    console.log(a.nome.id);
    
    var A = a.nome.id, B = b.nome.id;
    //console.log('_______');
    //console.log(A);
    //console.log(B);
    //console.log('_____');
    
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
    return array
  });
  
  return array;
};

  const creaStruttura = () =>{


    let test = [];
    
    test.push({
      Header:'Giorni',
      columns: [{
        Header:'Turni',
        accessor:'nome',
      }]
    })

    //console.log('qui vediamo i giorni');
    //console.log(giorni);

    giorni.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return   new Date(a.date) - new Date(b.date);
    });

    //qui cosa faccio?? creo le colonne
    giorni.forEach((item, i) => {
      let giorni = {};
      giorni.Header = dateToString(item.date)
      
      let filterArray = _.filter(arrayTurni, { 'idDate': item.idDate });
      let columns = [];
      filterArray.forEach((turno, ii) => {
        columns.push({
            Header: ''+turno.start.getHours()+':'+(turno.start.getMinutes() < 10 ? '0'+turno.start.getMinutes() : turno.start.getMinutes()  )+' '+turno.end.getHours()+':'+(turno.end.getMinutes() < 10 ? '0'+turno.end.getMinutes() : turno.end.getMinutes()  ),
            accessor: turno.id
          });
      });
      giorni.columns = columns;
      test.push(giorni);
    });

    //console.log('header');
    //console.log(test);
    //header

    
    setcolonne(test)

    
    _testTabellaTurniApi(id,false,(results)=>{
      //console.log('qui vediamo cosa risopx');
      //console.log(results);
      //console.log('array order');
      //console.log(arrayUser);
      

      let orderId = []
      arrayUser.forEach(element => {
        console.log('quiii vedo');
        console.log(element._id);
        orderId.push(element._id)
      });

      
      //orderId.reverse()
      //console.log(orderId);
      //results.push({nome:{id:'noId'}})

      console.log('qui faccio ordine');
      console.log(results);

      let newwArrr = mapOrder(results,orderId)

      
      console.log('dati da usaeew');
      
      console.log(results);

      console.log('bannes');
      console.log(banned);
      console.log('modificati pass');
      console.log(modificati);

      let noBanned = []

      
      results.map((elem,i)=>{
        let indexBan =  banned.indexOf(elem.nome.id)
        if(indexBan < 0){
          noBanned.push(elem)
        }
      })
      //pos = myArray.map(function(e) { return e.hello; }).indexOf('stevie');

      

      

      setData(noBanned)
      console.log('no bannes');
      console.log(noBanned);
     
    })

   /* _testTabellaTurniApiOld(id,false,(results)=>{
      //console.log('qui vediamo cosa risopx');
      //console.log(results);
      //console.log('array order');
      //console.log(arrayUser);
      

      let orderId = []
      arrayUser.forEach(element => {
        orderId.push(element._id)
      });
      //orderId.reverse()
      //console.log(orderId);
      //results.push({nome:{id:'noId'}})
      
      let newwArrr = mapOrder(results,orderId)

      
      console.log('dati da usaeew');
      console.log(results);

      

      setData(results)
     
    })*/

}

  useEffect(() => {

    //console.log('mostro i turni');
    //console.log(arrayTurni);
    if (id) {

      creaStruttura()
      
    }
  }, [arrayTurni])


  const colonneB = () =>{
    let colonneNew = {}
    collonne.map((x,i)=>{
      
      let newK = x.columns[0].accessor
      let keyTo = x.Header.replace('/', '-');
      keyTo = keyTo.replace('/', '-');
      colonneNew[newK] = x.Header
    })
    return colonneNew
  }

  const colonneBNewmulty = () =>{
    console.log('colonne multy');
   
    let colonneNew = {}
    collonne.map((x,i)=>{
      x.columns.map((c,i)=>{
        console.log(c);
        let newK = c.accessor
        let keyTo = x.Header.replace('/', '-');
        keyTo = keyTo.replace('/', '-');
        keyTo = keyTo+'('+c.Header+')'
        colonneNew[newK] = keyTo
      
      })
      
      /*let newK = x.columns[0].accessor
      console.log('newK');
      console.log(x.columns[0]);
      console.log(newK);
      let keyTo = x.Header.replace('/', '-');
      keyTo = keyTo.replace('/', '-');
      colonneNew[newK] = x.Header*/
      
    })

    console.log('vedo fine');
      console.log(colonneNew);

    return colonneNew
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'First Name',
            accessor: 'firstName',
          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'Age',
            accessor: 'age',
          },
          {
            Header: 'Visits',
            accessor: 'visits',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Profile Progress',
            accessor: 'progress',
          },
        ],
      },
    ],
    []
  )



  const Boohnew = () => {
    //console.log(data);
    //console.log('data');

    let arrayUse = []
    console.log('vediamo i data');
    console.log(data);
    data.map((item,index)=>{
      let objj = {}
      Object.keys(item).forEach(key => {
        
        console.log('keys vediamo');
        console.log(key);
        if(key == 'nome'){
          objj.nome = item[key].text
        }else{
          objj[key] = '✔️'
        }
      });
      //console.log(objj);
      arrayUse.push(objj)
      
    })
    

    console.log('mostra colonna ');
    console.log(collonne);
    console.log('array');
    console.log(arrayUse);


    let oooo = colonneBNewmulty(collonne)
   
    settestvolonne(oooo)
    setExportData(arrayUse)
    setGenera(true)
  }

  const Booh = () => {
    //console.log(data);
    //console.log('data');

    let arrayUse = []
    console.log('vediamo i data');
    console.log(data);
    data.map((item,index)=>{
      let objj = {}
      Object.keys(item).forEach(key => {
        
        console.log('keys vediamo');
        console.log(key);
        if(key == 'nome'){
          objj.nome = item[key].text
        }else{
          objj[key] = '✔️'
        }
      });
      //console.log(objj);
      arrayUse.push(objj)
      
    })
    

    console.log('mostra colonna ');
    console.log(collonne);

    let oooo = colonneB(collonne)
   
    settestvolonne(oooo)
    setExportData(arrayUse)
    setGenera(true)
  }


  const azzera = () =>{

    console.log(id);
    
    _resetModifiche(id,(resu)=>{
      console.log(resu);
      
    })

  }

  
  

  return (
    <Card>
    <CardBody style={{overflow: 'scroll'}}>
    <Styles>
      
      <button onClick={()=>creaStrutturaSelect()} style={{marginRight:10,marginBottom:20}} class="waves-effect waves-light btn btn-primary">Selezionati</button> 
      {/*<button  style={{marginRight:10,marginBottom:20}} class="waves-effect waves-light btn btn-secondary" onClick={()=>Booh()}>Genera csv</button>*/}
      <button  style={{marginRight:10,marginBottom:20}} class="waves-effect waves-light btn btn-secondary" onClick={()=>Boohnew()}>Genera csv turni</button>

      <br/>
      {modificati.length > 0 ? (
        <button  style={{marginRight:10,marginBottom:20,backgroundColor:'orange'}} class="waves-effect waves-light btn" onClick={()=>azzera()}>Ok visto le modifiche</button>

      ) : null}
      
      {generato ? (
       <JsonToExcel
        data={exportDatat}
        className={'waves-effect waves-light btn btn-primary'}
        filename={filename}
        fields={testvolonne}
        style={{marginRight:10,marginBottom:20,backgroundColor: '#e59e55',borderColor: '#e59e55'}}
        text={text}></JsonToExcel>
      ) : null}
      
      <Table modificati={modificati} columns={collonne} data={data} />
    </Styles>
    </CardBody>
    </Card>
    
  )
}

export default App

