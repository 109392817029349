import React, { useEffect, useState } from "react"
import PropTypes, { element } from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { indexOf, map, set } from "lodash"
import {_getStaffApi ,_getCategorieInterne ,UpdateInfoStaff,_createNotifica} from "../../../helpers/server_helper"
import {dateToString,dataCf,comuneCf,testNome ,levelDetect ,numberFormater} from "../../../helpers/formatting"
import Select from "react-select"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import * as fs from "fs";
import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, Header,Footer,HeadingLevel,AlignmentType,TextRun,Table, TableCell, TableRow, VerticalAlign, TextDirection,WidthType,SectionType,BorderStyle } from "docx";
import { jsPDF } from "jspdf";
import { Page, Text as TextP, View, Document as DocumentP, StyleSheet ,pdf,Image  } from '@react-pdf/renderer';
import bgImg from "./bgimg.png"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Row,
  Table as Tabbb,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Modal
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import mini card widgets
import MiniCards from "./mini-card"
import MiniCardComp from "./mini-card-comp"
import BancaCard from "./banca-card"
import InstaCard from "./card-insta"
import InputEdit from "./inputNewEdit";

//Import Images
import profile1 from "assets/images/profile-img.png"

// import charts
import ApexRevenue from "../ApexRevenue"
import { getUserProfile } from "store/actions"
import images from "assets/images"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import projectColumns from "./projectColumns"

import { render, Document as DocumentYo , Text } from 'redocx'



const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

const sessologg = (x) =>{
  if(x == 'M'){
    return 'Maschile'
  }else{
    return 'Femminile'
  }
}


const fixInstagram = (ins) =>{
  let tetx = ins 
  if(ins[0] == '@'){
    tetx = tetx.substring(1);
  }
  if(ins == 'NO'){
    tetx = ''
  }
  return tetx
}

const tipologg = (x) =>{
  if(x == 'CI'){
    return 'Carta di identià'
  }else if(x == 'PA'){
    return 'Passaporto'
  }else{
    return x
  }
}

const legender = (v) =>{
  const valori = [
    {
      "label": "Afghanistan",
      "value": "AF"
    },
    {
      "label": "Ã…land Islands",
      "value": "AX"
    },
    {
      "label": "Albania",
      "value": "AL"
    },
    {
      "label": "Algeria",
      "value": "DZ"
    },
    {
      "label": "American Samoa",
      "value": "AS"
    },
    {
      "label": "Andorra",
      "value": "AD"
    },
    {
      "label": "Angola",
      "value": "AO"
    },
    {
      "label": "Anguilla",
      "value": "AI"
    },
    {
      "label": "Antarctica",
      "value": "AQ"
    },
    {
      "label": "Antigua and Barbuda",
      "value": "AG"
    },
    {
      "label": "Argentina",
      "value": "AR"
    },
    {
      "label": "Armenia",
      "value": "AM"
    },
    {
      "label": "Aruba",
      "value": "AW"
    },
    {
      "label": "Australia",
      "value": "AU"
    },
    {
      "label": "Austria",
      "value": "AT"
    },
    {
      "label": "Azerbaijan",
      "value": "AZ"
    },
    {
      "label": "Bahamas",
      "value": "BS"
    },
    {
      "label": "Bahrain",
      "value": "BH"
    },
    {
      "label": "Bangladesh",
      "value": "BD"
    },
    {
      "label": "Barbados",
      "value": "BB"
    },
    {
      "label": "Belarus",
      "value": "BY"
    },
    {
      "label": "Belgium",
      "value": "BE"
    },
    {
      "label": "Belize",
      "value": "BZ"
    },
    {
      "label": "Benin",
      "value": "BJ"
    },
    {
      "label": "Bermuda",
      "value": "BM"
    },
    {
      "label": "Bhutan",
      "value": "BT"
    },
    {
      "label": "Bolivia, Plurinational State of",
      "value": "BO"
    },
    {
      "label": "Bonaire, Sint Eustatius and Saba",
      "value": "BQ"
    },
    {
      "label": "Bosnia and Herzegovina",
      "value": "BA"
    },
    {
      "label": "Botswana",
      "value": "BW"
    },
    {
      "label": "Bouvet Island",
      "value": "BV"
    },
    {
      "label": "Brazil",
      "value": "BR"
    },
    {
      "label": "British Indian Ocean Territory",
      "value": "IO"
    },
    {
      "label": "Brunei Darussalam",
      "value": "BN"
    },
    {
      "label": "Bulgaria",
      "value": "BG"
    },
    {
      "label": "Burkina Faso",
      "value": "BF"
    },
    {
      "label": "Burundi",
      "value": "BI"
    },
    {
      "label": "Cambodia",
      "value": "KH"
    },
    {
      "label": "Cameroon",
      "value": "CM"
    },
    {
      "label": "Canada",
      "value": "CA"
    },
    {
      "label": "Cape Verde",
      "value": "CV"
    },
    {
      "label": "Cayman Islands",
      "value": "KY"
    },
    {
      "label": "Central African Republic",
      "value": "CF"
    },
    {
      "label": "Chad",
      "value": "TD"
    },
    {
      "label": "Chile",
      "value": "CL"
    },
    {
      "label": "China",
      "value": "CN"
    },
    {
      "label": "Christmas Island",
      "value": "CX"
    },
    {
      "label": "Cocos (Keeling) Islands",
      "value": "CC"
    },
    {
      "label": "Colombia",
      "value": "CO"
    },
    {
      "label": "Comoros",
      "value": "KM"
    },
    {
      "label": "Congo",
      "value": "CG"
    },
    {
      "label": "Congo, the Democratic Republic of the",
      "value": "CD"
    },
    {
      "label": "Cook Islands",
      "value": "CK"
    },
    {
      "label": "Costa Rica",
      "value": "CR"
    },
    {
      "label": "CÃ´te d'Ivoire",
      "value": "CI"
    },
    {
      "label": "Croatia",
      "value": "HR"
    },
    {
      "label": "Cuba",
      "value": "CU"
    },
    {
      "label": "CuraÃ§ao",
      "value": "CW"
    },
    {
      "label": "Cyprus",
      "value": "CY"
    },
    {
      "label": "Czech Republic",
      "value": "CZ"
    },
    {
      "label": "Denmark",
      "value": "DK"
    },
    {
      "label": "Djibouti",
      "value": "DJ"
    },
    {
      "label": "Dominica",
      "value": "DM"
    },
    {
      "label": "Dominican Republic",
      "value": "DO"
    },
    {
      "label": "Ecuador",
      "value": "EC"
    },
    {
      "label": "Egypt",
      "value": "EG"
    },
    {
      "label": "El Salvador",
      "value": "SV"
    },
    {
      "label": "Equatorial Guinea",
      "value": "GQ"
    },
    {
      "label": "Eritrea",
      "value": "ER"
    },
    {
      "label": "Estonia",
      "value": "EE"
    },
    {
      "label": "Ethiopia",
      "value": "ET"
    },
    {
      "label": "Falkland Islands (Malvinas)",
      "value": "FK"
    },
    {
      "label": "Faroe Islands",
      "value": "FO"
    },
    {
      "label": "Fiji",
      "value": "FJ"
    },
    {
      "label": "Finland",
      "value": "FI"
    },
    {
      "label": "France",
      "value": "FR"
    },
    {
      "label": "French Guiana",
      "value": "GF"
    },
    {
      "label": "French Polynesia",
      "value": "PF"
    },
    {
      "label": "French Southern Territories",
      "value": "TF"
    },
    {
      "label": "Gabon",
      "value": "GA"
    },
    {
      "label": "Gambia",
      "value": "GM"
    },
    {
      "label": "Georgia",
      "value": "GE"
    },
    {
      "label": "Germany",
      "value": "DE"
    },
    {
      "label": "Ghana",
      "value": "GH"
    },
    {
      "label": "Gibraltar",
      "value": "GI"
    },
    {
      "label": "Greece",
      "value": "GR"
    },
    {
      "label": "Greenland",
      "value": "GL"
    },
    {
      "label": "Grenada",
      "value": "GD"
    },
    {
      "label": "Guadeloupe",
      "value": "GP"
    },
    {
      "label": "Guam",
      "value": "GU"
    },
    {
      "label": "Guatemala",
      "value": "GT"
    },
    {
      "label": "Guernsey",
      "value": "GG"
    },
    {
      "label": "Guinea",
      "value": "GN"
    },
    {
      "label": "Guinea-Bissau",
      "value": "GW"
    },
    {
      "label": "Guyana",
      "value": "GY"
    },
    {
      "label": "Haiti",
      "value": "HT"
    },
    {
      "label": "Heard Island and McDonald Islands",
      "value": "HM"
    },
    {
      "label": "Holy See (Vatican City State)",
      "value": "VA"
    },
    {
      "label": "Honduras",
      "value": "HN"
    },
    {
      "label": "Hong Kong",
      "value": "HK"
    },
    {
      "label": "Hungary",
      "value": "HU"
    },
    {
      "label": "Iceland",
      "value": "IS"
    },
    {
      "label": "India",
      "value": "IN"
    },
    {
      "label": "Indonesia",
      "value": "ID"
    },
    {
      "label": "Iran, Islamic Republic of",
      "value": "IR"
    },
    {
      "label": "Iraq",
      "value": "IQ"
    },
    {
      "label": "Ireland",
      "value": "IE"
    },
    {
      "label": "Isle of Man",
      "value": "IM"
    },
    {
      "label": "Israel",
      "value": "IL"
    },
    {
      "label": "Italia",
      "value": "IT"
    },
    {
      "label": "Jamaica",
      "value": "JM"
    },
    {
      "label": "Japan",
      "value": "JP"
    },
    {
      "label": "Jersey",
      "value": "JE"
    },
    {
      "label": "Jordan",
      "value": "JO"
    },
    {
      "label": "Kazakhstan",
      "value": "KZ"
    },
    {
      "label": "Kenya",
      "value": "KE"
    },
    {
      "label": "Kiribati",
      "value": "KI"
    },
    {
      "label": "Korea, Democratic People's Republic of",
      "value": "KP"
    },
    {
      "label": "Korea, Republic of",
      "value": "KR"
    },
    {
      "label": "Kuwait",
      "value": "KW"
    },
    {
      "label": "Kyrgyzstan",
      "value": "KG"
    },
    {
      "label": "Lao People's Democratic Republic",
      "value": "LA"
    },
    {
      "label": "Latvia",
      "value": "LV"
    },
    {
      "label": "Lebanon",
      "value": "LB"
    },
    {
      "label": "Lesotho",
      "value": "LS"
    },
    {
      "label": "Liberia",
      "value": "LR"
    },
    {
      "label": "Libya",
      "value": "LY"
    },
    {
      "label": "Liechtenstein",
      "value": "LI"
    },
    {
      "label": "Lithuania",
      "value": "LT"
    },
    {
      "label": "Luxembourg",
      "value": "LU"
    },
    {
      "label": "Macao",
      "value": "MO"
    },
    {
      "label": "Macedonia, the Former Yugoslav Republic of",
      "value": "MK"
    },
    {
      "label": "Madagascar",
      "value": "MG"
    },
    {
      "label": "Malawi",
      "value": "MW"
    },
    {
      "label": "Malaysia",
      "value": "MY"
    },
    {
      "label": "Maldives",
      "value": "MV"
    },
    {
      "label": "Mali",
      "value": "ML"
    },
    {
      "label": "Malta",
      "value": "MT"
    },
    {
      "label": "Marshall Islands",
      "value": "MH"
    },
    {
      "label": "Martinique",
      "value": "MQ"
    },
    {
      "label": "Mauritania",
      "value": "MR"
    },
    {
      "label": "Mauritius",
      "value": "MU"
    },
    {
      "label": "Mayotte",
      "value": "YT"
    },
    {
      "label": "Mexico",
      "value": "MX"
    },
    {
      "label": "Micronesia, Federated States of",
      "value": "FM"
    },
    {
      "label": "Moldova, Republic of",
      "value": "MD"
    },
    {
      "label": "Monaco",
      "value": "MC"
    },
    {
      "label": "Mongolia",
      "value": "MN"
    },
    {
      "label": "Montenegro",
      "value": "ME"
    },
    {
      "label": "Montserrat",
      "value": "MS"
    },
    {
      "label": "Morocco",
      "value": "MA"
    },
    {
      "label": "Mozambique",
      "value": "MZ"
    },
    {
      "label": "Myanmar",
      "value": "MM"
    },
    {
      "label": "Namibia",
      "value": "NA"
    },
    {
      "label": "Nauru",
      "value": "NR"
    },
    {
      "label": "Nepal",
      "value": "NP"
    },
    {
      "label": "Netherlands",
      "value": "NL"
    },
    {
      "label": "New Caledonia",
      "value": "NC"
    },
    {
      "label": "New Zealand",
      "value": "NZ"
    },
    {
      "label": "Nicaragua",
      "value": "NI"
    },
    {
      "label": "Niger",
      "value": "NE"
    },
    {
      "label": "Nigeria",
      "value": "NG"
    },
    {
      "label": "Niue",
      "value": "NU"
    },
    {
      "label": "Norfolk Island",
      "value": "NF"
    },
    {
      "label": "Northern Mariana Islands",
      "value": "MP"
    },
    {
      "label": "Norway",
      "value": "NO"
    },
    {
      "label": "Oman",
      "value": "OM"
    },
    {
      "label": "Pakistan",
      "value": "PK"
    },
    {
      "label": "Palau",
      "value": "PW"
    },
    {
      "label": "Palestine, State of",
      "value": "PS"
    },
    {
      "label": "Panama",
      "value": "PA"
    },
    {
      "label": "Papua New Guinea",
      "value": "PG"
    },
    {
      "label": "Paraguay",
      "value": "PY"
    },
    {
      "label": "Peru",
      "value": "PE"
    },
    {
      "label": "Philippines",
      "value": "PH"
    },
    {
      "label": "Pitcairn",
      "value": "PN"
    },
    {
      "label": "Poland",
      "value": "PL"
    },
    {
      "label": "Portugal",
      "value": "PT"
    },
    {
      "label": "Puerto Rico",
      "value": "PR"
    },
    {
      "label": "Qatar",
      "value": "QA"
    },
    {
      "label": "RÃ©union",
      "value": "RE"
    },
    {
      "label": "Romania",
      "value": "RO"
    },
    {
      "label": "Russian Federation",
      "value": "RU"
    },
    {
      "label": "Rwanda",
      "value": "RW"
    },
    {
      "label": "Saint BarthÃ©lemy",
      "value": "BL"
    },
    {
      "label": "Saint Helena, Ascension and Tristan da Cunha",
      "value": "SH"
    },
    {
      "label": "Saint Kitts and Nevis",
      "value": "KN"
    },
    {
      "label": "Saint Lucia",
      "value": "LC"
    },
    {
      "label": "Saint Martin (French part)",
      "value": "MF"
    },
    {
      "label": "Saint Pierre and Miquelon",
      "value": "PM"
    },
    {
      "label": "Saint Vincent and the Grenadines",
      "value": "VC"
    },
    {
      "label": "Samoa",
      "value": "WS"
    },
    {
      "label": "San Marino",
      "value": "SM"
    },
    {
      "label": "Sao Tome and Principe",
      "value": "ST"
    },
    {
      "label": "Saudi Arabia",
      "value": "SA"
    },
    {
      "label": "Senegal",
      "value": "SN"
    },
    {
      "label": "Serbia",
      "value": "RS"
    },
    {
      "label": "Seychelles",
      "value": "SC"
    },
    {
      "label": "Sierra Leone",
      "value": "SL"
    },
    {
      "label": "Singapore",
      "value": "SG"
    },
    {
      "label": "Sint Maarten (Dutch part)",
      "value": "SX"
    },
    {
      "label": "Slovakia",
      "value": "SK"
    },
    {
      "label": "Slovenia",
      "value": "SI"
    },
    {
      "label": "Solomon Islands",
      "value": "SB"
    },
    {
      "label": "Somalia",
      "value": "SO"
    },
    {
      "label": "South Africa",
      "value": "ZA"
    },
    {
      "label": "South Georgia and the South Sandwich Islands",
      "value": "GS"
    },
    {
      "label": "South Sudan",
      "value": "SS"
    },
    {
      "label": "Spain",
      "value": "ES"
    },
    {
      "label": "Sri Lanka",
      "value": "LK"
    },
    {
      "label": "Sudan",
      "value": "SD"
    },
    {
      "label": "Suriname",
      "value": "SR"
    },
    {
      "label": "Svalbard and Jan Mayen",
      "value": "SJ"
    },
    {
      "label": "Swaziland",
      "value": "SZ"
    },
    {
      "label": "Sweden",
      "value": "SE"
    },
    {
      "label": "Switzerland",
      "value": "CH"
    },
    {
      "label": "Syrian Arab Republic",
      "value": "SY"
    },
    {
      "label": "Taiwan, Province of China",
      "value": "TW"
    },
    {
      "label": "Tajikistan",
      "value": "TJ"
    },
    {
      "label": "Tanzania, United Republic of",
      "value": "TZ"
    },
    {
      "label": "Thailand",
      "value": "TH"
    },
    {
      "label": "Timor-Leste",
      "value": "TL"
    },
    {
      "label": "Togo",
      "value": "TG"
    },
    {
      "label": "Tokelau",
      "value": "TK"
    },
    {
      "label": "Tonga",
      "value": "TO"
    },
    {
      "label": "Trinidad and Tobago",
      "value": "TT"
    },
    {
      "label": "Tunisia",
      "value": "TN"
    },
    {
      "label": "Turkey",
      "value": "TR"
    },
    {
      "label": "Turkmenistan",
      "value": "TM"
    },
    {
      "label": "Turks and Caicos Islands",
      "value": "TC"
    },
    {
      "label": "Tuvalu",
      "value": "TV"
    },
    {
      "label": "Uganda",
      "value": "UG"
    },
    {
      "label": "Ukraine",
      "value": "UA"
    },
    {
      "label": "United Arab Emirates",
      "value": "AE"
    },
    {
      "label": "United Kingdom",
      "value": "GB"
    },
    {
      "label": "United States",
      "value": "US"
    },
    {
      "label": "United States Minor Outlying Islands",
      "value": "UM"
    },
    {
      "label": "Uruguay",
      "value": "UY"
    },
    {
      "label": "Uzbekistan",
      "value": "UZ"
    },
    {
      "label": "Vanuatu",
      "value": "VU"
    },
    {
      "label": "Venezuela, Bolivarian Republic of",
      "value": "VE"
    },
    {
      "label": "Viet Nam",
      "value": "VN"
    },
    {
      "label": "Virgin Islands, British",
      "value": "VG"
    },
    {
      "label": "Virgin Islands, U.S.",
      "value": "VI"
    },
    {
      "label": "Wallis and Futuna",
      "value": "WF"
    },
    {
      "label": "Western Sahara",
      "value": "EH"
    },
    {
      "label": "Yemen",
      "value": "YE"
    },
    {
      "label": "Zambia",
      "value": "ZM"
    },
    {
      "label": "Zimbabwe",
      "value": "ZW"
    }
  ] 

  const index = valori.findIndex(object => {
    return object.value === v;
  });

  if(index > -1){
    return valori[index].label
  }else{
    return v
  }
 

}


const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};


function hasDecimal (num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

const borders = {
  top: {
      style: BorderStyle.NONE,
      size: 1,
      color: "ff0000",
  },
  bottom: {
      style: BorderStyle.NONE,
      size: 5,
      color: "889900",
  },
  left: {
    style: BorderStyle.NONE,
    size: 5,
    color: "889900",
  },
  right: {
    style: BorderStyle.NONE,
    size: 5,
    color: "889900",
}
}

const table = new Table({
  columnWidths: [3505, 5505],
  rows: [
      new TableRow({
          children: [
              new TableCell({
                borders: borders,
                  width: {
                      size: 3505,
                      type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph(
                      {
                        text: "Prestazione occasionale",
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.LEFT,
                      }
                    )],
              }),
              new TableCell({
                borders: borders,
                  width: {
                      size: 5505,
                      type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      text: "125,00 €",
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
              }),
          ],
      }),
      new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 3505,
                    type: WidthType.DXA,
                },
                borders: borders,
                children: [
                  new Paragraph(
                    {
                      text: "A detrarre ritenuta d'acconto (20%)",
                      heading: HeadingLevel.HEADING_1,
                      alignment: AlignmentType.LEFT,
                    }
                  )
                ],
            }),
            new TableCell({
               borders: borders,
                width: {
                    size: 5505,
                    type: WidthType.DXA,
                },
                children: [new Paragraph({
                  text: "-25,00 €",
                  alignment: AlignmentType.RIGHT,
                })],
            }),
        ],
    }),
    new TableRow({
      children: [
          new TableCell({
              width: {
                  size: 3505,
                  type: WidthType.DXA,
              },
              borders: borders,
              children: [
                new Paragraph(
                  {
                    text: "Netto a ricevere",
                    heading: HeadingLevel.HEADING_2,
                    alignment: AlignmentType.LEFT,
                  }
                )
              ],
          }),
          new TableCell({
             borders: borders,
              width: {
                  size: 5505,
                  type: WidthType.DXA,
              },
              children: [new Paragraph({
                text: "-25,00 €",
                heading: HeadingLevel.HEADING_2,
                alignment: AlignmentType.RIGHT,
              })],
          }),
      ],
  }),
  ],
});



const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" }
    ]
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" }
    ]
  }
]



const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',

  },
  page: { flexDirection: "row", padding: 25 },
  table: {
    fontSize: 10,
    width: '100%',
    display: "grid",
    height: '100%',
    gridTemplateRows: '40% 55%',
    
    
  },
  tableOld: {
    fontSize: 10,
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch"
  },
  row:{
    display: "grid",
    height: '100%',
    gridTemplateColumns: '33% 33% 33%',
  },
  rowOld: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
  },
  cell: {
    maxWidth:'100%',
    maxHeight:'100%',
    height:'100%',
    objectFit: 'contain',
    
  },
  cellPdf: {
    maxWidth:'100%',
    maxHeight:'80%',
    height:'70%',
    objectFit: 'contain',
    
  },
  cellOld: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
    objectFit: 'cover',
    height:'100%',
    backgroundColor:'red'
  },
  header: {
    backgroundColor: "#eee"
  },
  headerText: {
    fontSize: 11,
    fontWeight: 1200,
    color: "#1a245c",
    margin: 8
  },
  tableText: {
    margin: 10,
    fontSize: 10,
  },
  title: {
    fontSize: 24,
    margin:0,
    textAlign: 'left',
  },
  author: {
    marginTop:20,
    fontSize: 12,
    marginTop:0,
    textAlign: 'left',
    marginBottom: 40,
  },
});

const urlCreate = (url) =>{
  console.log('mostro url');
  
  let urlC = new URL(url).pathname
  urlC = urlC.replace("/v0/b/mcm01-a2ec1.appspot.com/o/", "");
  urlC = 'https://mcmimageshow.imgix.net/'+urlC+'?w=500'
  console.log(urlC);
  return urlC
}



const MyDocument = ({obg}) => {
  console.log('mostro');
  console.log(obg);
return(
  <DocumentP>
  
    
        <Page orientation='landscape' style={styles.page} size="A4" wrap>
          <View style={styles.table}>
           
            <View style={[styles.rowOld,{marginTop:90,marginBottom:20}]}>
              <View>
                <TextP style={styles.title}>{obg.nome}</TextP>
                <TextP style={styles.author}>{"Altezza: "+obg.altezza+"cm - Taglia: "+obg.taglia+" - Scarpe: "+obg.scarpe}</TextP>
              </View>
              <Image
                style={{height:80,width:113,float: 'right'}}
                src={'https://firebasestorage.googleapis.com/v0/b/mcm01-a2ec1.appspot.com/o/logoM%2Flogo%20color-01.png?alt=media&token=b933f4ad-041f-471a-972f-aca3dbb5ef51'}
              />
              </View>
               <View style={[styles.rowOld]}>
                  {obg.imgs.map((u,i)=>{
                    return(
                      <View style={{marginRight:10,height:'100%', maxHeight:'100%',display:'flex'}}>
                        <Image
                          style={styles.cellPdf}
                          src={urlCreate(u)}
                        />
                      </View>
                    )
                  })}
                  
                </View>
          </View>
          <Image style={{position:'absolute',left:0,right:0,top:0,height:'600px',zIndex:-1}} src={bgImg} />

      </Page> 

</DocumentP>
)
};




const doc = {}


const ContactsProfile = props => {


  const {
    match: { params },
 } = props

  const { userProfile, onGetUserProfile } = props
  const [userInfo, setUserInfo] = useState(null)
  const [userPlusInfo , setUserPlusInfo] = useState(null)
  const [categorie, setCategorie] = useState([])
  const [isGallery, setisGallery] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [note, setNote] = useState('')
  const [alert,setAlertx] = useState(false)
  const [alertMinore,setAlertMinore] = useState(false)
  const [alertDataFisc,setAlertDataFisc] = useState(false)
  const [alertComuneNas,setAlertComuneNas] = useState(false)
  const [alertSecondN,setAlertSecondN] = useState(false)
  const [documetone,setDocumentone] = useState(null)
  const [modal,setModal] = useState(false)
  const [modalPres,setModalPres] = useState(false)
  const [modalLavoro,setModalLavoro] = useState(false)
  
  const [datii,setDatii] = useState({})
  const [datiiPres,setDatiiPres] = useState({})

  const [messOk,setMessOk] = useState(false)
  
  

 const domicilioNew = () =>{

 }

  // eslint-disable-next-line no-unused-vars
  const [miniCards, setMiniCards] = useState([
    {
      title: "Completed Projects",
      iconClass: "bx-check-circle",
      text: "125",
    },
    { title: "Pending Projects", iconClass: "bx-hourglass", text: "12" },
    { title: "Total Revenue", iconClass: "bx-package", text: "$36,524" },
  ])


  const ckNumerocell = (daTest) => {
    var patt = new RegExp(/^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/);
    var res = patt.test(daTest);
    
    
    return res
  
  }



  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const genraChrome = () =>{
    let obj = {
      "nome": userInfo && userInfo.nome,
      "cognome": userInfo && userInfo.cognome,
      "cf":userInfo && userInfo.cf,
      "datanascita": userInfo && dateToString(userInfo.data_di_nascita),
      "dcid": userInfo && userInfo.cID,
      "indirizzoDomicilio": userPlusInfo && (userPlusInfo.via+', '+userPlusInfo.numero) ,
      "CapDomicilio":userPlusInfo && userPlusInfo.cap,
      "sesso":userInfo && userInfo.sesso,
      "typeD": userInfo && userInfo.typoDI
    }
    let stringgg = JSON.stringify(obj)

    copyTextToClipboard(stringgg)
    
  }

  const formatAdresse = (via) =>{   
    const axios = require('axios');
    let numero = "";
    let indirizzo = "";
    let comune = "";
    let provincia = "";
    let cap = "";
    let nazione = "";
    axios.get('https://maps.googleapis.com/maps/api/geocode/json?&address='+via+'&key=AIzaSyDSnyIFG854kXKB_U0TG8sfWINHIym_ET0')
        .then(function (response) {
          // handle success
          console.log('risposta foratt addd');
          console.log(response.data.results[0]);
          let riassunto = response.data.results[0].address_components;
  
  
          for (const component of riassunto) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];
            switch (componentType) {
              case "street_number": {
                numero = component.short_name;
                break;
              }
        
              case "route": {
                indirizzo = component.short_name;
                break;
              }
        
              case "postal_code": {
                cap = component.short_name;
                break;
              }
        
              case "country": {
                nazione = component.short_name;
                break;
              }
        
              case "country": {
                nazione = component.short_name;
                break;
              }
        
              case "locality": {
                comune = component.short_name;
                break;
              }
        
              case "administrative_area_level_2": {
                provincia = component.short_name;
                break;
              }
        
            }
          
          }
        
          let objInd = {
            numero:numero,
            via:indirizzo,
            cap:cap,
            comune:comune,
            provincia:provincia,
            nazione:nazione
          };
          
          console.log('mostro obj indir');
          console.log(objInd);
          
          setUserPlusInfo(objInd)
  
          //let indirizzo = response.data.results[0].formatted_address
          
         
        })
        .catch(function (error) {
          // handle error
          console.log('errore');
          console.log(error);
          
        })
        .then(function () {
          // always executed
         
        });
  
  }


  const ckFisconas = (datacont,cf)=>{

  }

  const ckNDatadinascita = (datacont) => {
    var ageDifMs = Date.now() - new Date(datacont).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    let resut = Math.abs(ageDate.getUTCFullYear() - 1970)
    console.log('mostro data');
    console.log(resut);
    if(resut < 18){
      setAlertMinore(true)
    }else{
      setAlertMinore(false)
    }
    return resut;
  
  }

  const handleSelectGroup = (selectedGroup) => {
    setselectedGroup(selectedGroup)
  }

  const approvazione = (approv) =>{
      let userUpdate = {
        uid:userInfo.uid,
      }
      userUpdate.approvato = approv

      console.log('id:');
      console.log(userInfo.uid);


      console.log('mostra');
      console.log(userUpdate);

      UpdateInfoStaff(userUpdate,(res)=>{
        //console.log('salvato');
        console.log(res);
        
        Alert('dato salvato')
        setUserInfo(res)
      })
      
  }


  const respingi = (value) =>{
    let userUpdate = {
      uid:userInfo.uid,
    }
    userUpdate.respinto = value

    UpdateInfoStaff(userUpdate,(res)=>{
      //console.log('salvato');
      //console.log(res);
      
      Alert('dato salvato')
      setUserInfo(res)
    })
    
}

  const salvainterna = () => {
    let userUpdate = {
      uid:userInfo.uid,
    }
    let arrr = []
    if(selectedGroup){
      selectedGroup.forEach(element => {
        arrr.push(element.value)
      });
    }
    

    userUpdate.categoriaInterna = arrr

    UpdateInfoStaff(userUpdate,(res)=>{
      //console.log('salvato');
      //console.log(res);
      alert('dato salvato')
    })
    //console.log(selectedGroup);
  }

  const salvanote = () =>{
    let tosave = {uid:userInfo.uid,note:note}
    UpdateInfoStaff(tosave,(res)=>{
      //console.log('salvato');
      //console.log(res);
      alert('dato salvato')
      
    })
  }

  const apriPerLavoro = () =>{
    setModalLavoro(true)
  }


  const aprigeneratore = () =>{
    setModalPres(true)
    setDatiiPres({
      nome:userInfo ? userInfo.nome+' '+userInfo.cognome.charAt(0) : null,
      altezza:userInfo ? userInfo.altezza : null,
      scarpe:userInfo ? userInfo.scarpe : null,
      taglia:userInfo ? userInfo.taglia : null,
    })
  }

  useEffect(() => {
    if (params && params.id) {
      _getStaffApi(params.id,(risultato)=>{
        console.log(risultato);
        setUserInfo(risultato)

        if(risultato.domicilioNew && risultato.domicilioNew.via){
        
          
         
          setUserPlusInfo(risultato.domicilioNew)
        }else{
          formatAdresse(risultato.domicilio)
        }
        

        let newDocument = new Document({
          styles: {
            default: {
                heading1: {
                    run: {
                        size: 28,
                        color: "00000",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                  run: {
                      size: 28,
                      bold:true,
                      color: "00000",
                  },
                  paragraph: {
                      spacing: {
                          after: 120,
                      },
                  },
              },
                
            }
          },
          sections: [
            
            {
              properties: {
                type: SectionType.ODD_PAGE,
            },
            headers: {
                default: new Header({
                    children: [
                      new Paragraph({
                        text: risultato.cognome+' '+risultato.nome,
                        heading: HeadingLevel.TITLE,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                }),
            },
            footers: {
                default: new Footer({
                    children: [],
                }),
            },
            children: [
              new Paragraph({
                text: "",
                heading: HeadingLevel.HEADING_2,
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun(
                    "Nata a "+risultato.comuneNas+" il "+dateToString(risultato.data_di_nascita)+", residente in "+risultato.residenza
                  ),
                  new TextRun({
                    text: "C.F. "+risultato.cf,
                    break: 1
                  })
                ]
              }),
              new Paragraph({
                text: " ",
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                text: "Milano, "+dateToString(new Date()),
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                text: "Ricevuta",
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                text: "",
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun(
                    `Spettabile`
                  ),
                  new TextRun({
                    text: "MCM Comunicazione S.r.l.",
                    bold: true,
                    break: 1
                  }),
                  new TextRun({
                    text: "via Paolo Sarpi, 56",
                    break: 1
                  }),
                  new TextRun({
                    text: "20154 Milano",
                    break: 1
                  })
                ]
              }),
              new Paragraph({
                text: "",
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                heading: HeadingLevel.HEADING_1,
                text: "Per prestazione occasionale in data Maggio 2021, relativa a collaborazione presso Milano",
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                text: "",
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                text: "",
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                text: "",
                alignment: AlignmentType.LEFT,
              }),
              table
        
            ],
        }]
        });

        setDocumentone(newDocument)


        if(risultato.cell){
          if(ckNumerocell(risultato.cell)){
            setAlertx(false)
          }else{
            setAlertx(true)
          }
          
        }

        if(risultato.cf && risultato.data_di_nascita){
          console.log('vediamoooo');
         
          
          let cfDate = new Date(dataCf(risultato.cf))
          console.log(cfDate)
          console.log(risultato.data_di_nascita);
          let isDAme = datesAreOnSameDay(cfDate, new Date(risultato.data_di_nascita)) 
          console.log(isDAme);
          if(!isDAme){
            setAlertDataFisc(true)
          }else{
            setAlertDataFisc(false)
          }
          
          
        }

        if(risultato.cf && risultato.nome){
          let errore = testNome(risultato.cf,risultato.nome)
          setAlertSecondN(!errore)
        }

        if(risultato.cf && risultato.comuneNas){
          let toTwst = risultato.comuneNas.toLowerCase()
          console.log('ooo');
          console.log(toTwst);
          let comune  = comuneCf(risultato.cf).toLowerCase();
          if(comune){
            let exist = toTwst.includes(comune);
          if(exist){
            setAlertComuneNas(false)
          }else{
            setAlertComuneNas(true)
          }
          }
          
          

        }

        if(risultato.data_di_nascita){
          ckNDatadinascita(risultato.data_di_nascita)
        }

        

        setNote(risultato.note)

        let arrCat = []
        risultato.categoriaInterna.map((categoria,index)=>{
          arrCat.push({label:categoria.titolo,value:categoria._id})
        })
        setselectedGroup(arrCat)

      })
      _getCategorieInterne((risultato)=>{
        setCategorie(risultato)
      })
    }
  }, [params.id])


  const addDati = (type,value) =>{
    let datiii = {...datii}
    datiii[type] = value
    setDatii(datiii)
  }

  const addDatiPres = (type,value) =>{
    let datiii = {...datiiPres}
    datiii[type] = value
    setDatiiPres(datiii)
  }

  const addRemove = (img,remove) =>{

    let datiii = {...datiiPres}
    let imgss = datiii.imgs ? datiii.imgs :[]
    if(remove){
      let pos = imgss.indexOf(img)
      imgss.splice(pos, 1)
    }else{
      imgss.push(img)
    }

    
    datiii.imgs = imgss;
    setDatiiPres(datiii)
  }

  const ckiimh = (img) =>{
    let datiii = {...datiiPres}
    let imgss = datiii.imgs ? datiii.imgs :[]
    return imgss.includes(img)
  }


  
  const savePdf = async (document, filename) => {
    saveBlob(await pdf(document).toBlob(), filename);
  };

  const generatore = () =>{
    console.log(datii);

    const tableee = new Table({
      columnWidths: [3505, 5505],
      rows: [
          new TableRow({
              children: [
                  new TableCell({
                    borders: borders,
                      width: {
                          size: 3505,
                          type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph(
                          {
                            text: "Prestazione occasionale",
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.LEFT,
                          }
                        )],
                  }),
                  new TableCell({
                    borders: borders,
                      width: {
                          size: 5505,
                          type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          text:  hasDecimal(Number(datii.soldi)+(datii.soldi/80)*20)+" €",
                          alignment: AlignmentType.RIGHT,
                        }),
                      ],
                  }),
              ],
          }),
          new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 3505,
                        type: WidthType.DXA,
                    },
                    borders: borders,
                    children: [
                      new Paragraph(
                        {
                          text: "A detrarre ritenuta d'acconto (20%)",
                          heading: HeadingLevel.HEADING_1,
                          alignment: AlignmentType.LEFT,
                        }
                      )
                    ],
                }),
                new TableCell({
                   borders: borders,
                    width: {
                        size: 5505,
                        type: WidthType.DXA,
                    },
                    children: [new Paragraph({
                      text: "- "+( hasDecimal((datii.soldi/80)*20))+' €',
                      alignment: AlignmentType.RIGHT,
                    })],
                }),
            ],
        }),
        new TableRow({
          children: [
              new TableCell({
                  width: {
                      size: 3505,
                      type: WidthType.DXA,
                  },
                  borders: borders,
                  children: [
                    new Paragraph(
                      {
                        text: "Netto a ricevere",
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                      }
                    )
                  ],
              }),
              new TableCell({
                 borders: borders,
                  width: {
                      size: 5505,
                      type: WidthType.DXA,
                  },
                  children: [new Paragraph({
                    text: hasDecimal(datii.soldi)+' €'  ,
                    heading: HeadingLevel.HEADING_2,
                    alignment: AlignmentType.RIGHT,
                  })],
              }),
          ],
      }),
      ],
    });

    let newDocumente = new Document({
      styles: {
        default: {
            heading1: {
                run: {
                    size: 28,
                    color: "00000",
                },
                paragraph: {
                    spacing: {
                        after: 120,
                    },
                },
            },
            heading2: {
              run: {
                  size: 28,
                  bold:true,
                  color: "00000",
              },
              paragraph: {
                  spacing: {
                      after: 120,
                  },
              },
          },
            
        }
      },
      sections: [
        
        {
          properties: {
            type: SectionType.ODD_PAGE,
        },
        headers: {
            default: new Header({
                children: [
                  new Paragraph({
                    text: userInfo.cognome+' '+userInfo.nome,
                    heading: HeadingLevel.TITLE,
                    alignment: AlignmentType.CENTER,
                  }),
                ],
            }),
        },
        footers: {
            default: new Footer({
                children: [],
            }),
        },
        children: [
          new Paragraph({
            text: "",
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun(
                "Nata a "+userInfo.comuneNas+" il "+dateToString(userInfo.data_di_nascita)+", residente in "+userInfo.residenza
              ),
              new TextRun({
                text: "C.F. "+userInfo.cf,
                break: 1
              })
            ]
          }),
          new Paragraph({
            text: " ",
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            text: "Milano, "+dateToString(new Date()),
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "Ricevuta",
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            text: "",
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun(
                `Spettabile`
              ),
              new TextRun({
                text: "MCM Comunicazione S.r.l.",
                bold: true,
                break: 1
              }),
              new TextRun({
                text: "via Paolo Sarpi, 56",
                break: 1
              }),
              new TextRun({
                text: "20154 Milano",
                break: 1
              })
            ]
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            heading: HeadingLevel.HEADING_1,
            text: "Per prestazione occasionale in data _________ , relativa a collaborazione presso Milano",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          tableee,
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "Prestazione fuori campo I.V.A. ai sensi degli art.1 e 5 D.P.R. 633/1972, in quanto resa occasionalmente da soggetto che non esercita abitualmente qualsiasi attività di lavoro autonomo.Prestazione occasionale resa da soggetto non tenuto all’iscrizione alla Gestione separata dell'INPS istituita con Legge 8 Agosto 1995 n. 355, pertanto non soggetta al versamento del contributo previdenziale previsto dalla stessa legge",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "      Bollo",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "      Per ricevuta",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "",
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            text: "___________________",
            alignment: AlignmentType.LEFT,
          }),
        ],
    }]
    });
    Packer.toBlob(newDocumente).then(blob => {
      console.log(blob);
      saveAs(blob, userInfo.cognome+"_ritenuta.docx");
      console.log("Document created successfully");
      setModal(false)
    })
  }

  const removeCompetenzaProv = (index) =>{
    let userInfon = {...userInfo}
    let province_competenzaX = userInfon.province_competenza
    province_competenzaX.splice(index, 1);
    

    let userUpdate = {
      uid:userInfo.uid,
    }
    userUpdate.province_competenza = province_competenzaX


    
    UpdateInfoStaff(userUpdate,(res)=>{
      //console.log('salvato');
      console.log(res);
      
      //Alert('dato salvato')
      setUserInfo(userInfon)
    })
  }

  const removeCompetenza = (index) =>{
    let userInfon = {...userInfo}
    let competenzex = userInfon.competenze
    competenzex.splice(index, 1);
    

    let userUpdate = {
      uid:userInfo.uid,
    }
    userUpdate.competenze = competenzex

    
    
    UpdateInfoStaff(userUpdate,(res)=>{
      //console.log('salvato');
      console.log(res);
      
      //Alert('dato salvato')
      setUserInfo(userInfon)
    })
  }


  const generaNewAdress = async () =>{
    const axios = require('axios');
    try {
      let res = await axios({
            url: 'https://api.myptv.com/geocoding/v1/locations/by-text?searchText='+userInfo.residenza+'&apiKey=MTg1Zjk2MDdjZmJlNDM0YjgzNDFjY2I1Mzk2NGU1YmU6ZmYxZWNlY2EtZDdlOS00ZTFmLWJjNjEtMTQ5MDYxZDY0MDFk',
            method: 'get',
            timeout: 8000,
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(res.status == 200){
            // test for status you want, etc
            console.log(res.status)
        }    
        // Don't forget to return something   
       //let newUser = {...userInfo}
       
       let newUser = {
        uid:userInfo.uid,
      }

       newUser.residenza_via = res.data.locations[0].address.street
       newUser.residenza_civico = res.data.locations[0].address.houseNumber
       newUser.residenza_localita = res.data.locations[0].address.city
       newUser.residenza_provincia = res.data.locations[0].address.province
       newUser.residenza_cap = res.data.locations[0].address.postalCode
       newUser.newRes = true


       
      

        UpdateInfoStaff(newUser,(res)=>{
          //console.log('salvato');
          console.log(res);
          console.log('salvattooo');
          
          
          setUserInfo(res)
        })

       

    }
    catch (err) {
        console.error(err);
    }
  } 

  const accettaCambi = () =>{
    
    let userUpdate = {
      uid:userInfo.uid,
    }
    userUpdate.cambiamenti = {
      cell:null
    }
    userUpdate.ckSync = false
    userUpdate.toSync = true
    
    
    UpdateInfoStaff(userUpdate,(res)=>{
      console.log('salvato');
      console.log(res);
      //Alert('dato salvato')
      setUserInfo(res)
      setMessOk(true)
      //alert('ok approvazione cambi avvenuta')
    })
  }
  

  return (
    <React.Fragment>
      <Modal
        size='xl'
        isOpen={modalLavoro}
        toggle={() => {
          setModalLavoro(false)
        }}
      >
        <div className="lavoroide">
        <div className="modal-header">
          <h4>Dati Anagrafici</h4>

        </div>
       

        <div style={{padding:20,paddingBottom:0,display:'grid',gridTemplateColumns: 'repeat(3, 1fr)'}}>
          <div>
            <label><strong>C.F.*</strong></label>
            <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.cf)} >{userInfo && userInfo.cf}</p>
          </div>
          <div>
            <label><strong>Cognome</strong></label>
            <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.cognome)}>{userInfo && userInfo.cognome}</p>
          </div>
          <div>
            <label><strong>Nome</strong></label>
            <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.nome)}>{userInfo && userInfo.nome}</p>
          </div>
          <div>
          <label><strong>Sesso</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && sessologg(userInfo.sesso))}>{userInfo && sessologg(userInfo.sesso)}</p>
        </div>
        <div>
          <label><strong>Data di nascita</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && dateToString(userInfo.data_di_nascita))}>{userInfo && dateToString(userInfo.data_di_nascita)}</p>
        </div>
        <div>
          <label><strong>Cittadinanza</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && legender(userInfo.cittadinanza))}>{userInfo && legender(userInfo.cittadinanza)}</p>
        </div>
        <div>
          <label><strong>Stato di nascita</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo && userInfo.comuneNas.split(',')[userInfo.comuneNas.split(',').length - 1])}>{userInfo && userInfo.comuneNas.split(',')[userInfo.comuneNas.split(',').length - 1]}</p>
        </div>
        <div>
          <label><strong>Comune di nascita</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo && userInfo.comuneNas.split(',')[userInfo.comuneNas.split(',').length - 2])}>{userInfo && userInfo.comuneNas.split(',')[userInfo.comuneNas.split(',').length - 2]}</p>
        </div>
        <div>
          <label><strong>Citta estera di nascita</strong> </label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.comuneNas.split(',')[userInfo.comuneNas.split(',').length - 1] != ' Italia' ? userInfo.comuneNas.split(',')[userInfo.comuneNas.split(',').length - 2] : '')}>{userInfo && userInfo.comuneNas.split(',')[userInfo.comuneNas.split(',').length - 2]}</p>

        </div>
        </div>




     
     {/*<p style={{padding:20}}>*{userInfo && userInfo.comuneNas}</p>*/}
      
      <div className="modal-header">
          <h4>Documento d'identità</h4>

       </div>
       <div style={{padding:20,paddingBottom:0,display:'grid',gridTemplateColumns: 'repeat(3, 1fr)'}}>
        <div>
          <label><strong>Tipologia</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && tipologg(userInfo.typoDI))}>{userInfo && tipologg(userInfo.typoDI)}</p>
        </div>
        <div>
          <label><strong>Stato emissione</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && legender(userInfo.sEmissione))}>{userInfo && legender(userInfo.sEmissione)}</p>
        </div>
        <div>
          <label><strong>Numero</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.cID)}>{userInfo && userInfo.cID}</p>
        </div>
      </div>

      <div className="modal-header">
          <h4>Permesso di soggiorno</h4>

        </div>

        <div style={{padding:20,display:'grid',gridTemplateColumns: 'repeat(3, 1fr)'}}>
        <div>
          <label><strong>Titolo</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.titoloPerm)}>{userInfo && userInfo.titoloPerm}</p>
        </div>
        <div>
          <label><strong>Motivo</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.motivoPerm)}>{userInfo && userInfo.motivoPerm}</p>
        </div>
        <div>
          <label><strong>Numero</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.numeropSogg)}>{userInfo && userInfo.numeropSogg}</p>
        </div>
        <div>
          <label><strong>Questura rilascio</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && userInfo.qupSogg)}>{userInfo && userInfo.qupSogg}</p>
        </div>
        <div>
          <label><strong>Scadenza</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userInfo && dateToString(userInfo.scadpSogg))}>{userInfo && dateToString(userInfo.scadpSogg)}</p>
        </div>
        
      </div>

      <div className="modal-header">
          <h4>Domicilio</h4>

       </div>
       <div style={{padding:20,paddingBottom:0, display:'grid',gridTemplateColumns: '80% 20%'}}>
        <div>
          <label><strong>Indirizzo</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userPlusInfo && userPlusInfo.via+', '+userPlusInfo.numero)}>{userPlusInfo && userPlusInfo.via} , {userPlusInfo && userPlusInfo.numero}</p>
        </div>
        <div>
          <label><strong>Cap</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userPlusInfo && userPlusInfo.cap)}>{userPlusInfo && userPlusInfo.cap}</p>
        </div>
      </div>
      <div style={{padding:20,paddingBottom:0,display:'grid',gridTemplateColumns: 'repeat(3, 1fr)'}}>
        <div>
          <label><strong>Stato domicilio</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userPlusInfo && userPlusInfo.nazione)}>{userPlusInfo && userPlusInfo.nazione}</p>
        </div>
        <div>
          <label><strong>Comune domicilio</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userPlusInfo && userPlusInfo.comune)}>{userPlusInfo && userPlusInfo.nazione === 'IT' ? userPlusInfo.comune : ''}</p>
        </div>
        <div>
          <label><strong>Citta estero domicilio</strong></label>
          <p style={{cursor:'pointer'}} onClick={()=>copyTextToClipboard(userPlusInfo && userPlusInfo.comune)}>{userPlusInfo && userPlusInfo.nazione != 'IT' ? userPlusInfo.comune : ''}</p>
        </div>
      </div>
      

        
        <div style={{padding:10}}>
          
           
         <button onClick={()=>genraChrome()} style={{width:'100%',border:'none', borderRadius:5,padding:10,marginTop:2,backgroundColor:'#00bcd4',color:'white'}}>Genera per chrome</button>

          <button onClick={()=>setModalLavoro(false)} style={{width:'100%',border:'none', borderRadius:5,padding:10,marginTop:2,backgroundColor:'red',color:'white'}}>Chiudi</button>

        </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalPres}
        toggle={() => {
          
        }}
      >
        <div className="modal-header">
          <h2>Genera presentazione</h2>
          <p>*prima di generare ricordati di selezionare le foto da utilizzare</p>

        </div>

        
        <div style={{padding:10}}>
          
          <div>
            <div>
            <label>Nome</label>
            <input placeholder='nome' value={datiiPres.nome} onChange={(e)=>addDatiPres('nome',e.target.value)} style={{width:'100%',padding:5}} type='text' />
            </div>
            <div style={{display: 'grid'}}>
              <label>Altezza</label>
              <input onChange={(e)=>addDatiPres('altezza',e.target.value)} value={datiiPres.altezza}></input>
            </div>
            <div style={{display: 'grid'}}>
              <label>Taglia</label>
              <input onChange={(e)=>addDatiPres('taglia',e.target.value)} value={datiiPres.taglia}></input>
            </div>
            <div style={{display: 'grid'}}>
              <label>Scarpe</label>
              <input onChange={(e)=>addDatiPres('scarpe',e.target.value)} value={datiiPres.scarpe}></input>
            </div>
            <div style={{marginTop:30,position:'relative',display: 'grid',gridTemplateColumns: '33% 33% 33%'}}>
                      {userInfo && userInfo.images.map((img,i)=>{
                        if(ckiimh(img)){
                          return(
                            <div style={{padding:5,position:'relative'}}>
                              <div style={{backgroundColor:'#a5a5a5',borderRadius:'30px', position:'absolute',left:2,top:2}}>
                                <p style={{color:'white',padding:10}}>✔️</p>
                              </div>
                              <img onClick={()=>addRemove(img,true)} style={{width:'100%',cursor:'pointer'}} src={img} />
                            </div>
                          )
                        }else{
                          return(
                            <div style={{padding:5}}>
                                <img  onClick={()=>addRemove(img)}style={{width:'100%',opacity:0.8,cursor:'pointer'}} src={img} />
                              </div>
                          )
                        }
                        
                        /*if(isAdded(img)){
                          return(
                            <div style={{padding:5,position:'relative'}}>
                              <div style={{backgroundColor:'#a5a5a5',borderRadius:'30px', position:'absolute',left:2,top:2}}>
                                <p style={{color:'white',padding:10}}>✔️</p>
                              </div>
                              <img onClick={()=>addRemove(img,true)} style={{width:'100%',cursor:'pointer'}} src={img} />
                            </div>
                          )
                        }else{
                          return(
                            <div style={{padding:5}}>
                              <img  onClick={()=>addRemove(img)}style={{width:'100%',opacity:0.8,cursor:'pointer'}} src={img} />
                            </div>
                          )
                        }*/
                       
                      })}
                    </div>
          </div>  
          {/*<input onChange={(e)=>addDati('data',e.target.value)}  style={{width:'100%',padding:5,marginTop:5}} type='date' />*/}
          {datiiPres.imgs && datiiPres.imgs.length > 0 ? (
             <button onClick={()=>savePdf(<MyDocument obg={datiiPres}/>, datiiPres.nome+".pdf")} style={{width:'100%',border:'none', borderRadius:5,padding:10,marginTop:20}}>Genera</button>

          ) : 
          <button disabled='true' style={{width:'100%',border:'none', borderRadius:5,padding:10,marginTop:20}}>Genera</button>

          }
          <button onClick={()=>setModalPres(false)} style={{width:'100%',border:'none', borderRadius:5,padding:10,marginTop:2,backgroundColor:'red',color:'white'}}>Chiudi</button>

        </div>
      </Modal>
      <Modal
        isOpen={modal}
        toggle={() => {
          
        }}
      >
        <div className="modal-header">
          <h2>Aggiungi info per nota di credito</h2>

        </div>

        <p style={{padding:10}}>* ricorda sempre di inserire decimali es 100,00 </p>
        <div style={{padding:10}}>
          <input onChange={(e)=>addDati('soldi',e.target.value)} style={{width:'100%',padding:5}} type='number' />
          {/*<input onChange={(e)=>addDati('data',e.target.value)}  style={{width:'100%',padding:5,marginTop:5}} type='date' />*/}
          <button onClick={()=>generatore()} style={{width:'100%',border:'none', borderRadius:5,padding:10,marginTop:20}}>Genera</button>
          <button onClick={()=>setModal(false)} style={{width:'100%',border:'none', borderRadius:5,padding:10,marginTop:2,backgroundColor:'red',color:'white'}}>Chiudi</button>

        </div>
      </Modal>
      {isGallery ? (
            <Lightbox
              mainSrc={userInfo.images[photoIndex]}
              nextSrc={userInfo.images[(photoIndex + 1) % userInfo.images.length]}
              prevSrc={userInfo.images[(photoIndex + userInfo.images.length - 1) % userInfo.images.length]}
              enableZoom={true}
              onCloseRequest={() => {
                setisGallery(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex((photoIndex + userInfo.images.length - 1) % userInfo.images.length)
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % userInfo.images.length)
              }}
              imageCaption={"Project " + parseFloat(photoIndex + 1)}
            />
          ) : null}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Profilo" />
          {userInfo && userInfo.ckSync ? (
            <Alert style={{background:'#ffc107'}} color="" role="alert">
              <p style={{fontSize:16,fontWeight:800}}>Ci sono stati cambi nel profilo amministrativo! controlla che tutto sia corretto</p>
              {userInfo && Object.keys(userInfo.cambiamenti).map((key, index) =>{
                if(userInfo.cambiamenti[key]){
                  return <p><strong style={{marginRight:10}}>{key}:</strong> {userInfo.cambiamenti[key].prima} -> {userInfo.cambiamenti[key].ora}</p>
                }
                
              })}
              <button style={{marginBottom:20,border:'none',padding:10,borderRadius:10,backgroundColor:'white',color:'#f1b44c'}} onClick={()=>accettaCambi()}>Accetta cambi</button>

            </Alert>
          ) : null}

          {messOk ? (
            <Alert style={{background:'#34c38f'}} color="" role="alert">
              <p style={{fontSize:16,fontWeight:800,color:'white'}}>Dati accettati</p>
            </Alert>
          ) : null}
          
          {alert ? (
            <Alert color="danger" role="alert">
              Sembra che il numero di cellulare non sia corretto
            </Alert>
          ) : null}
          {alertDataFisc ? (
            <Alert color="danger" role="alert">
              Sembra che la data di nascita non corrisponda con il codice fiscale { userInfo && userInfo.cf ? 'secondo il codice fiscale è nato il '+dataCf(userInfo.cf) : null}
            </Alert>
          ) : null}
          {alertMinore ? (
            <Alert color="danger" role="alert">
              Sembra che il candidato sia minorenne * { userInfo && userInfo.cf ? 'secondo il codice fiscale è nato il '+dataCf(userInfo.cf) : null}
            </Alert>
          ) : null}
          {alertComuneNas ? (
            <Alert color="danger" role="alert">
              Sembra che il comune di nascita non corrisponda con il codice fiscale {  userInfo && userInfo.cf ? 'comune codice fiscale: '+comuneCf(userInfo.cf) : null}
            </Alert>
          ) : null}
          {alertSecondN ? (
            <Alert color="warning" role="alert">
              Controlla bene potrebbe esserci un'incongruenza tra il nome e codice fiscale ( forse secondo nome)
            </Alert>
          ) : null}
          
          <button style={{marginBottom:20,border:'none',padding:10,borderRadius:10,backgroundColor:'#f1b44c',color:'white'}} onClick={()=>setModal(true)}>Genera ritenuta</button>
          <button style={{marginLeft:10, marginBottom:20,border:'none',padding:10,borderRadius:10,backgroundColor:'#343a40',color:'white'}} onClick={()=>aprigeneratore()}>Genera presentazione</button>
          <button style={{marginLeft:10, marginBottom:20,border:'none',padding:10,borderRadius:10,backgroundColor:'#343a40',color:'white'}} onClick={()=>apriPerLavoro()}>Scheda Per Piattaforma Lavoro</button>
          
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 style={{textTransform:'capitalize'}} className="text-primary">{userInfo ? userInfo.nome+' '+userInfo.cognome : null}</h5>
                        
                      </div>
                    </Col>
                    
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={userInfo ? userInfo.img_profilo : null}
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                        
                      </div>
                      <h5 style={{textTransform:'capitalize'}} className="font-size-15 text-truncate">
                        {userProfile.name}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        {userProfile.designation}
                      </p>
                    </Col>
                    <Col style={{padding:10}}>
                    
                    {userInfo && userInfo.respinto ? (
                        <button onClick={()=>respingi(false)} className="btn-rounded waves-effect waves-light btn btn-danger">Respinto</button>

                    ) : (
                      userInfo && userInfo.approvato ? (
                        <button onClick={()=>approvazione(false)} className="btn-rounded waves-effect waves-light btn btn-success">Rimuovi Approvazione</button>
                      ) : (
                        <div>
                          <button onClick={()=>approvazione(true)} className="btn-rounded waves-effect waves-light btn btn-success">Approva</button>
                          <button style={{marginLeft:20}} onClick={()=>respingi(true)} className="btn-rounded waves-effect waves-light btn btn-danger">Respingi</button>
                        </div>
                      )
                    )}
                    
                    
                    
                    </Col>
                    
                    {/*<button onClick={()=>{
                      _createNotifica({
                        type: 'info',
                        desc:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
                        titolo:'yoooo',
                        staff:['5f0c3b5247bc99523321b4df','60473612c1b5717d77d218fb'],
                      },(resultat)=>{
                        console.log('qui result');
                        console.log(resultat);
                      })
                    }}>test</button>*/}
                    
                    
                    <div className="table-responsive">

                     {userInfo ? ( 
                    <Tabbb className="table-nowrap mb-0">
                      <tbody>
                        <tr >
                          <th scope="row">Numero Documento:</th>
                          <td>{userInfo ? ( userInfo.cID ?  userInfo.cID : <span style={{color:'red'}}>manca</span> ) : null}</td>
                        </tr>
                        
                        <tr>
                          <th scope="row">Tipo Documento:</th>
                          <InputEdit value={userInfo.typoDI } tipo='typoDI' uid={userInfo.uid} />
                        </tr>
                        <tr>
                          <th scope="row">Stato emissione Documento:</th>
                          <InputEdit value={userInfo.sEmissione } tipo='sEmissione' uid={userInfo.uid} />
                        </tr>
                        <tr>
                          <th scope="row">Cittadinanza:</th>
                          <InputEdit value={userInfo.cittadinanza } tipo='cittadinanza' uid={userInfo.uid} />
                        </tr>
                        {userInfo ? (
                          userInfo.lingue.map((lingua,index)=>{
                            if(lingua.code){
                              return(
                                <tr key={index}>
                                  <th scope="row">{lingua ? lingua.code : null } :</th>
                                  <td>{levelDetect(lingua.value)}</td>
                                </tr>
                              )
                            }
                            
                          })

                        ) : null}
                        
                      </tbody>
                    </Tabbb>
                     ) : null}
                  </div>
                    
                  </Row>
                </CardBody>
              </Card>

                          
              {userInfo ? (
                <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informazioni contatto</CardTitle>
                  <p style={{textTransform:'capitalize'}} className="text-muted mb-4">
                    {userProfile.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Tabbb className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{alertSecondN ? <i style={{color:'orange'}} class="bx bx-error"></i> : null} Nome:</th>
                          <div style={{display:'flex'}}>
                            
                            <InputEdit  value={userInfo.nome} tipo='nome' uid={userInfo.uid}/>
                          </div>
                          
                        </tr>
                        <tr>
                          <th scope="row">Cognome:</th>
                          <InputEdit value={userInfo.cognome} tipo='cognome' uid={userInfo.uid} />
                        </tr>
                        <tr>
                          <th scope="row">Data di nascita:</th>
                          <td>{dateToString(userInfo.data_di_nascita)}</td>
                        </tr>
                        <tr>
                          {alertComuneNas ? (
                            <React.Fragment>
                              <th style={{backgroundColor:'red',color:'white'}} scope="row">Comune di nascita:</th>
                              <InputEdit value={userInfo.comuneNas} tipo='comuneNas' uid={userInfo.uid} iptype='indir'/>

                              {/*<td style={{color:'red'}}>
                                {userInfo.comuneNas}
                                <p>Errore..</p>
                          </td>*/}
                            </React.Fragment>
                            
                          ) : (
                            <React.Fragment>
                              <th scope="row">Comune di nascita:</th>
                              <td>{userInfo.comuneNas}</td>
                            </React.Fragment>
                          )}
                          
                        </tr>
                        <tr>
                          <th scope="row">Sesso:</th>
                          <td>{userInfo.sesso == 'M' ? 'Uomo' : 'Donna'}</td>
                        </tr>
                        <tr>
                          <th scope="row">Telefono: <a target='_blank' href={'https://wa.me/'+numberFormater(userInfo.cell)}><p className="text-muted">{userInfo.cell ?  <i class="bx bxl-whatsapp"></i> : null}</p></a></th>
                          {ckNumerocell(userInfo.cell) ? (
                            <td><InputEdit value={userInfo.cell} tipo='cell' uid={userInfo.uid} /></td>
                          ) : (
                            <td><div style={{marginTop:0}}>
                               <InputEdit value={userInfo.cell} tipo='cell' uid={userInfo.uid} />
                               <p style={{margin:0,fontWeight:'600',color:'red'}}> Il dato sembra errato</p>
                            </div></td>
                           
                          )}
                          
                          
                        </tr>
                        <tr>
                          <th scope="row">E-mail:</th>
                          <td>{userInfo.email}</td>
                        </tr>
                        <tr >
                          <th scope="row">Codice fiscale:</th>
                          <InputEdit  value={userInfo.cf} tipo='cf' uid={userInfo.uid}/>
                         {/*<td>{userInfo.cf}</td>*/}
                        </tr>
                        
                       
                        <tr>
                          <th scope="row">Residenza:</th>
                          <InputEdit value={userInfo.residenza} tipo='residenza' uid={userInfo.uid} iptype='indir'/>
                        </tr>
                        



                        <tr>
                          <th scope="row">Domicilio:</th>
                          <InputEdit value={userInfo.domicilio} tipo='domicilio' uid={userInfo.uid} iptype='indir'/>
                          
                        </tr>
                        
                      </tbody>
                    </Tabbb>
                  </div>
                </CardBody>
              </Card>
              ) : (
                <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informazioni contatto</CardTitle>
                </CardBody>
                </Card>
              )}

      {userInfo ? (
                <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informazioni importati</CardTitle>
                  <p style={{textTransform:'capitalize'}} className="text-muted mb-4">
                    {userProfile.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Tabbb className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          {alertComuneNas ? (
                            <React.Fragment>
                              <th style={{backgroundColor:'red',color:'white'}} scope="row">Comune di nascita:</th>
                              <InputEdit value={userInfo.comuneNas} tipo='comuneNas' uid={userInfo.uid} iptype='indir'/>

                              {/*<td style={{color:'red'}}>
                                {userInfo.comuneNas}
                                <p>Errore..</p>
                          </td>*/}
                            </React.Fragment>
                            
                          ) : (
                            <React.Fragment>
                              <tr>
                                <th>
                                  <h2 style={{width:0}}>Comune di nascita</h2>
                                  <p style={{fontSize:12,width:150}}>{userInfo.comuneNas}</p>
                                  <Button onClick={()=>generaNewAdress(true)}>Genera</Button>
                                </th>
                              </tr>
                          
                              <tr>
                              <tr>
                          <th scope="row">comune:</th>
                          <InputEdit value={userInfo.nascita_comune} tipo='nascita_comune' uid={userInfo.uid} />
                        
                        </tr>
                        <tr>
                          
                          <th scope="row">provincia:</th>
                          <InputEdit value={userInfo.nascita_provincia} tipo='nascita_provincia' uid={userInfo.uid} />
    
                        
                        </tr>
                              </tr>
                             
                            </React.Fragment>
                          )}
                          
                        </tr>
                       
                        
                        <tr>
                          <th>
                            <h2>Residenza</h2>
                            <p style={{fontSize:12,width:0}}>{userInfo.residenza}</p>
                            <Button onClick={()=>generaNewAdress()}>Genera</Button>
                          </th>
                        </tr>
                        

                        <tr>
                        <tr>
                          <th scope="row">Via:</th>
                          <InputEdit value={userInfo.residenza_via} tipo='residenza_via' uid={userInfo.uid} />
                        
                        </tr>
                        <tr>
                          
                          <th scope="row">civico:</th>
                          <InputEdit value={userInfo.residenza_civico} tipo='residenza_civico' uid={userInfo.uid} />
    
                        
                        </tr>
                        <tr>
                          
                          <th scope="row">citta:</th>
                          <InputEdit value={userInfo.residenza_localita} tipo='residenza_localita' uid={userInfo.uid} />
    
                        
                        </tr>
                        <tr>
                          
                          <th scope="row">cap:</th>
                          <InputEdit value={userInfo.residenza_cap} tipo='residenza_cap' uid={userInfo.uid} />
    
                        
                        </tr>
                        <tr>
                          <th scope="row">provincia:</th>
                          <InputEdit value={userInfo.residenza_provincia} tipo='residenza_provincia' uid={userInfo.uid} />
                        
                        </tr>
                        </tr>
                        

                        
                      </tbody>
                    </Tabbb>
                  </div>
                </CardBody>
              </Card>
              ) : (
                <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informazioni importati</CardTitle>
                </CardBody>
                </Card>
              )}
                  
        {userInfo ? (
                <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informazioni Fisiche</CardTitle>
                  <p className="text-muted mb-4">
                    
                  </p>
                  <div className="table-responsive">
                    <Tabbb className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Altezza :</th>
                          <InputEdit value={userInfo.altezza} tipo='altezza' uid={userInfo.uid} iptype='num'/>
                         
                        </tr>
                        <tr>
                          <th scope="row">Colore capelli :</th>
                          <td>{userInfo.capelli }</td>
                        </tr>
                        <tr>
                          <th scope="row">Colore occhi :</th>
                          <td>{userInfo.occhi}</td>
                        </tr>
                        <tr>
                          <th scope="row">Numero scarpe :</th>
                          <InputEdit value={userInfo.scarpe} tipo='scarpe' uid={userInfo.uid} iptype='num'/>
                         
                        </tr>
                        <tr>
                          <th scope="row">Taglia :</th>
                          <InputEdit value={userInfo.taglia} tipo='taglia' uid={userInfo.uid} iptype='num'/>
                          
                        </tr>
                        <tr>
                          <th scope="row">Tatoo visibili :</th>
                          <td>{userInfo.tatoo ? 'Si' : 'No'}</td>
                        </tr>
                        <tr>
                          <th scope="row">Piercing visibili :</th>
                          <td>{userInfo.piercing ? 'Si': 'No'}</td>
                        </tr>
                        
                      </tbody>
                    </Tabbb>
                  </div>
                </CardBody>
              </Card>
              ) : (
                <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>
                </CardBody>
                </Card>
              )}


              
            </Col>

            <Col xl="8">
            <Row>
                <InstaCard
                    title='Instagram'
                    text={userInfo && userInfo.instagram ? fixInstagram(userInfo.instagram) : ''}
                    iconClass='bx-check-circle'
                    
                  />
                  <MiniCards
                    title='Effettua fattura'
                    text={userInfo ? userInfo.pIva : ''}
                    iconClass={userInfo && userInfo.fatture ? 'bx-check-circle' : ''}
                    
                  />
                  <BancaCard
                    title='Iban'
                    text={userInfo ? userInfo.Iconto : ''}
                    iban={userInfo ? userInfo.iban : ''}
                    iconClass='bx-check-circle'
                    
                  />
              </Row>

              <CardTitle className="mb-4">Competenze : </CardTitle>
              <Row>
                {userInfo && userInfo.competenze.map((comp,index)=>{
                  return(
                    <>
                    <div style={{position:'relative'}}>
                    <button onClick={() => {if(window.confirm('Sei sicuro di voler eliminare questa competenza?')){removeCompetenza(index)};}} style={{position:'absolute',zIndex:1}}>x</button>
                    <MiniCardComp
                    title=''
                    text={comp.titolo}
                    iconClass=''
                    key={"_card_Comp"+index}
                  />
                  </div>
                  </>
                  )
                })}
              </Row>
              <CardTitle className="mb-4">Provincie competenza :</CardTitle>
              <Row>
                {userInfo && userInfo.province_competenza.map((comp,index)=>{
                  return(
                    <div style={{position:'relative'}}>
                      <button onClick={() => {if(window.confirm('Sei sicuro di voler eliminare questa provincia di competenza?')){removeCompetenzaProv(index)};}} style={{position:'absolute',zIndex:1}}>x</button>

                    <MiniCardComp
                      mini={true}
                      title=''
                      text={comp}
                      iconClass=''
                      key={"_card_xComp"+index}
                    />
                    </div>
                  )
                })}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Categorie interne</CardTitle>
                  
                  <Select
                      value={selectedGroup}
                      isMulti={true}
                      onChange={handleSelectGroup}
                      options={categorie}
                      classNamePrefix="select2-selection"
                    />
                    <br></br>
                    <button onClick={()=>salvainterna()} className="btn btn-primary waves-effect waves-light btn btn-primary">Salva</button>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Immagini</CardTitle>
                  {userInfo ? (userInfo.images.length < 3 ? <span style={{color:'red'}}>Devono essere minimo 3</span> : null ) : null   }
                  <div style={{textAlign:'center'}} className="popup-gallery">
                    
                      {userInfo ? (
                        userInfo.images.map((img,index)=>{
                          return(
                            <div key={index} className="img-fluid float-left">
                            <img
                              style={{objectFit: 'cover'}}
                              src={img}
                              onClick={() => {
                                setisGallery(true)
                                setphotoIndex(index)
                              }}
                              alt=""
                              width="120"
                              height="120"
                            />
                            </div>
                          )
                        })
                      ) : null}
                      
                    
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Note</CardTitle>
                  <FormGroup>
                    <Label htmlFor="formmessage">Inserisci qui le tue note su {userInfo ? userInfo.nome : ''} :</Label>
                    <Input
                      type="textarea"
                      id="formmessage"
                      className="form-control"
                      onChange={(e)=>{
                        setNote(e.target.value)
                      }}
                      rows="3"
                      value={note}
                    />
                  </FormGroup>
                  <Button onClick={()=>salvanote()} type="submit" color="primary">
                    Salva nota
                  </Button>
                      
                  
                </CardBody>
              </Card>
             
              
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
})

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile))
