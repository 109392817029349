import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card,Col,CardHeader, CardBody, CardTitle,CardText, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { indexOf, map } from "lodash"
import images from "assets/images"
import { dateToString , timeToString } from "../../../helpers/formatting";
import {_getStaffUserApi,_updateTurno,_createTurno,_deleteTurno} from "../../../helpers/server_helper"


/*
turnooo(idtime){
  console.log('id');
  console.log(idtime);
  let {arrayTurni} = this.state;
  let filterArray = _.filter(arrayTurni, { 'idDate': idtime });
  console.log('mostro con filtre');
  console.log(filterArray);
  return filterArray.map((x,i)=>{
    return (
      <div style={{display:'flex'}}>
        <FormGroup style={{width:''}} disabled={false} inline={false} intent={Intent.NONE} label={ "Ora inizio"} labelFor="text-input" >
          <TimePicker value={x.start}  onChange={(newTime)=>this._changeTime(newTime,x)}  />
        </FormGroup>
        <FormGroup style={{marginLeft:10}} disabled={false} inline={false} intent={Intent.NONE} label={ "Ora fine"} labelFor="text-input" >
          <TimePicker value={x.end} onChange={(newTime)=>this._changeTime(newTime,x,true)}  />
        </FormGroup>
      </div>
    )
  })
}*/

const turnoBox = ({turnoCard , adder , idProg ,giorno,actionCreate }) => {

  const [edit,setEdit] = useState(false)
  const [start,setStart] = useState(null)
  const [end,setEnd] = useState(null)
  const [add,setAdd] = useState(false)
  const [prezzoTurno,setPrezzoTurno] = useState(null)
  const [locationTurno,setLocationTurno] = useState(null)






  const salvaTurno = () =>{

    let splitStart =  start.split(':')
    let splitEnd =  end.split(':')


    var todayStart = new Date();
    var todayEnd = new Date();

    
    var myToday = new Date(todayStart.getFullYear(), todayStart.getMonth(), todayStart.getDate(), splitStart[0], splitStart[1], 0);
    var endNe = new Date(todayEnd.getFullYear(), todayEnd.getMonth(), todayEnd.getDate(), splitEnd[0], splitEnd[1], 0);


    let obj;


    if(adder){
      obj = {
        start:myToday,
        end:endNe,
        giorno: giorno,
        lavoro:idProg,
        prezzo:prezzoTurno,
        location:locationTurno
      }
      
      _createTurno(obj,(result)=>{
        alert('turno creato')
        setEdit(false)
        actionCreate()
      })
    }else{
      obj = {
        id:turnoCard.id,
        start:myToday,
        end:endNe,
        prezzo:prezzoTurno,
        location:locationTurno
      }
      _updateTurno(obj,(result)=>{
        alert('turno salvato')
        setEdit(false)
      })

    }

    
    console.log(obj);

    
    
    
    
  }


  const eliminaTurno = () =>{
    let actionCreatex = actionCreate
    _deleteTurno(turnoCard.id,()=>{
      alert('ok cancellato')
      actionCreate()
    })
    
  }

  useEffect(() => {
    if(turnoCard){
      console.log('mostro turni box');
      console.log(turnoCard);
      if(adder){
        setStart('00:00')
        setEnd('00:00')
      }else{
        setStart(timeToString(turnoCard.start))
        setEnd(timeToString(turnoCard.end))
        setLocationTurno(turnoCard.location)
        setPrezzoTurno(turnoCard.prezzo)
      }
     
    }
  }, [turnoCard])
    
    if(edit || adder){
      return(
        <Card  style={{width:250}} outline color="warning" className="border">
          <CardHeader className="bg-transparent">
            <h5 className="my-0 text-warning">
              <i className="mdi mdi-bullseye-arrow mr-3"/>{adder ? 'Aggiungi turno' :'Orario turno'}
            </h5>
          </CardHeader>
          {add ? (
            <CardBody >
            <div style={{display:'grid',gridTemplateColumns: '50% 50%'}}>
            <div style={{textAlign:'center'}}>
              <CardTitle className="mt-0">Inizio</CardTitle>
              <CardText>
                <input
                  className="form-control"
                  type="time"
                  defaultValue={start}
                  onChange={(value)=>setStart(value.target.value)}
                  id="example-time-input"
                />
              
              </CardText>
            </div>
            <div style={{textAlign:'center'}}>
              <CardTitle className="mt-0">Fine</CardTitle>
              <CardText>
               <input
                  className="form-control"
                  type="time"
                  defaultValue={end}
                  onChange={(value)=>setEnd(value.target.value)}
                  id="example-time-input"
                />
              </CardText>
            </div>
            </div>
            <div>
            <label>prezzo:</label> 
              <input value={prezzoTurno} onChange={(value)=>setPrezzoTurno(value.target.value)} style={{width:'100%'}} />
            </div>
            <div>
            <label>location:</label> 
              <input value={locationTurno} onChange={(value)=>setLocationTurno(value.target.value)} style={{width:'100%'}} />
            </div>
            
          </CardBody>
          ) : (
            <button style={{background:'none',border:'none',padding:10}} onClick={()=>setAdd(true)}>AGGIUNGI</button>
          )}
          {/*
          <CardBody style={{display:'grid',gridTemplateColumns: '50% 50%'}}>
            <div style={{textAlign:'center'}}>
              <CardTitle className="mt-0">Inizio</CardTitle>
              <CardText>
                <input
                  className="form-control"
                  type="time"
                  defaultValue={start}
                  onChange={(value)=>setStart(value.target.value)}
                  id="example-time-input"
                />
              
              </CardText>
            </div>
            <div style={{textAlign:'center'}}>
              <CardTitle className="mt-0">Fine</CardTitle>
              <CardText>
               <input
                  className="form-control"
                  type="time"
                  defaultValue={end}
                  onChange={(value)=>setEnd(value.target.value)}
                  id="example-time-input"
                />
              </CardText>
            </div>
            
          </CardBody>*/}
          {add ? (
            adder ? (
              <div className="my-0 text-warning"  style={{textAlign:'center',padding:5,cursor:'pointer'}}>
              <span onClick={()=>salvaTurno()} style={{color:'blue'}}>salva</span>
              </div>
            ) : (
              <div className="my-0 text-warning"  style={{textAlign:'center',padding:5,cursor:'pointer'}}>
              <span onClick={()=>setEdit(false)} style={{color:'red'}}>chiedi</span>
              <span>    </span>
              <span onClick={()=>salvaTurno()} style={{color:'blue'}}>salva</span>
            </div>
            )
          ) : null}
          
          
          
          
        </Card>
       )
    }else{
      return(
        <Card  style={{width:200, minHeight: 179}} outline color="primary" className="border">
          <CardHeader className="bg-transparent">
            <h5 className="my-0 text-primary">
              <i className="mdi mdi-bullseye-arrow mr-3"/>Orario turno
            </h5>
          </CardHeader>
          <CardBody >
            <div style={{display:'grid',gridTemplateColumns: '50% 50%'}}>
            <div style={{textAlign:'center'}}>
              <CardTitle className="mt-0">Inizio</CardTitle>
              <CardText>
              {start}
              </CardText>
            </div>
            <div style={{textAlign:'center'}}>
              <CardTitle className="mt-0">Fine</CardTitle>
              <CardText>
              {end}
              </CardText>
            </div>
            </div>
            <div>
            <div style={{textAlign:'center',marginTop:20}}> 
            <p>Prezzo: <span style={{fontWeight:'900'}}>{prezzoTurno}</span></p>
 
            <p>Location: <span style={{fontWeight:'900'}}>{locationTurno}</span></p>
            </div>
          </div>
          </CardBody>
          <div onClick={()=>{setEdit(true),setAdd(true)}} style={{textAlign:'center',padding:5,color:'blue',cursor:'pointer'}}>
            edit
          </div>
          <div onClick={()=>{if(window.confirm('Sei sicuro di voler eliminare il turno? non potra più essere utilizzato')){eliminaTurno()}}} style={{textAlign:'center',padding:5,color:'red',cursor:'pointer'}}>
            Rimuovi
          </div>
          
        </Card>
       )
    }
   
        

  
  
}



export default turnoBox
