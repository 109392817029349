import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table,Modal } from "reactstrap"
import { Link } from "react-router-dom"
import { indexOf, map } from "lodash"
import images from "assets/images"
import algoliasearch from 'algoliasearch/lite';

import {_getStaffUserApi,UpdateInfoLavoro} from "../../../helpers/server_helper"


const client = algoliasearch(
  '3VN9LAE1IH',
  '4adcefd5da1ae8ae047903a30e3fcf48'
);
const index = client.initIndex('STAFF');

const TeamMembers = ({id, team,gestore }) => {

  const [teams,setTeams] = useState([])
  const [lista,setLista] = useState([])
  const [teamId,setteamId] = useState([])
  const [modal_standard,setmodal_standard] = useState(false)


  const cambiaSelect = (index) =>{
    console.log(index);
    let newArr = [...teams]
    newArr[index].active = !newArr[index].active
    setTeams(newArr)  
  }


  const testoCerca =  (value) =>{
    
    if(value.target.value.length > 5){
      index.search(value.target.value, {
        filters: '',
        hitsPerPage: 50,
      }).then(res => {
  
        setLista(res.hits)
    
      });
    }
  }

  const addToTema = (idUser,infoadd) =>{
    let teamOld = teamId
    let objUser = {
      _id:idUser,
      img_profilo:infoadd.photo,
      nome:infoadd.nome,
      cognome:infoadd.cognome,
    }
    teamOld.push(idUser)
    let obj = {
      id:id,
      selected:teamOld
    }
    
    UpdateInfoLavoro(obj,(result)=>{
      console.log('risultato');
      console.log(result);
      if(!result.error){
        let listaold = teams
        listaold.push(objUser)
        setTeams(listaold)
        setLista([])
        setmodal_standard(false)
        alert('referenti salvati')
      }
     
    })

  }

  const salvaCambioTeam = () =>{
    //console.log(teams);
    let newReferenti = []
    teams.forEach(element => {
      if(element.active){
        newReferenti.push(element._id)
      }
    });
    let obj = {
      id:id,
      referenti:newReferenti
    }
    
    UpdateInfoLavoro(obj,(result)=>{
      alert('referenti salvati')
    })
  }

  const removeStaff = (index) =>{
    let staffone = teams
    staffone.splice(index, 1);
    
    let idArr = []
    staffone.forEach(element => {
      idArr.push(element._id)
    });

    let obj = {
      id:id,
      selected:idArr
    }
    
    UpdateInfoLavoro(obj,(result)=>{
      console.log('risultato');
      console.log(result);
      if(!result.error){
        console.log('vediamo lista');
        console.log(staffone);
        setLista([])
        setTeams(staffone)
        alert('referenti salvati')
      }
     
    })

  }


  useEffect(() => {
    if (team) {
      let arrays = []
      team.forEach(element => {
        arrays.push(element._id)
      });
      let selectedTeam = []
      setTeams(team)
      setteamId(arrays)

    }
  }, [team])


  return (
    <React.Fragment>
      <Modal
      isOpen={modal_standard}
      toggle={() => {
        modal_standard
      }}
    >
      <div className="modal-header">
        <input onChange={(value)=>testoCerca(value)} style={{width:'100%',border:'1px solid #ddd',padding:10}} type='text' />
        <button
          type="button"
          onClick={() => {
            setmodal_standard(!modal_standard)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <Table className="table table-centered table-nowrap">
        <tbody>
       {lista.map((member,index)=>{
            return(
              <tr  onClick={()=>addToTema(member.objectID,member)} style={{backgroundColor: member.active ? '#efef' :'#fff',cursor:'pointer'}} key={"_member_User" + index} >
                  <td style={{ width: "50px" }}>
                    {member.photo !== "Null" ? (
                      <img
                        src={member.photo}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    ) : (
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
                          {member.name.charAt(0)}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    <h5 className="font-size-14 m-0">
                    {member.nome} {member.cognome}
                    </h5>
                  </td>
                  
                  <td>
                    <div>
                      {/*map(member.skills, (skill, key) => (
                        <Link
                          to="#"
                          className="badge badge-primary font-size-11 mr-1"
                          key={"_skill_" + key}
                        >
                          {skill.name}
                        </Link>
                      ))*/}
                    </div>
                  </td>
                </tr>
            )})}
        </tbody>
        </Table>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setmodal_standard(!modal_standard)
          }}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
        >
          Aggiungi
        </button>
      </div>
    </Modal>
    
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Staff di lavoro</CardTitle>

        <div style={{height:300,overflow:'scroll'}} className="table-responsive">
          <Table className="table table-centered table-nowrap">
            <tbody>
              {map(teams, (member, k) => (
                <tr style={{backgroundColor: member.active ? '#efef' :'#fff',cursor:'pointer'}} key={"_member_" + k} >
                  <td style={{ width: "50px" }}>
                    {member.img_profilo !== "Null" ? (
                      <img
                        src={member.img_profilo}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    ) : (
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
                          {member.name.charAt(0)}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    <h5 className="font-size-14 m-0">
                    {member.nome} {member.cognome}
                    </h5>
                  </td>
                  <td>
                    <i onClick={gestore ? ()=>removeStaff(k) : ()=>alert('solo il gestore puo accedere a questa funzione')} style={{fontSize:'20px'}} className="mdi mdi-delete-circle"></i>
                  </td>
                  <td>
                    <div>
                      {/*map(member.skills, (skill, key) => (
                        <Link
                          to="#"
                          className="badge badge-primary font-size-11 mr-1"
                          key={"_skill_" + key}
                        >
                          {skill.name}
                        </Link>
                      ))*/}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <button onClick={gestore ? ()=>setmodal_standard(true) : ()=>alert('solo il gestore puo accedere a questa funzione')} class="btn btn-primary waves-effect waves-light btn btn-primary">Aggiungi</button>
      </CardBody>
    </Card>
    </React.Fragment>
  )
}

TeamMembers.propTypes = {
  team: PropTypes.array,
}

export default TeamMembers
