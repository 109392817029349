import API from './Api'
import firebase from "firebase/app"
//import { auth , storage , fireDB,increment } from "../components/Firebase/firebase";

import "firebase/auth"
import "firebase/firestore"
import "firebase/database"




/*
const _getAllLavori = (callback) => {
  const myAPI = new API({ url: "/lavori" });
  var entity = { name: "lavori" };
  myAPI.createEntity(entity);
  var newThis = this;
  this.props.firebase.onAuthUserListenerXLavoro(function(data) {
    //alert('Sei il benvenuto! '+newThis.props.match.params.id);

    var ppp = {'Authorization' : 'Bearer '+data.idToken};
    console.log('vediamo token');
    console.log(data.idToken);
    console.log('vediamo id');
    console.log(data.mongoId);
    myAPI.endpoints.lavori
      .getOne(
        { id: newThis.props.match.params.id },
        ppp
      )
      .then(res => {

        const lavoro = res.data;

        console.log('mostro i i dati:');
        console.log(lavoro);
        let ismy = lavoro.referenti.indexOf(data.mongoId);
        let adminLavoro = false
        if(ismy > -1){
          adminLavoro = true;
        }

        newThis.setState({lavoro:lavoro,imadmin:adminLavoro})
        newThis._getTurno(lavoro._id)
        newThis.getStaffUser()
        newThis.getDaVisonare()


      });
  });
}
*/



const saveMessageGroup = (messageText, currentGroupId,infoChat,callback) => {
  if (messageText.trim()) {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          let userone ={
            _id:user.uid
          }
          const message = {
            messageText,
            sentAt:new Date(),
            sentBy: user.uid,
            user:userone,
            _id:'_' + Math.random().toString(36).substr(2, 9),
            createdAt:new Date(),
            text:messageText
          }
  
          firebase.firestore().collection('messaggi')
          .doc(currentGroupId)
          .collection('messeges')
          .add(message)
          .then(function (docRef) {
  
            if(infoChat){

              console.log('mostrooooo');
              console.log(infoChat);

              firebase.firestore().collection('gruppo')
              .doc(currentGroupId)
              .set({
                CreateAt:new Date(),
                memme:infoChat.member,
                fotoSfaff:infoChat.fotoSfaff,
                isStaffA:true,
                StaffNome:infoChat.StaffNome,
                nomeUser:infoChat.nomeUser,
                userImg:infoChat.userImg,
                MessRecente: {
                  readBy:{
                    [user.uid]:true
                  },

                  sendBy:user.uid,
                  test:messageText
                }
              }, {mergeFields: ['MessRecente','CreateAt','memme','isStaffA','fotoSfaff','nomeUser','StaffNome','userImg']});
            }else{
              firebase.firestore().collection('gruppo')
            .doc(currentGroupId)
            .set({
              CreateAt:new Date(),
              MessRecente: {
                readBy:{
                  [user.uid]:true
                },

                sendBy:user.uid,
                test:messageText
              }
            }, {mergeFields: ['MessRecente','CreateAt']});
            }

            
  
              //mergeFields: ['CreateAt','Modificato','Nome','color','createdBy','icon','members','memme','idLavoro']}
            resolve(message)
            callback('ok')
          })
          .catch(function (error) {
            reject(error)
          })
        
        }
      })
    })
  
}
}

export const viewCk = (currentGroupId) => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.firestore().collection('gruppo')
            .doc(currentGroupId)
            .set({
              MessRecente: {
                readBy:{
                  [user.uid]:true
                },
              }
            }, {merge: true})
            .then(function (docRef) {
              console.log('vcvcvc');
              console.log('fatto');
            })
          }
      })
    })
 }

export const getAllMess = (groupId)  => {
  const unsubscribe = firebase.firestore()
    .collection("messaggi")
    .doc(groupId.trim())
    .collection('messeges')
    .orderBy('createdAt','asc')
    .onSnapshot(querySnapshot => {
      
      const allMessages = []
          querySnapshot.forEach((doc) => {
            //console.log('dataaa');
          //console.log(doc.data().createdAt);
          new Date(doc.data().createdAt.seconds)
    
            let data = doc.data();
            data.createdAt=  doc.data().createdAt.toDate();
            data.user = doc.data().user;
            if (doc) allMessages.push(data)
          })
          console.log(allMessages)

    });
  return unsubscribe;
};


const _getChatMess = (groupId,callback) => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        
       const groupRef = firebase.firestore().collection('messaggi')
        groupRef
        .doc(groupId.trim())
        .collection('messeges')
        .orderBy('createdAt','asc')
        .onSnapshot((querySnapshot) => {
      
          const allMessages = []
          querySnapshot.forEach((doc) => {
            //console.log('dataaa');
          //console.log(doc.data().createdAt);
          new Date(doc.data().createdAt.seconds)
    
            let data = doc.data();
            data.createdAt=  doc.data().createdAt.toDate();
            data.user = doc.data().user;
            if (doc) allMessages.push(data)
          })
          //console.log(allMessages);
    
    
          
          callback(allMessages)
      })
      }
    })
  })
}

const _getChatGroup = (staffer,callback) => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log('userrr');
        console.log(user.uid);
       const groupRef = firebase.firestore().collection('gruppo')
        groupRef
        .where('memme', 'array-contains', user.uid)
        .where('isStaffA', '==', staffer)
        .orderBy('CreateAt', 'desc')
        .onSnapshot((querySnapshot) => {
      
        const allGroups = []
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          //console.log(data);
          data.id = doc.id
          if (data.MessRecente) allGroups.push(data)
        })
        //console.log('qui')
        //console.log(allGroups)
        callback(allGroups)
      })
      }
    })
  })
}

const _getStaffToDo = (callback) => {
  const ApiLavori = new API({ url: "/stafftodo" });
  var entity = { name: "stafftodo" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.stafftodo.getAllOk(
            ppp
          ).then(res => {
            const alltodo = res.data;
                 
            callback(alltodo)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}





export const _getCategorieInterne = (callback) => {
  const ApiLavori = new API({ url: "/categoriainterna" });
  var entity = { name: "categoriainterna" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.categoriainterna.getAllOk(
            ppp
          ).then(res => {
           

            let categorieArray = [];
            res.data.forEach((item, i) => {
              categorieArray.push({
                label: item.titolo,
                value: item._id
              })
            });
                 
            callback(categorieArray)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const CompetenzeListaFunct = (callback) => {
  const ApiLavori = new API({ url: "/competenza" });
  var entity = { name: "competenza" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.competenza.getAllOk(
            ppp
          ).then(res => {
            

            let categorieArray = [];
            res.data.forEach((item, i) => {
              categorieArray.push({
                label: item.titolo,
                value: item._id
              })
            });
                 
            callback(categorieArray)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


const _getCategorie = (callback) => {
  const ApiLavori = new API({ url: "/categoriainterna" });
  var entity = { name: "categoriainterna" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.categoriainterna.getAllOk(
            ppp
          ).then(res => {
            const optionGroup = [
              {
                label: "Picnic",
                options: []
              }
            ]

            let categorieArray = [];
            res.data.forEach((item, i) => {
              optionGroup.options.push({
                label: item.titolo,
                value: item._id
              })
            });
                 
            callback(categorieArray)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

const _getStaff = (id,callback) => {
  const ApiLavori = new API({ url: "/staffid" });
  var entity = { name: "staffid" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.staffid.getOne(
            { id: id },
            ppp
          ).then(res => {
            const userInfo = res.data;
                 
            callback(userInfo)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

//forse problematica
const _testTabellaTurni = (id,allselect,callback) => {
  const ApiLavori = new API({ url: "/itemturnolavoro" });
  var entity = { name: "itemturnolavoro" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.itemturnolavoro.getOne(
            { id:id },
              ppp
            ).then(res => {
              let cards = [];
              res.data.forEach((item, i) => {
                console.log('pppppp vedfiamo');
                console.log(item);
                let card = {
                  nome: {type:'text',text:item.infoUser[0].cognome+' '+item.infoUser[0].nome,id:item.infoUser[0]._id},
                }
                item.itemsSold.forEach((turno, i) => {
                  if(allselect){
                    if( turno.status == "selezionati"){
                      let idc = [turno.turno];
                      card[idc] = {type:'card',status:turno.status,idItem:turno.item, user:item.infoUser ,date:'c' ,turno:'c',ckIn: turno.ckIn ? turno.ckIn : null , ckOut:turno.ckOut ? turno.ckOut : null}
                    }else{
                      card.remove = true
                    }
                  }else{
                    let idc = [turno.turno];
                    card[idc] = {type:'card',status:turno.status,idItem:turno.item, user:item.infoUser ,date:'c' ,turno:'c',ckIn: turno.ckIn ? turno.ckIn : null , ckOut:turno.ckOut ? turno.ckOut : null}
                  }
                 
                  
                });
      
                if(allselect){
                  if(!card.remove){
                    cards.push(card)
                  }
                }else{
                  cards.push(card)
                }
                
                
              });

              callback(cards) 
            
            

          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}



const _getTurno = (id,callback) => {
  const ApiLavori = new API({ url: "/turnolavoro" });
  var entity = { name: "turnolavoro" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.turnolavoro.getOne(
            { id: id },
            ppp
          ).then(res => {
            const turni = res.data;
            let array = []
            turni.forEach(
              element => array.push({id:element._id,start:new Date(element.start),end:new Date(element.end),idDate:element.giorno.idDate,prezzo:element.prezzo,location:element.location, status:(element.status == null ? true : element.status )})
            );      
            callback(array)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


const _getStaffUser = (callback) => {
  const ApiLavori = new API({ url: "/adminstaff" });
  var entity = { name: "adminstaff" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.adminstaff.getAllOk(ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


const _getLavoro = (id,callback) => {
  const ApiLavori = new API({ url: "/lavori" });
  var entity = { name: "lavori" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.lavori.getOne(
            { id: id},
            ppp
          ).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

const _getLavoroCopy = (id,callback) => {
  const ApiLavori = new API({ url: "/lavorocopy" });
  var entity = { name: "lavorocopy" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.lavorocopy.getOne(
            { id: id},
            ppp
          ).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const UpdateInfoStaff = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/staff" });
  var entity = { name: "staff" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.staff.update(datoToupdate,ppp).then(res => {
                  console.log('dato salvato');
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const _updateTurno = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/turno" });
  var entity = { name: "turno" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.turno.updateConID(datoToupdate,ppp).then(res => {
                  
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

export const _createTurno = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/turno" });
  var entity = { name: "turno" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              ApiStaff.endpoints.turno.create(datoToupdate,ppp).then(res => {
                  
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}



export const AddStaff = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/staffaddnew" });
  var entity = { name: "staffaddnew" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.staffaddnew.update(datoToupdate,ppp).then(res => {
                  
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

export const UpdateInfoLavoro = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/lavoro" });
  var entity = { name: "lavoro" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.lavoro.updateConID(datoToupdate,ppp).then(res => {
                  
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const ChiudiLavoro = (datoToupdate,callback) => {
  console.log('mostro pass');
  console.log(datoToupdate);
  const ApiStaff = new API({ url: "/lavorofine" });
  var entity = { name: "lavorofine" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.lavorofine.update(datoToupdate,ppp).then(res => {
                  
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const _getAllLavoriStoriaco = (callback) => {
  const ApiLavori = new API({ url: "/lavoriterminati" });
  var entity = { name: "lavoriterminati" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.lavoriterminati.getAllOk(ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


const _getAllLavori = (callback) => {
  const ApiLavori = new API({ url: "/lavoritrue" });
  var entity = { name: "lavoritrue" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.lavoritrue.getAllOk(ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}




const _getAllLavoriold = (callback) => {
  const ApiLavori = new API({ url: "/lavoritoday" });
  var entity = { name: "lavoritoday" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.lavoritoday.getAllOk(ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

export const _getAllClinetiValue = (callback) => {
  const ApiLavori = new API({ url: "/clienti" });
  var entity = { name: "clienti" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          let array = [];
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.clienti.getAllOk(ppp).then(res => {
            res.data.forEach(element => {
              array.push({label:element.alias,value:element._id})
            });
            callback(array)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const _getAllClineti = (callback) => {
  const ApiLavori = new API({ url: "/clienti" });
  var entity = { name: "clienti" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.clienti.getAllOk(ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const _getAllCategoriaInt = (callback) => {
  const ApiLavori = new API({ url: "/categoriainterna" });
  var entity = { name: "categoriainterna" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.categoriainterna.getAllOk(ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const _createCategorInt = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/categoriainterna" });
  var entity = { name: "categoriainterna" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              ApiStaff.endpoints.categoriainterna.create(datoToupdate,ppp).then(res => {
                  
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


//item turni

export const _updateItemTurno = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/itemturno" });
  var entity = { name: "itemturno" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.itemturno.updateConID(datoToupdate,ppp).then(res => {
                  
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

//turniSpecial



export const _FindxlavoroxmeseNew = (lId,meseAnno,callback) => {
  const ApiStaff = new API({ url: "/newturnifindxlavoroxmese" });
  var entity = { name: "newturnifindxlavoroxmese" };
  console.log('mostro meseeeee');
  console.log(meseAnno.length);
  let newCkio = []
  if(meseAnno.length == 6){
    let anno = meseAnno.slice(-4);
    let mese = meseAnno.substring(0, 2)
    console.log('meseeee');
    console.log(mese);
    newCkio = (Number(mese)+1)+'-'+anno
  }else{
    let anno = meseAnno.slice(-4);
    let mese = meseAnno.substring(0, 1)
    newCkio = '0'+(Number(mese)+1)+'-'+anno
  }
  console.log(newCkio);

  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              ApiStaff.endpoints.newturnifindxlavoroxmese.getPost({lavoroId:lId ,meseAnno:newCkio } ,ppp).then(res => {
               console.log('mostro result');
               console.log(res);   
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

export const _Findxlavoroxmese = (lId,meseAnno,callback) => {
  const ApiStaff = new API({ url: "/findxlavoroxmese" });
  var entity = { name: "findxlavoroxmese" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              

              

              ApiStaff.endpoints.findxlavoroxmese.getPost({lavoroId:lId ,meseAnno:meseAnno } ,ppp).then(res => {
                  
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const UpdateSpecialturno = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/specialturno" });
  var entity = { name: "specialturno" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.specialturno.update(datoToupdate,ppp).then(res => {
                  console.log('dato salvato');
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

export const UpdateSpecialturnoNew = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/testset" });
  var entity = { name: "testset" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.testset.update(datoToupdate,ppp).then(res => {
                  console.log('dato salvato');
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

//notifiche

export const _resetModifiche= (id,callback) => {
  const ApiLavori = new API({ url: "/modificatireset" });
  var entity = { name: "modificatireset" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.modificatireset.getOne(
            { id: id },
            ppp
          ).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

export const _getModifiche= (id,callback) => {
  const ApiLavori = new API({ url: "/modificati" });
  var entity = { name: "modificati" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.modificati.getOne(
            { id: id },
            ppp
          ).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const _getstoricoNotifiche = (id,callback) => {
  const ApiLavori = new API({ url: "/newnotifichebylavoro" });
  var entity = { name: "newnotifichebylavoro" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.newnotifichebylavoro.getOne(
            { id: id },
            ppp
          ).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

export const _createNotifica = (notifica,callback) => {
  const ApiStaff = new API({ url: "/newnotifica" });
  var entity = { name: "newnotifica" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              console.log(notifica);

              ApiStaff.endpoints.newnotifica.create(notifica,ppp).then(res => {
                  
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

//giorni


export const UpdateGiornoArrinlavoro= (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/giornoarrupdate" });
  var entity = { name: "giornoarrupdate" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              datoToupdate.idDate = datoToupdate.id
              ApiStaff.endpoints.giornoarrupdate.updateNew(datoToupdate,ppp).then(res => {
                  console.log('dato salvato');
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const UpdateGiornoArr= (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/giornoarr" });
  var entity = { name: "giornoarr" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              datoToupdate.idDate = 'noimp'
              ApiStaff.endpoints.giornoarr.updateNew(datoToupdate,ppp).then(res => {
                  console.log('dato salvato');
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

export const UpdateGiorno= (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/giorno" });
  var entity = { name: "giorno" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.giorno.updateNew(datoToupdate,ppp).then(res => {
                  console.log('dato salvato');
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


//clienti
export const _getClineti = (callback) => {
  const ApiLavori = new API({ url: "/clienti" });
  var entity = { name: "clienti" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.clienti.getAllOk(ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const _getCliente = (id,callback) => {
  const ApiLavori = new API({ url: "/cliente" });
  var entity = { name: "cliente" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.cliente.getOne(
            { id: id },
            ppp
          ).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}



export const UpdateCliente = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/cliente" });
  var entity = { name: "cliente" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              datoToupdate.id = datoToupdate.uid;
              ApiStaff.endpoints.cliente.updateConID(datoToupdate,ppp).then(res => {
                  console.log('dato salvato');
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const _createTurnospecial = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/specialturno" });
  var entity = { name: "specialturno" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              ApiStaff.endpoints.specialturno.create(datoToupdate,ppp).then(res => {
                  
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const _createTurnospecialNew = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/newturno" });
  var entity = { name: "newturno" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              ApiStaff.endpoints.newturno.create(datoToupdate,ppp).then(res => {
                  
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}



export const _createCliente = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/clienti" });
  var entity = { name: "clienti" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              ApiStaff.endpoints.clienti.create(datoToupdate,ppp).then(res => {
                  
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

//vendite

export const _getAllVebnditePagin= (pagina,filter,callback) => {

  let limit = 20;
  let parametrriRicerca = {limit:limit}
  if(pagina){
    parametrriRicerca.skip = (pagina-1)*limit
  }else{
    parametrriRicerca.skip = 0
  }

  if(pagina == 'tutto'){
    console.log('quiiiiii tutto');
    parametrriRicerca.limit = 1000;
    parametrriRicerca.skip = 0
  }
  
  if(filter){
    parametrriRicerca.filter = filter
  }

  

  const ApiLavori = new API({ url: "/rsalespagi" });
  var entity = { name: "rsalespagi" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.rsalespagi.getAllParamx(parametrriRicerca,ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const _getAllFeedPagin= (pagina,filter,callback) => {

  let limit = 20;
  let parametrriRicerca = {limit:limit}
  if(pagina){
    parametrriRicerca.skip = (pagina-1)*limit
  }else{
    parametrriRicerca.skip = 0
  }
  if(pagina == 'tutto'){
    console.log('quiiiiii tutto');
    parametrriRicerca.limit = 1000;
    parametrriRicerca.skip = 0
  }

  if(filter){
    parametrriRicerca.filter = filter
  }

  const ApiLavori = new API({ url: "/rfeedspagi" });
  var entity = { name: "rfeedspagi" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.rfeedspagi.getAllParamx(parametrriRicerca,ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const _getIngressi = (callback) => {
  const ApiLavori = new API({ url: "/rissgroupnew" });
  var entity = { name: "rissgroupnew" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.rissgroupnew.getAllOk(
            ppp
          ).then(res => {
            const alltodo = res.data;
            console.log('coseeee');
            console.log(alltodo);  
            callback(alltodo)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


//presentazione
export const _getAllPre = (callback) => {
  const ApiLavori = new API({ url: "/listeclienti" });
  var entity = { name: "listeclienti" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.listeclienti.getAllOk(ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

export const _getPresentazione = (id,callback) => {
  const ApiLavori = new API({ url: "/listacliente" });
  var entity = { name: "listacliente" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.listacliente.getOne(
            { id: id },
            ppp
          ).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

export const _updatePresentazione = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/listacliente" });
  var entity = { name: "listacliente" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              ApiStaff.endpoints.listacliente.updateConID(datoToupdate,ppp).then(res => {
                  
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const _deleteTurno = (id,callback) => {
  const ApiLavori = new API({ url: "/turnos" });
  var entity = { name: "turnos" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.turnos.delete(
            { id: id },
            ppp
          ).then(res => {
            callback({ok:true})  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

export const _deletePresentazione = (id,callback) => {
  const ApiLavori = new API({ url: "/listacliente" });
  var entity = { name: "listacliente" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.listacliente.delete(
            { id: id },
            ppp
          ).then(res => {
            callback({ok:true})  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}


export const _createPresentazione = (callback) => {
  const ApiStaff = new API({ url: "/listacliente" });
  var entity = { name: "listacliente" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              ApiStaff.endpoints.listacliente.create({},ppp).then(res => {
                  
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}

export const _ckinalllavoroxmese = (lId,meseAnno,callback) => {
  const ApiStaff = new API({ url: "/turnilavoroxmese" });
  var entity = { name: "turnilavoroxmese" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              

              

              ApiStaff.endpoints.turnilavoroxmese.getPost({lavoroId:lId ,meseAnno:meseAnno } ,ppp).then(res => {
                  
                  callback(res.data)
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const _ricercaInterna = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/stafffinde" });
  var entity = { name: "stafffinde" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              ApiStaff.endpoints.stafffinde.create(datoToupdate,ppp).then(res => {
                  
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const _generaIbanYo = (datoToupdate,callback) => {
  const ApiStaff = new API({ url: "/staffibanss" });
  var entity = { name: "staffibanss" };
  ApiStaff.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
  if (authUser) {
    authUser.getIdToken().then(function(idToken) {
              var ppp = { Authorization: "Bearer " + idToken };
              //datoToupdate.uid = authUser.uid;
              console.log('boghhh');
              ApiStaff.endpoints.staffibanss.create(datoToupdate,ppp).then(res => {
                  callback({resp:res,status:true})
      })
      .catch(function (error) {
                  callback({error:error})
       })
          })
      }
  })
}


export const _getLavoriC= (pagina,titolo,callback) => {

  let limit = 20;
  let parametrriRicerca = {limit:limit}
  if(pagina){
    parametrriRicerca.skip = (pagina-1)*limit
  }else{
    parametrriRicerca.skip = 0
  }
  

  if(titolo){
    parametrriRicerca.titolo = titolo
  }

  const ApiLavori = new API({ url: "/lavoripagi" });
  var entity = { name: "lavoripagi" };
  ApiLavori.createEntity(entity);
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
        authUser.getIdToken().then(function(idToken) {
          var ppp = { Authorization: "Bearer " + idToken };
          ApiLavori.endpoints.lavoripagi.getAllParamx(parametrriRicerca,ppp).then(res => {
            callback(res.data)  
          })
          .catch(function (error) {
            callback({error:error})  
          })
        });
      }else{
        callback({error:'problem auth'})
      }
    });
}

export const _getLavoroCopyyo = _getLavoroCopy
export const _getAllLavoriApi = _getAllLavori
export const _getLavoroApi = _getLavoro
export const _getStaffUserApi = _getStaffUser
export const _testTabellaTurniApi = _testTabellaTurni
export const _getTurnoApi = _getTurno
export const _getStaffApi = _getStaff
export const _getCategorieApi = _getCategorie
export const _getStaffToDoApi = _getStaffToDo
export const _getChatGroupApi = _getChatGroup
export const _getChatMessApi = _getChatMess
export const saveMessageGroupApi = saveMessageGroup