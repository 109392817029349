import PropTypes from 'prop-types'
import React from "react"
import { Card, CardBody, Col, Media } from "reactstrap"

const MiniCards = props => {
  const { title, text, iconClass ,mini } = props
  return (
    <React.Fragment>
      <Col md={''}>
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media style={{textAlign:'center'}} body>
                <p className="text-muted font-weight-medium">{title}</p>
                <h4 className="mb-0">{text}</h4>
                <p></p>
              </Media>
              
            </Media>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

MiniCards.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}

export default MiniCards
